import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import React, { useEffect, useRef } from 'react'

const PCard = (props: any) => {
  const { title, children, headerElements } = props

  return (
    <CRow className="align-items-center justify-content-start row">
      <CCol>
        <CCard className="mb-3">
          <CCardHeader>
            <span className="p-card-header-container">
              {title}
              <span className="p-card-header-toggler">{headerElements}</span>
            </span>
          </CCardHeader>
          <CCardBody className="card-center">{children}</CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}
export default PCard
//  {
//    title
//  }
//  {
//    headerElements
//  }
// <span className="p-card-header-container">
//   <span className="p-card-header-text">{title}</span>
//   <span className="p-card-header-toggler">{headerElements}</span>
// </span>
