import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { dispatchResizeEvent } from "../../events/eventDispatcher"

import {
    CLink,
    CCardHeader,
    CSwitch,
} from "@coreui/react";

const ConditionalCollapseHeader = (props: any) => {
    const {
        headerClass,
        titleStr,
        handleErrors,
        errorStates,
        expanded,
        setExpanded,
        toggleEnable,
        toggleFieldName,
        toggleClassName,
        toggleColor,
        handleToggle,
        toggleSize,
        cLinkParentClass,
        cLinkClass

    } = props;
    //console.log('Input group row props are: ', props)
    const handleExpansion = () => {
        setExpanded(!expanded);
        dispatchResizeEvent(50);

    };
    return (
        <CCardHeader
            style={{ cursor: "pointer" }}
            onClick={handleExpansion}
            className={headerClass}
        >
            <CSwitch
                name={toggleFieldName}
                className={toggleClassName}
                // variant={'3d'}
                size={toggleSize}
                color={toggleColor}
                checked={toggleEnable}
                labelOn={"\u2713"}
                labelOff={"\u2715"}
                value={toggleEnable}
                onChange={handleToggle}
            />
            {titleStr}
            {toggleEnable && (
                <div className={cLinkParentClass}>
                    <CLink className={cLinkClass}>
                        <CIcon
                            name={expanded ? "cil-chevron-top" : "cil-chevron-bottom"}
                        />
                    </CLink>
                </div>
            )}
        </CCardHeader>
    );
};
export default ConditionalCollapseHeader;
