import React, { useRef } from 'react'
import { pickByKeys, omitByKeys } from '../../../api/base/object'
import { Transition } from 'react-transition-group'
export const TransitionPropTypeKeys = [
  'in',
  'mountOnEnter',
  'unmountOnExit',
  'appear',
  'enter',
  'exit',
  'timeout',
  'onEnter',
  'onEntering',
  'onEntered',
  'onExit',
  'onExiting',
  'onExited',
]

export const PFadeProps = [...TransitionPropTypeKeys, 'baseClass', 'baseClassActive', 'tag']
//TODO: Add fela css
const PFade = React.forwardRef(({ ...props }: any, ref) => {
  const { tag: _tag, className, children, baseClass: _baseClass, baseClassActive: _baseClassActive, ...rest } = props
  const Tag = _tag || 'div'
  const baseClass = _baseClass || 'fade'
  const baseClassActive = _baseClassActive || 'show'

  const transitionProps = pickByKeys(rest, TransitionPropTypeKeys)
  const childProps = omitByKeys(rest, TransitionPropTypeKeys)

  return (
    <Transition {...{ ...transitionProps, timeout: transitionProps.timeout || 300 }} nodeRef={ref}>
      {(status) => {
        const isActive = status === 'entered'
        const classes = `${className || ''} ${baseClass} ${isActive ? baseClassActive : ''}`
        return (
          <Tag className={classes} {...childProps} ref={ref}>
            {children}
          </Tag>
        )
      }}
    </Transition>
  )
})
PFade.displayName = 'PFade'
export default PFade
