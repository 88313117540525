import * as React from 'react'

const PathSpotNoDataRaw = (props: any) => {
  const { primary_color, secondary_color, tertiary_color, quaternary_color, className, viewBox, width, height } = props

  return (
    <svg viewBox={viewBox || '0 0 503.356 361.418'} className={className || 'p-icon-5xl'}>
      <rect
        x={77.678}
        y={58.664}
        width={323.832}
        height={223.632}
        rx={6.248}
        ry={6.248}
        fill="none"
        stroke="#085e73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={22.27}
      />
      <path fill="none" stroke="#a65095" strokeLinecap="round" strokeLinejoin="round" strokeWidth={6.785} d="M184.95 210.405v-43.321h-31.433v43.321" />
      <path fill="none" stroke="#00a19a" strokeLinecap="round" strokeLinejoin="round" strokeWidth={6.785} d="M282.622 210.852V110.089h-31.455v100.763" />
      <path fill="none" stroke="#a3cf5a" strokeLinecap="round" strokeLinejoin="round" strokeWidth={6.838} d="M233.903 213.223v-75.994h-31.678v75.994" />
      <path fill="none" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth={7.268} d="M331.442 210.723v-86.521h-31.433v86.521" />
      <path
        fill="none"
        stroke="#231f20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4.357}
        d="M151.898 154.871h33.926v-13.074l-.153-16.781h48.926v-.3l-.346-26.84h48.436l.122.035 15.552-8.552"
      />
      <path fill="#231f20" d="M299.341 93.78l4.5-7.434-8.688-.182 4.188 7.616z" />
      <path
        fill="none"
        stroke="#085e73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={24.126}
        d="M12.063 349.355l65.615-65.615M467.56 349.355l-65.615-65.615M12.063 349.355H467.56"
      />
      <g fill="none" stroke="#085e73" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.268}>
        <path d="M167.821 332.268l20.05-20.051M307.011 332.268l-20.051-20.051M167.821 332.268h139.19M187.871 312.217h99.089" />
      </g>
      <path fill="none" stroke="#00a19a" strokeLinecap="round" strokeLinejoin="round" strokeWidth={24.427} d="M334.457 222.836H144.731" />
      <path fill="none" stroke="#00a19a" strokeLinecap="round" strokeLinejoin="round" strokeWidth={22.587} d="M492.063 204.909l-64.812-64.813" />
      <circle cx={377.977} cy={84.487} r={74.135} fill="#fff" stroke="#085e73" strokeLinecap="round" strokeLinejoin="round" strokeWidth={20.704} />
      <path
        d="M377.977 119.837c-19.523 0-35.35-15.825-35.351-35.348 0-19.523 15.825-35.35 35.348-35.351h.002"
        fill="none"
        stroke="#00a19a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={22.587}
      />
    </svg>
  )
}

const PathSpotNoData = React.memo(PathSpotNoDataRaw)
export default PathSpotNoData
