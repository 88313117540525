import { CLabel, CInputGroupText } from "@coreui/react";
import React from "react";

const StyledLabel = (props: any) => {
  const { textStyle, borderStyle, labelStr } = props;
  //console.log('Styled label props are: ', props)
  if (!textStyle && borderStyle) {
    return <CInputGroupText style={borderStyle}>{labelStr}</CInputGroupText>;
  } else if (textStyle && borderStyle) {
    return (
      <CInputGroupText className={textStyle} style={borderStyle}>
        {labelStr}
      </CInputGroupText>
    );
  } else if (textStyle && !borderStyle) {
    return <CLabel className={textStyle}>{labelStr}</CLabel>;
  } else {
    return <CLabel className="header-title mr-0 pr-0">{labelStr}</CLabel>;
  }
};
export default StyledLabel;
