//Airy Lib

import { pathspotPrimary } from '../../styles/ts/colors'

export const inlineFlex = { display: 'inline-flex !important' }
export const flexRow = { flexDirection: 'row' }
export const flexNoWrap = { flexWrap: 'nowrap' }
export const justifyContentCenter = { justifyContent: 'center' }
export const alignItemsCenter = { alignItems: 'center' }
export const fullWidth = { width: '100%' }
export const autoHeight = { height: 'auto' }
export const justifyContentSpaceBetween = { justifyContent: 'space-between' }
export const flexColumn = { flexDirection: 'column' }
export const flexWrap = { flexWrap: 'wrap' }
export const alignSelfCenter = { alignSelf: 'center' }
export const alignItemsBaseline = { alignItems: 'baseline' }
export const alignItemsStart = { alignItems: 'flex-start' }
export const justifyContentEnd = { justifyContent: 'flex-end' }
export const justifyContentStart = { justifyContent: 'flex-start' }
export const fullHeight = { height: '100%' }
export const flexWrapVar = (args: any) => {
  return args.flexWrap ? flexWrap : flexNoWrap
}
export const flexDirection = (args: any) => {
  return args.row ? flexRow : flexColumn
}
export const alignItems = (args: any) => {
  return { alignItems: args.alignItems }
}
export const justifyContent = (args: any) => {
  return { justifyContent: args.justifyContent }
}
export const alignSelf = (args: any) => {
  return { alignSelf: args.alignSelf }
}

export const margin = (args: any) => {
  return {
    marginTop: args.marginTop || args?.margin?.marginRight || args?.margin || '0.25rem',
    marginBottom: args.marginBottom || args?.margin?.marginRight || args?.margin || '0.25rem',
    marginLeft: args.marginLeft || args?.margin?.marginRight || args?.margin || '0.25rem',
    marginRight: args.marginRight || args?.margin?.marginRight || args?.margin || '0.25rem',
  }
}
export const padding = (args: any) => {
  return {
    paddingTop: args.paddingTop || args?.padding?.paddingRight || args?.padding || '0.25rem',
    paddingBottom: args.paddingBottom || args?.padding?.paddingRight || args?.padding || '0.25rem',
    paddingLeft: args.paddingLeft || args?.padding?.paddingRight || args?.padding || '0.25rem',
    paddingRight: args.paddingRight || args?.padding?.paddingRight || args?.padding || '0.25rem',
  }
}
export const border = (args: any) => {
  return {
    borderColor: args.borderColor || pathspotPrimary,
    borderWidth: args.borderWidth || '0px',
    borderStyle: args.borderStyle || 'normal',
    borderTop: args.borderTop || args?.border?.borderRight || args?.border,
    borderBottom: args.borderBottom || args?.border?.borderRight || args?.border,
    borderLeft: args.borderLeft || args?.border?.borderRight || args?.border,
    borderRight: args.borderRight || args?.border?.borderRight || args?.border,
  }
}
