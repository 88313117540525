import { pathspotPrimary, pathspotSecondary } from '../../styles/ts/colors'
import { borderWidth, fontSizeLg, fontSizeSm, fontWeightNormal, lineHeightLg, lineHeightSm } from './variables'

export const lineHeightBase = `1.5 !default`
export const inputBtnPaddingY = `.375rem !default`
export const inputBtnPaddingX = `.75rem !default`
export const inputBtnFontFamily = `null !default`
export const inputBtnFontSize = `.875rem !default`
export const inputBtnLineHeight = `${lineHeightBase} !default`

export const inputBtnFocusWidth = `.2rem !default`
export const inputBtnFocusColor = `rgba(${pathspotPrimary}, .25) !default`
export const inputBtnFocusBoxShadow = `0 0 0 ${inputBtnFocusWidth} ${inputBtnFocusColor} !default`

export const inputBtnPaddingYSm = `.25rem !default`
export const inputBtnPaddingXSm = `.5rem !default`
export const inputBtnFontSizeSm = `${fontSizeSm} !default`
export const inputBtnLineHeightSm = `${lineHeightSm} !default`

export const inputBtnPaddingYLg = `.5rem !default`
export const inputBtnPaddingXLg = `1rem !default`
export const inputBtnFontSizeLg = `${fontSizeLg} !default`
export const inputBtnLineHeightLg = `${lineHeightLg} !default`

export const inputBtnBorderWidth = `${borderWidth} !default`

export const btnPaddingY = `${inputBtnPaddingY} !default`
export const btnPaddingX = `${inputBtnPaddingX} !default`
export const btnFontFamily = `${inputBtnFontFamily} !default`
export const btnFontSize = `${inputBtnFontSize} !default`
export const btnLineHeight = `${inputBtnLineHeight} !default`
export const btnWhiteSpace = `null !default` // Set to `nowrap` to prevent text wrapping

export const btnPaddingYSm = `${inputBtnPaddingY}Sm !default`
export const btnPaddingXSm = `${inputBtnPaddingX}Sm !default`
export const btnFontSizeSm = `${inputBtnFontSize}Sm !default`
export const btnLineHeightSm = `${inputBtnLineHeight}Sm !default`

export const btnPaddingYLg = `${inputBtnPaddingY}Lg !default`
export const btnPaddingXLg = `${inputBtnPaddingX}Lg !default`
export const btnFontSizeLg = `${inputBtnFontSize}Lg !default`
export const btnLineHeightLg = `${inputBtnLineHeight}Lg !default`

export const btnBorderWidth = `${inputBtnBorderWidth} !default`

export const btnFontWeight = `${fontWeightNormal} !default`
export const btnBoxShadow = `inset 0 1px 0 rgba(white, .15), 0 1px 1px rgba(black, .075) !default`
export const btnFocusWidth = `${inputBtnFocusWidth} !default`
export const btnFocusBoxShadow = `${inputBtnFocusBoxShadow} !default`
export const btnDisabledOpacity = `.65 !default`
export const btnActiveBoxShadow = `inset 0 3px 5px rgba(black, .125) !default`

export const btnLinkDisabledColor = `gray600 !default`

export const btnBlockSpacingY = `.5rem !default`

// Allows for customizing button radius independently from global border radius
export const btnBorderRadius = `borderRadius !default`
export const btnBorderRadiusLg = `borderRadiusLg !default`
export const btnBorderRadiusSm = `borderRadiusSm !default`

export const btnFormBoxShadow =
  '0 1px 1px 0 rgba(var(--elevation-base-color), .14), 0 2px 1px -1px rgba(var(--elevation-base-color), .12), 0 1px 3px 0 rgba(var(--elevation-base-color), .20)'

export const btnTransition = (args: any) => {
  return `color .15s ease-in-out, {args.backgroundColor || pathspotSecondary} .15s ease-in-out, {  args.borderColor || pathspotPrimary} .15s ease-in-out, boxShadow .15s ease-in-out !default`
}

export const btn = (args: any) => {
  return {
    display: `flex !important`,
    flexDirection: `column`,
    flexWrap: `nowrap`,
    justifyContent: `center`,
    alignItems: `center`,
    height: `100%`,
    width: `100%`,
  }
}
export const buttonMargins = (args: any) => {
  const { marginTop, marginBottom, marginLeft, marginRight } = args
  return {
    marginTop: marginTop || '3rem',
    marginBottom: marginBottom || '3rem',
    marginRight: marginRight || '1rem',
    marginLeft: marginLeft || '1rem',
  }
}
export const defaultButtonAttributes = {
  type: 'button',
  shape: 'standard',
  size: 'sm',
}
