import React from 'react'
import { useFela } from 'react-fela'
import PContentRow from '../rows/PContentRow'

import { v4 as uuidv4 } from 'uuid'
import PIcon from '../../../components/icons/PIcon/PIcon'
import PContent from '../containers/PContent'
import * as PIconLib from '../../../assets/icons/pathspot/catalog'
import { pathspotGray, pathspotPrimary, pathspotSecondary, pathspotWhite } from '../../../styles/ts/colors'
import { baseText } from '../../styles/text'
import { inlineFlex } from '../../styles/css-properties'
import PElement from '../containers/PElement'
import PButtonText from '../text/PButtonText'
import CIcon from '@coreui/icons-react'
import PFontIcon from '../icons/PFontIcon'
const componentKey = uuidv4()

const buttonClass = (args: any) => {
  const { color, backgroundColor, width, height, disabledColor: _disabledColor, justifyContent, alignItems, margin, padding } = args
  const baseColor = backgroundColor || pathspotPrimary
  const disabledColor =
    _disabledColor || baseColor.length === 7 ? `${baseColor}80` : baseColor.length === 9 ? `${baseColor.substring(0, 6)}80` : pathspotGray
  const displayColor = args?.disabled !== undefined ? (args.disabled === true ? disabledColor : baseColor) : baseColor
  const returnObject = {
    display: 'inline-flex !important',
    backgroundColor: displayColor || pathspotPrimary,
    color: backgroundColor || pathspotWhite,
    width: width || 'auto',
    height: height || 'auto',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
    ...margin,
    ...padding,
  }
  // console.log('Return object is: ', returnObject)
  return returnObject
}

const iconClass = (args: any) => {
  const { textAlign, width, height, fontSize, color } = args
  const returnVal = {
    ...inlineFlex,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: textAlign || 'center',
    marginRight: '0.25rem',
    marginLeft: '0.25rem',
    verticalAlign: 'middle',
    width: fontSize || '1.5rem !important',
    height: fontSize || '1.5rem !important',
    fontSize: fontSize || '1.5rem !important',
    color: color || pathspotWhite,
  }
  //console.log('Returned object from icon class is: ', returnVal)
  return returnVal
}
const PIconButton = (props: any) => {
  const {
    iconName: _iconName,
    width,
    alignItems,
    justifyContent,
    margin,
    padding,
    disabled,
    onClick: _onClick,
    button,
    border,
    height,
    showComment,
    PathSpotIcon,
    iconStrokeColor: _iconStrokeColorProp,
    iconFillColor: _iconFillColorProp,
    iconColor: _iconColorProp,
    color: _colorProp,
    backgroundColor: _backgroundColorProp,
  } = props
  showComment && console.log('Button in PICon button is: ', button, props)
  const iconName = _iconName ? _iconName : button && button.icon ? button.icon : undefined
  const {
    iconColor: _buttonIconColor,
    iconStrokeColor: _buttonIconStrokeColor,
    iconFillColor: _buttonIconFillColor,
    text: buttonText,
    color: _buttonColor,
    backgroundColor: _buttonBackgroundColor,
    height: buttonHeight,
    width: buttonWidth,
    type: buttonType,
    fontSize: buttonFontSize,
    fontWeight: buttonFontWeight,
    onClick: buttonOnClick,
  } = button ? button : ({} as any)
  const { css } = useFela()
  //Make it such that you can pass a callback within the button structure or as a seperate prop.
  const onClick = _onClick || buttonOnClick
  const iconColor = _buttonIconColor || _iconColorProp
  const iconStrokeColor = _buttonIconStrokeColor || _iconStrokeColorProp
  const iconFillColor = _buttonIconFillColor || _iconFillColorProp
  const backgroundColor = _buttonBackgroundColor || _backgroundColorProp
  const color = _buttonColor || _colorProp
  const click = (e: any) => !disabled && onClick && onClick(e)
  showComment && console.log('Pathspot Icon is: ', PathSpotIcon)
  const cssClasses = `btn btn-${button?.shape || 'normal'} btn-${button?.size || 'sm'}`
  return (
    <PElement
      isRow
      width={width || 'auto'}
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent || 'center'}
      margin={margin || { marginBottom: '1rem', marginTop: '1rem' }}
      border={border || undefined}
      height={height || undefined}
    >
      <button
        key={`${componentKey}-brow-${button?.text || iconName}-button`}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        className={`${cssClasses} ${css(
          buttonClass({
            disabled: disabled,
            height: buttonHeight,
            width: buttonWidth || 'auto',
            color: color,
            backgroundColor: backgroundColor,
            alignItems: 'center',
            justifyContent: 'center',
            margin: margin,
            padding: padding,
          })
        )}`}
        type={buttonType || 'button'}
        disabled={disabled}
        onClick={(val: any) => click(val)}
      >
        {iconName && PathSpotIcon && (
          <PathSpotIcon
            key={`${componentKey}-icon`}
            name={iconName}
            className={css(iconClass({ color: iconFillColor }))}
            strokeColor={iconStrokeColor}
            fillColor={iconFillColor}
          />
        )}
        {iconName && !PathSpotIcon && (
          <PFontIcon
            key={`${componentKey}-icon`}
            name={iconName}
            icon={iconName}
            className={css(iconClass({ color: iconColor, fontSize: buttonFontSize || '1rem' }))}
          />
        )}
        {buttonText && (
          <PButtonText
            color={color || pathspotWhite}
            displayText={buttonText || 'Lil Button'}
            justifyContent="center"
            isRow
            fontSize={buttonFontSize || '1rem'}
            fontWeight={buttonFontWeight || 'normal'}
            margin={{ ...margin }}
            padding={{ ...padding }}
          />
        )}
      </button>
    </PElement>
  )
}

export default PIconButton
//
