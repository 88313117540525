import React, { BaseSyntheticEvent } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

//TODO: Add fela css
const PLink = React.forwardRef(({ ...props }: any, ref) => {
  const { className, active, href, onClick, disabled, ...rest } = props

  const to = rest ? rest.to : null
  const click = (e: BaseSyntheticEvent) => {
    if ((!href && !to) || href === '#') {
      e.preventDefault()
    }
    !disabled && onClick && onClick(e)
  }

  const classes = classNames(active && 'active', disabled && 'disabled', className)

  return to ? (
    <NavLink ref={ref} {...rest} className={classes} onClick={click} />
  ) : (
    <a
      ref={ref}
      href={href || '#'}
      className={classes}
      rel={rest.target === '_blank' ? 'noopener norefferer' : null}
      {...rest}
      onClick={click}
    />
  )
})
PLink.displayName = 'PLink'
export default PLink
