import _ from 'lodash'

/**
 *
 * Evaluates y-value at given x point for line that passes
 * through the points (x0,y0) and (y1,y1)
 *
 * @param x
 * @param x0
 * @param y0
 * @param x1
 * @param y1
 * @returns {Number}
 */

const linearInterpolation = (x: number, x0: number, y0: number, x1: number, y1: number) => {
  var a = (y1 - y0) / (x1 - x0)
  var b = -a * x0 + y0
  return a * x + b
}

/**
 * Forces argument to be an array
 *
 * @param input
 * @returns {Array}
 */

import { isArray } from 'lodash'

const makeItArrayIfItsNot = (input: any | Array<any>) => {
  return isArray(input) ? [input] : input
}
/**
 *
 * Utilizes bisection method to search an interval to which
 * point belongs to, then returns an index of left or right
 * border of the interval
 *
 * @param {Number} point
 * @param {Array} intervals
 * @param {Boolean} useRightBorder
 * @returns {Number}
 */

const findIntervalBorderIndex = (xEvalPoint: number, originalXData: Array<any>) => {
  if (xEvalPoint < originalXData[0]) {
    return 0
  }
  if (xEvalPoint > originalXData[originalXData.length - 1]) {
    return originalXData.length - 1
  }
  const nextIndex = originalXData.findIndex((ogXdataPoint: number) => ogXdataPoint >= xEvalPoint)
  return nextIndex
}
/**
 * Evaluates interpolating line/lines at the set of numbers
 * or at a single number for the function y=f(x)
 *
 * @param {Number|Array} pointsToEvaluate     number or set of numbers
 *                                            for which polynomial is calculated
 * @param {Array} functionValuesX             set of distinct x values
 * @param {Array} functionValuesY             set of distinct y=f(x) values
 * @returns {Array}
 */
export const evaluateLinear = (pointsToEvaluate: Array<number>, functionValuesX: Array<number>, functionValuesY: Array<number>) => {
  const assuredArrayEvalPoints = isArray(pointsToEvaluate) ? [...pointsToEvaluate] : [pointsToEvaluate]
  const interpolatedValues = assuredArrayEvalPoints.map((evalPoint: number, idx: number) => {
    let startIndex = 0
    let endIndex = functionValuesX.length - 1
    let index = findIntervalBorderIndex(evalPoint, functionValuesX)
    if (index === 0) {
      return functionValuesY[0]
    }
    if (index === endIndex) {
      return functionValuesY[endIndex]
    }
    startIndex = index - 1
    const interpolatedValue = linearInterpolation(
      evalPoint,
      functionValuesX[startIndex],
      functionValuesY[startIndex],
      functionValuesX[index],
      functionValuesY[index]
    )
    //console.log('Interpolated value is: ', interpolatedValue)
    return interpolatedValue
  })
  //console.log('interpolatedValues are: ', interpolatedValues)
  return interpolatedValues.map((value: number) => Math.round(value * 100) / 100)
}
