import React from 'react'
import { useFela } from 'react-fela'
import PFieldLabel from '../text/PFieldLabel'
import PContent from '../containers/PContent'
import { datepicker, dropdown } from '../../styles/selectors'
import DatePicker from 'react-datepicker'
import { stringToMoment } from '../../../api/time/time'
import * as cssattr from '../../styles/css-properties'

export const timepicker = (args: any) => {
  const { justifyContent, alignItems, width } = args
  return {
    ...cssattr.inlineFlex,
    ...cssattr.flexRow,
    flexWrap: 'nowrap',
    minWidth: width || '50%',
    width: width || '50%',
    maxWidth: width || '50%',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
  }
}

const PTimePicker = (props: any) => {
  const {
    fieldLabel,
    options,
    placeholder,
    value,
    onChange,
    width,
    setSelectedDate: _setSelectedDate,
    selectedDate: _selectedDate,
    callback,
    item,
  } = props
  const setSelectedDate = _setSelectedDate ? _setSelectedDate : (val: any) => callback(val, item)
  const selectedDate = _selectedDate
    ? _selectedDate
    : value && value !== '' && value !== null && stringToMoment(value, 'hh:mm a').isValid()
    ? stringToMoment(value, 'hh:mm a').toDate()
    : undefined
  if (selectedDate || (value !== '' && value !== null)) {
    // console.log('Props in time picker are: ', props)
    // console.log('Set selected data is: ', setSelectedDate)
    // console.log('Selected date is ---------------------: ', selectedDate)
  }
  const { css } = useFela()
  return (
    <PContent isRow width={width} alignItems="center" justifyContent="center">
      {/* <div className={css(timepicker({ width: '100%' }))}> */}
      <DatePicker
        className={css(timepicker({ width: '100%' }))}
        selected={selectedDate ? selectedDate : undefined}
        onSelect={setSelectedDate}
        onChange={setSelectedDate}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa" //only when value has changed
      />
      {/* </div> */}
    </PContent>
  )
}
export default PTimePicker
