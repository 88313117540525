import React from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'
import { pageTitle } from '../../styles/text'
import PElement from '../containers/PElement'
const componentUUID = uuidv4()

const PText = (props: any) => {
  const { text, ...styleAttrs } = props
  const { css } = useFela()

  const key = `${text}-${componentUUID}`

  return <span className={css({ ...styleAttrs })}>{`${text}`}</span>
}

export default PText
