import { CInputGroup, CRow } from '@coreui/react'
// @ts-ignore
import React from 'react'
import { CInputGroupRowPrepend, CInputGroupRowAppend } from '../..'
import { selectorStyle, styles } from '../../styles/scss/styling'
//@ts-ignore
import Select from 'react-select'

const CSelectorGroupRow = (props: any) => {
  const { fieldName, iconStart, displayStr, placeholderStr, iconEnd, endStr, options, rowClassName, style, borderStyle, handleChange, values, touched, errors } = props
  return (
    <CRow className={rowClassName || 'mb-3'}>
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <Select
          name={fieldName}
          placeholder={placeholderStr}
          options={options}
          onChange={(val: any) => handleChange(val)}
          value={values}
          // styles={selectorStyle}
          styles={styles}
          // classNamePrefix="react-selector"
          isMulti
          //invalid={touched[fieldName] && errors[fieldName] ? true : false}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
    </CRow>
  )
}
export default CSelectorGroupRow
