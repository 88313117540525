export const matyellow = 'rgba(251, 220, 143, 0.5)'
export const matblue = 'rgba(103, 181, 231, 0.5)'
export const dgrey = 'rgba(196, 191, 201, 0.5)'

export const grey = 'rgba(234, 237, 243, 0.5)'
export const red = 'rgba(249, 133, 157, 0.5)'
export const yellow = 'rgba(251, 218, 124, 0.3)'

export const trq = 'rgba(106, 197, 206, 0.5)'
export const blue = 'rgba(100, 180, 236, 0.5)'
export const lightblue = 'rgba(129, 198, 231, 0.35)'
export const darkblue = 'rgba(69, 166, 211, 0.5)'

export const matgreen = 'rgba(124, 178, 180, 0.75)'
export const purple = 'rgba(132, 156, 212, 0.5)'
export const greengrass = 'rgba(115, 186, 29, 0.5)'
export const darkGrey = 'rgba(46, 49, 49, 0.25)'

export const reddish = 'rgba(255,0,0,0.4)'
export const reddishnew = 'rgba(255,0,0,0.35)'
export const lightreddish = 'rgba(255,0,0,0.05)'

export const darkGrey2 = 'rgba(46, 49, 49, 0.25)'

export const pathspotPrimary = '#025c71'
export const pathspotPrimary50 = '#025c7180'

export const pathspotSecondary = '#00a199'
export const pathspotGreen = '#a4cf59'
export const pathspotLavender = '#a55095'
export const pathspotLavender50 = '#a5509580'

export const pathspotRed = '#ff4136'
export const pathspotGray = '#6e7b91'
export const burntOrange = '#996300'
export const dukeBlue = '#003087'
export const texasOrange = '#bf5700'
export const grassGreen = '#73a24e'
export const slateGray = '#596673'
export const blockBlue = '#0080b3'
export const numericOrange = '#f2420d'
export const variableRed = '#e71823'
export const bananaYellow = '#fac905'
export const mintGreen = '#7fdbca'
export const pastelGreen = '#bcf0c0'
export const vibrantPurple = '#c792ea'
export const pastelYellow = '#f7ecb5'
export const vibrantBlue = '#00bff9'
export const vibrantPink = '#ec9cd2'
export const pastelBlue = '#87aff4'
export const primary = '#025c71'
export const success = '#00a29b'
export const warning = '#a4cf59'
export const info = '#a55095'
export const danger = '#f34545'
export const primaryDark = '#025c71'
export const pathspotBlue = '#025c71'
export const pathspotBlue50 = '#509cad'
export const pathspotTeal = '#00a29b'
export const pathspotTeal50 = '#72b6b2'
export const pathspotLightGreen = '#a4cf59'
export const pathspotGrayDark = '#515967'
export const pathspotGrayLight = '#818a98'
export const pathspotWhite = '#ffffff'
export const pathspotOffWhite = '#f4f3ee'
export const pathspotBarWhite = '#f4f5f8'
export const pathspotBgWhite = '#cfd7e3'
export const pathspotBlack = '#000000'
export const pathspotRedDark = '#8d1928'
export const pathspotRedLight = '#f34545'
export const coreuiGrayText = '#5c6873'
export const coreuiSidebarBrand = '#2a3649'
export const rootSkText = '#333'
export const formPlaceholder = '#73818f'
export const formGrayedOut = '#e4e7ea'
export const pathspotIconWhite = '#c5d8dc'
export const sensorRed = '#rgb(202, 44, 39)'
export const sensorBackground = '#rgb(215, 135, 139)'
export const sensorGreen = '#rgb(24, 200, 53)'
export const sensorGreenBackground = '#rgb(154, 237, 185)'
export const sensorWarn = '#rgb(237, 142, 14)'
export const sensorWarnBackground = '#rgb rgb(246, 224, 135)'
export const sensorGrayed = '#rgb = rgb(64, 73, 73)'
export const sensorGrayedBackground = '#rgb(167, 174, 174)'
export const pathspotWhite50p = '#ffffff80'
export const alertCritical = '#fa7a7a'
export const alertMedium = '#eccc5e'
export const alertLow = '#71d8c9'

export const outOfRangeBackground = '#ffefee'
export const outOfRange = '#822422'
export const inRange = '#246524'
export const inRangeBackground = '#eefaef'
export const pathspotGray40 = '#596673'

export const toastifySuccess = '#07bc0c'
export const toastifyWarning = '#f1c40f'
export const toastifyError = '#e74c3c'
export const toastifyInfo = '#3498db'

export const toastifySuccess75 = '#07bc0cbf'
export const toastifyWarning75 = '#f1c40fbf'
export const toastifyError75 = '#e74c3cbf'
export const toastifyInfo75 = '#3498dbbf'

export const toastifySuccess50 = '#07bc0c80'
export const toastifyWarning50 = '#f1c40f80'
export const toastifyError50 = '#e74c3c80'
export const toastifyInfo50 = '#3498dbbf80'

export const toastifyError25 = '#e74c3c40'
export const toastifyTextGray = '#75757580'

export const slateGray70 = '#a9b3bc'
export const grayInput = '#5c6873'
export const grayInputBackground = '#f0f3f5'
export const grayInputBorder = '#e4e7ea'
export const formControlGray = '#5c6873'
export const formControlBackground = '#fff'

// export const coreuiInputText = #5c6873;
// export const sensor-red-alt = #fa5961;
// export const sensor-red-alt-background = #f8aaae;
// export const sensor-red = rgb(202, 44, 39);
// export const sensor-red-background = rgb(215, 135, 139);
// export const sensor-green = rgb(24, 200, 53);
// export const sensor-green-background = rgb(154, 237, 185);
// export const sensor-warn = rgb(237, 142, 14);
// export const sensor-warn-background = rgb(246, 224, 135);
// export const sensor-grayed = rgb(64, 73, 73);
// export const sensor-grayed-background = rgb(167, 174, 174);
// //Temperature Data
// export const temperature-data = 'rgba(255, 99, 132)';
// export const temperature-data-background = 'rgba(255, 99, 132, 0.5)';
// export const humidity-data = 'rgb(17, 119, 133)';
// export const humidity-data-background = 'rgb(17, 119, 133, 0.5)';
// export const critical-threshold = 'rgb(231, 76, 60, 1)';
// export const warning-threshold = 'rgb(241, 196, 15, 1)';
