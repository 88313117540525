import { Moment } from 'moment'

export enum FilteredDashboards {
  HandWashes = 'HandWashes',
  Compliance = 'Compliance',
  ScanImages = 'ScanImages',
  Forms = 'Forms',
  Devices = 'Devices',
  Overview = 'Overview',
  Sensors = 'Sensors',
  SensorsModal = 'SensorsModal',
  SensorAlerts = 'SensorAlerts',
  SensorProcesses = 'SensorProcesses',
}

export enum FiltersStateListTarget {
  selections = 'selections',
  options = 'options',
  privileges = 'privileges',
}
export enum FiltersStateTarget {
  context = 'context',
  selections = 'selections',
  options = 'options',
  display = 'display',
  privileges = 'privileges',
}
export enum FiltersConfigTarget {
  display = 'display',
}

export enum FilterBarDateRange {
  start = 'start',
  end = 'end',
  delta = 'delta',
}

export enum FilterBarComponent {
  dateRange = 'dateRange',
  locationGroups = 'locationGroups',
  locations = 'locations',
  departments = 'departments',
  stations = 'stations',
  employees = 'employees',
}

export type BaseOption<ValueType = number> = {
  label: string
  value: ValueType
}

export interface FilterBarDateRangeState {
  [FilterBarDateRange.start]?: Moment | null
  [FilterBarDateRange.end]?: Moment | null
  [FilterBarDateRange.delta]?: any
}
export interface FilterBarListComponentsState {
  [index: string]: Array<any>
  [FilterBarComponent.locationGroups]: Array<any>
  [FilterBarComponent.locations]: Array<any>
  [FilterBarComponent.departments]: Array<any>
  [FilterBarComponent.stations]: Array<any>
  [FilterBarComponent.employees]: Array<any>
}
export interface FilterBarComponentsState {
  [index: string]: FilterBarDateRangeState | Array<any>
  [FilterBarComponent.dateRange]: FilterBarDateRangeState
  [FilterBarComponent.locationGroups]: Array<
    BaseOption & {
      _affCustomersInfo: Record<number, string>
      _affLocationIds: number[]
    }
  >
  [FilterBarComponent.locations]: Array<
    BaseOption & {
      _affStationIds: number[]
      _affEmployeeIds: string[]
    }
  >
  [FilterBarComponent.departments]: Array<
    BaseOption & {
      _affLocationId: number
      _affStationIds: number[]
    }
  >
  [FilterBarComponent.stations]: Array<BaseOption>
  [FilterBarComponent.employees]: Array<BaseOption<string>>
}
export interface FilterDisplayConfig {
  [index: string]: boolean
  [FilterBarComponent.dateRange]: boolean
  [FilterBarComponent.locationGroups]: boolean
  [FilterBarComponent.locations]: boolean
  [FilterBarComponent.departments]: boolean
  [FilterBarComponent.stations]: boolean
  [FilterBarComponent.employees]: boolean
}
export interface FiltersAffData {
  config: FilterDisplayConfig
  data: FilterBarComponentsState
}

export interface FiltersState<T> {
  [index: string]: FilterBarComponentsState | FilterDisplayConfig | T | null
  [FiltersStateTarget.selections]: FilterBarComponentsState
  [FiltersStateTarget.options]: FilterBarComponentsState
  [FiltersStateTarget.display]: FilterDisplayConfig
  [FiltersStateTarget.privileges]: FilterBarComponentsState
}

export interface ContextFilterState extends FiltersState<FilteredDashboards> {
  [FiltersStateTarget.context]: FilteredDashboards | null
}
export type DashboardComponentState = {
  [Property in keyof FilteredDashboards]: FilterBarComponentsState
}
export interface FiltersBarState {
  buttonVisible: boolean
  barVisible: boolean
  touched: boolean
  filtersState: ContextFilterState
  contextFiltersStates?: Array<ContextFilterState>
  defaultComponentStates?: DashboardComponentState
}
