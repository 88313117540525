import React from 'react'

export const ConditionalRender = (props: any) => {
  const { shouldRender } = props
  // console.log('Conditional render props are: ', props)
  // console.log('Conditional render children are: ', props.children)
  if (shouldRender) {
    return <div style={{ width: "100%" }}>{props.children}</div>
  } else {
    return <div></div>
  }
}

export default ConditionalRender
