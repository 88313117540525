import React from 'react'
import { useFela } from 'react-fela'
import { container } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'

const componentKey = `p-container-${uuidv4()}`

const PContainer = (props: any) => {
  const {
    flexWrap,
    isRow,
    isGroup,
    alignItems,
    justifyContent,
    margin,
    border,
    height,
    minHeight,
    maxHeight,
    width,
    maxWidth,
    minWidth,
    showComment,
    comment,
    name,
    display,
    className,
    padding,
  } = props
  showComment && console.log(`Props in PContainer for ${comment} are: `, props)
  const flexDirection = isRow ? 'row' : 'column'
  const { css } = useFela()
  return (
    <div
      key={`${name || uuidv4()}-${componentKey}`}
      className={css(
        container({
          isGroup,
          flexWrap,
          display,
          flexDirection,
          alignItems,
          justifyContent,
          margin,
          border,
          height,
          width,
          maxWidth,
          minWidth,
          padding,
          minHeight,
          maxHeight,
        }),
        className
      )}
    >
      {props.children}
    </div>
  )
}

export default PContainer
