import React, { BaseSyntheticEvent } from 'react'
import { useFela } from 'react-fela'
import { v4 as uuidv4 } from 'uuid'
import { pathspotPrimary } from '../../../styles/ts/colors'
import { isNullOrUndefined } from '../../../api/types/type-utils'
import _ from 'lodash'
const componentUUID = uuidv4()

const textInput = (args: any) => {
  return {
    overflow: 'visible',
    margin: 0,
    fontFamily: 'inherit',
    boxSizing: 'border-box',
    paddingBlock: '1px',
    paddingInline: '2px',
    display: 'block',
    width: '1%',
    // height: 'calc(1.5em + 0.75rem + 2px)',
    padding: '0.375rem 0.75rem',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    backgroundClip: 'padding-box',
    border: '1px solid',
    color: '#5c6873',
    backgroundColor: '#fff',
    borderColor: '#e4e7ea',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    position: 'relative',
    flex: '1 1 auto',
    minWidth: 0,
    marginBottom: 0,
    textIndent: '2px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '::before': {
      boxSizing: 'border-box',
    },
    '::after': {
      boxSizing: 'border-box',
    },
  }
}

const PTextInput = (props: any) => {
  // console.log('////////////////////////Pitem text props are: ', props)
  const {
    displayText: _displayText,
    placeholder: _placeholder,
    value: _value,
    alignItems,
    justifyContent,
    width,
    fontSize,
    color,
    fontWeight,
    margin,
    padding,
    border: _border,
    readOnly,
    item,
    callback,
    flexWrap,
    borderRadiusLeft: _borderRadiusLeft,
    borderRadiusRight: _borderRadiusRight,
    borderRadius: _borderRadius,
    borderWidth,
    borderColor,
    borderStyle,
    showComment,
    height,
    isGroup,
  } = props
  const { css } = useFela()
  const value = isNullOrUndefined(_value)
    ? undefined
    : Number.isNaN(_value)
    ? undefined
    : _value === ''
    ? undefined
    : _.isNumber(_value)
    ? `${_value}`
    : _value
  const placeholder = _displayText || _placeholder || value
  const key = `${placeholder}-${componentUUID}`
  const borderRadiusLeft = _borderRadiusLeft === undefined ? '0.25rem' : _borderRadiusLeft
  const borderRadiusRight = _borderRadiusRight === undefined ? '0.25rem' : _borderRadiusRight
  const borderRadius = _borderRadius || {
    borderTopRightRadius: borderRadiusRight,
    borderBottomRightRadius: borderRadiusRight,
    borderTopLeftRadius: borderRadiusLeft,
    borderBottomLeftRadius: borderRadiusLeft,
  }
  const border = {
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || pathspotPrimary,
    borderStyle: borderStyle || 'solid',
    ...borderRadius,
  }

  return (
    <input
      key={key}
      className={css(textInput({}))}
      placeholder={placeholder}
      defaultValue={value}
      disabled={readOnly}
      onBlur={(e: BaseSyntheticEvent) => {
        callback(e.target.value, item)
      }}
    />
  )
}

export default PTextInput
// const textInput = (args: any) => {
//   return {
//     input: {
//       overflow: 'visible',
//       margin: 0,
//       fontFamily: 'inherit',
//     },
//     '*, *::before, *::after': {
//       boxSizing: 'border-box',
//     },
//     'input[type="text" i]': {
//       paddingBlock: '1px',
//       paddingInline: '2px',
//     },
//     '.form-control': {
//       display: 'block',
//       width: '100%',
//       height: 'calc(1.5em + 0.75rem + 2px)',
//       padding: '0.375rem 0.75rem',
//       fontSize: '0.875rem',
//       fontWeight: 400,
//       lineHeight: 1.5,
//       backgroundClip: 'padding-box',
//       border: '1px solid',
//       color: '#5c6873',
//       backgroundColor: '#fff',
//       borderColor: '#e4e7ea',
//       borderRadius: '0.25rem',
//       transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
//     },
//     '.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file': {
//       position: 'relative',
//       flex: '1 1 auto',
//       width: '1%',
//       minWidth: 0,
//       marginBottom: 0,
//     },
//     [`html:not([dir="rtl"]) .input-group > .form-control:not(:last-child), html:not([dir="rtl"])
//   .input-group > .custom-select:not(:last-child)`]: {
//       borderTopRightRadius: 0,
//       borderBottomRightRadius: 0,
//     },
//     [`html:not([dir="rtl"]) .input-group > .form-control:not(:first-child), html:not([dir="rtl"])
//   .input-group > .custom-select:not(:first-child)`]: {
//       borderTopLeftRadius: 0,
//       borderBottomLeftRadius: 0,
//     },
//   }
// }
// <div className={css(textInput({}))}>
//   <input
//     key={key}
//     className={css(
//       inputText({ fontSize, color, fontWeight, flexDirection: flexDirection || 'row', margin, padding, border: { ...borderRadius } })
//     )}
//     defaultValue={`${placeholder || value}`}
//     disabled={readOnly}
//     onBlur={(e: BaseSyntheticEvent) => {
//       callback(e.target.value, item)
//     }}
//   />
// </div>
