import { api } from '../../api/api'
import { API_URL_GET_FILTERS_FOR_COMP_DASHBOARD, API_URL_GET_FILTERS_FOR_HANDWASHES_DASHBOARD } from '../../api/constants'
import moment, { Moment } from 'moment'
import {
  FilterDisplayConfig,
  ContextFilterState,
  FilterBarComponentsState,
  FiltersBarState,
  FilteredDashboards,
  FilterBarListComponentsState,
  FiltersStateListTarget,
  FilterBarComponent,
} from './filters-bar.types'
import _ from 'lodash'
import { mapValueFromKeyMatch } from '../../webapp-lib/pathspot-react'
export const defaultDisplayState: FilterDisplayConfig = {
  dateRange: false,
  locationGroups: false,
  locations: false,
  stations: false,
  employees: false,
  departments: false,
}
export const emptyFilterComponentsState = {
  dateRange: { start: null, end: null, delta: null },
  locationGroups: [],
  locations: [],
  stations: [],
  employees: [],
  departments: [],
}
export const emptyContextFilterState: ContextFilterState = {
  context: null,
  selections: { ...emptyFilterComponentsState },
  options: { ...emptyFilterComponentsState },
  display: { ...defaultDisplayState },
  privileges: { ...emptyFilterComponentsState },
}
export const getAllLocationsAndLocationGroups = async (): Promise<any> => {
  return await api.withAuth().url(`${API_URL_GET_FILTERS_FOR_COMP_DASHBOARD}`).get().json().then(api.zjson)
}
export const getRandomReqId = () => {
  return Math.floor(Math.random() * 10000)
}
export const getFiltersForHandwashesPage = async (): Promise<any> => {
  return await api.withAuth().url(`${API_URL_GET_FILTERS_FOR_HANDWASHES_DASHBOARD}`).get().json().then(api.zjson)
}
export const formatFiltersForDataRequest = (filters: FilterBarComponentsState) => {
  const noDates = filters.dateRange?.start === undefined && filters.dateRange?.end === undefined
  const formattedFilters = {
    selectedDateRange: noDates
      ? undefined
      : {
          startDate: filters.dateRange.start,
          endDate: filters.dateRange.end,
        },
    selectedLocationGroups: filters.locationGroups.length ? filters.locationGroups : undefined,
    selectedLocations: filters.locations.length ? filters.locations : undefined,
    selectedDepartments: filters.departments.length ? filters.departments : undefined,
    selectedStations: filters.stations.length ? filters.stations : undefined,
    selectedEmployees: filters.employees.length ? filters.employees : undefined,
  }
  return formattedFilters
}
export const filterMomentToString = (filterMoment: Moment) => `${filterMoment.format('LLL')}`
export const isEqualFilterSelections = (currentSelections: any, previousSelections: any) => {
  const boolState = Object.keys(currentSelections).map((key: string) => {
    if (key === 'dateRange' || key === 'allowedDateRange') {
      const previousStart =
        previousSelections[key].start !== null ? filterMomentToString(previousSelections[key].start) : previousSelections[key].start
      const currentStart =
        currentSelections[key].start !== null ? filterMomentToString(currentSelections[key].start) : currentSelections[key].start
      const previousEnd =
        previousSelections[key].end !== null ? filterMomentToString(previousSelections[key].end) : previousSelections[key].end
      const currentEnd = currentSelections[key].end !== null ? filterMomentToString(currentSelections[key].end) : currentSelections[key].end
      const startEqual = previousStart === currentStart
      const endEqual = previousEnd === currentEnd
      return startEqual && endEqual
    } else if (key === 'reqId') {
      return true
    } else {
      try {
        if (currentSelections[key] !== null && previousSelections[key] !== null) {
          let contentMatch = false
          const sizeMatch =
            previousSelections[key].length > 0 &&
            currentSelections[key].length > 0 &&
            previousSelections[key].length === currentSelections[key].length
          if (sizeMatch) {
            contentMatch = previousSelections[key].every((item: any) =>
              currentSelections[key].map((currentItem: any) => currentItem.label).includes(item.label)
            )
            return contentMatch
          } else if (previousSelections[key].length === 0 && currentSelections[key].length === 0) {
            return true
          } else {
            return false
          }
        }
        return false
      } catch (e) {}
    }
  })
  return !boolState.includes(false)
}
//This function checks both the context and the filter substate category (selctions, options, etc.)
export const isEmptyContextFilterSubState = (filtersState: ContextFilterState, subStateTarget: FiltersStateListTarget) => {
  const hasContext = filtersState.context !== null
  if (!isEmptyFilterComponentsState(filtersState[subStateTarget])) {
    return false
  }
  if (hasContext) {
    return false
  }
  return true
}
export const isUndefinedFiltersDisplayState = (filtersObj: FilterDisplayConfig | undefined) => {
  if (filtersObj === undefined) {
    return undefined
  }
  const boolState = Object.keys(filtersObj).map((key: string) => {
    try {
      return filtersObj[key] === null || filtersObj[key] === false ? true : false
    } catch (e) {
      return true
    }
  })
  return !boolState.includes(false)
}

export const isEmptyFilterComponentsState = (filtersObj: FilterBarComponentsState | undefined) => {
  if (filtersObj === undefined) {
    return undefined
  }
  const boolState = Object.keys(filtersObj).map((key: string) => {
    if (key === 'dateRange') {
      return filtersObj.dateRange.start === null && filtersObj.dateRange.end === null
    } else if (key === 'reqId') {
      return false
    } else {
      try {
        if (filtersObj[key] === null) {
          return true
        }
        return (filtersObj[key] as Array<any>).length === 0 ? true : false
      } catch (e) {
        return true
      }
    }
  })
  return !boolState.includes(false)
}
export const updateFilterState = (globalFilterSelections: any, queryFilterState: any, defaultFilterState: any) => {
  if (isEmptyFilterComponentsState(queryFilterState)) {
    if (isEmptyFilterComponentsState(globalFilterSelections)) {
      return defaultFilterState
    } else {
      return globalFilterSelections
    }
  } else {
    return queryFilterState
  }
  //Only want to carry filter state over if its been changed.
  // return globalFilterState.touched ? globalFilterState.selections : defaultFilterState
}
export const getFilterSelectionsFromURL = (queryParams: any) => {
  const locationGroupIds = 'locationGroupId' in queryParams ? [queryParams.locationGroupId] : []
  const locationIds = 'locationId' in queryParams ? [queryParams.locationId] : []
  const departmentIds = 'departmentId' in queryParams ? [queryParams.departmentId] : []
  const stationIds = 'stationId' in queryParams ? [queryParams.stationId] : []
  const employeeIds = 'employeeId' in queryParams ? [queryParams.employeeId] : []
}
export const updateDisplayNames = (filtersAffData: any) => {
  //This only run once so names do not constantly append parenthetical items on re-render
  const { locationGroups, locations, stations, employees, departments } = filtersAffData
  if (departments && departments.length > 0) {
    departments.forEach((element: any) => {
      let parentLocation = locations.find((loc: any) => loc.value === element._affLocationId)
      element.label = parentLocation ? `${element.label}  (${parentLocation.label})` : element.label
    })
  }
  if (stations && stations.length > 0) {
    stations.forEach((stationItem: any) => {
      let parentLocation = locations.find((loc: any) => loc._affStationIds.includes(stationItem.value))
      stationItem.label = parentLocation ? `${stationItem.label} (${parentLocation.label})` : stationItem.label
    })
  }
  return filtersAffData
}
export const getPrivilegedItemsFromIdList = (objectKey: string, idList: any[], contextFilterPrivileges: FilterBarComponentsState) => {
  const { context, ...filterLists } = contextFilterPrivileges
  const filterPrivileges = { ...filterLists } as FilterBarListComponentsState
  let privilegedItems = []
  if (idList.length > 0) {
    privilegedItems = filterPrivileges[objectKey].filter((item: any) => idList.some((id: any) => item.value === parseInt(id)))
  }
  return privilegedItems
}
export const getFiltersOjectFromURL = (queryParams: any, contextPrivileges: any): FilterBarComponentsState => {
  const queryIdLists: FilterBarListComponentsState = {
    locationGroups: 'locationGroupId' in queryParams ? [queryParams.locationGroupId] : [],
    locations: 'locationId' in queryParams ? [queryParams.locationId] : [],
    departments: 'departmentId' in queryParams ? [queryParams.departmentId] : [],
    stations: 'stationId' in queryParams ? [queryParams.stationId] : [],
    employees: 'employeeId' in queryParams ? [queryParams.employeeId] : [],
  }
  const filterComponentValues = Object.keys(queryIdLists).map((itemKey: any) => {
    return [itemKey, getPrivilegedItemsFromIdList(itemKey, queryIdLists[itemKey], contextPrivileges)]
  })
  const filterItemLists: FilterBarListComponentsState = Object.fromEntries(filterComponentValues)
  const start: any = 'starDate' in queryParams ? moment(queryParams.start, 'YYYY-MM-DD') : null
  const end: any = 'endDate' in queryParams ? moment(queryParams.end, 'YYYY-MM-DD') : null
  const delta = end !== null && start !== null ? end - start : null
  const filtersObj: FilterBarComponentsState = {
    ...filterItemLists,
    dateRange: {
      start: start,
      end: end,
      delta: delta,
    },
  }
  return filtersObj
}
export const getFiltersStateOptions = {
  locationGroups: (filtersState: ContextFilterState, customerId?: string) => {
    // Return all available location items filtered by customer.
    return filtersState.privileges.locationGroups.filter((lg) => {
      return !customerId || Object.hasOwn(lg._affCustomersInfo, customerId)
    })
  },
  locations: (filtersState: ContextFilterState) => {
    //if no LG is selected, then return all available location items
    const selectedLGs = filtersState.selections.locationGroups
    if (!selectedLGs || !selectedLGs.length) {
      const allowedLocationIds = new Set(filtersState.options.locationGroups.flatMap((lg) => lg._affLocationIds))
      return filtersState.privileges.locations.filter((loc) => {
        return allowedLocationIds.has(loc.value)
      })
    }
    //if there are any LGs selected, we only need locations that are affiliated with them
    let allowedLocationIds: number[] = []
    selectedLGs.forEach((item) => {
      allowedLocationIds = [...allowedLocationIds, ...item._affLocationIds]
    })
    return filtersState.privileges.locations.filter((x) => allowedLocationIds.includes(x.value))
  },
  departments: (filtersState: ContextFilterState) => {
    const selectedLGs = filtersState.selections.locationGroups
    const selectedLocs = filtersState.selections.locations
    //if no LG or LOC is selected, then return all available departments items
    if ((!selectedLGs || !selectedLGs.length) && (!selectedLocs || !selectedLocs.length)) {
      const allowedLocationIds = new Set(filtersState.options.locationGroups.flatMap((lg) => lg._affLocationIds))
      return filtersState.privileges.departments.filter((dept) => {
        return allowedLocationIds.has(dept._affLocationId)
      })
    }
    //if there are any LGs or LOCs selected, we only need departments that are affiliated with them
    const locsToTraverse = selectedLocs && selectedLocs.length ? selectedLocs : getFiltersStateOptions.locations(filtersState)
    return filtersState.privileges.departments.filter((dept) => locsToTraverse.some((loc) => loc.value === dept._affLocationId))
  },
  stations: (filtersState: ContextFilterState) => {
    const selectedLGs = filtersState.selections.locationGroups
    const selectedLocs = filtersState.selections.locations
    const selectedDepts = filtersState.selections.departments

    //if no LG or LOC is selected, then return all available station items
    if ((!selectedLGs || !selectedLGs.length) && (!selectedLocs || !selectedLocs.length) && (!selectedDepts || !selectedDepts.length)) {
      const allowedStationIds = new Set(filtersState.options.departments.flatMap((dept) => dept._affStationIds))
      return filtersState.privileges.stations.filter((station) => {
        return allowedStationIds.has(station.value)
      })
    }

    let allowedStationIds: number[] = []

    if (!selectedDepts || !selectedDepts.length) {
      //if there are any LGs or LOCs selected and we are not using departments, we only need stations that are affiliated with the locations
      const locsToTraverse = selectedLocs && selectedLocs.length ? selectedLocs : getFiltersStateOptions.locations(filtersState)
      locsToTraverse.forEach((item) => {
        allowedStationIds = item?._affStationIds ? [...allowedStationIds, ...item._affStationIds] : [...allowedStationIds]
      })
    } else {
      const deptsToTraverse = selectedDepts && selectedDepts.length ? selectedDepts : getFiltersStateOptions.departments(filtersState)
      deptsToTraverse.forEach((item) => {
        allowedStationIds = [...allowedStationIds, ...item._affStationIds]
      })
    }
    return filtersState.privileges.stations.filter((x) => allowedStationIds.includes(x.value))
  },
  employees: (filtersState: ContextFilterState) => {
    const selectedLGs = filtersState.selections.locationGroups
    const selectedLocs = filtersState.selections.locations
    //if no LG or LOC is selected, then return all available employee items
    if ((!selectedLGs || !selectedLGs.length) && (!selectedLocs || !selectedLocs.length)) {
      const allowedEmployeeIds = new Set(filtersState.options.locations.flatMap((loc) => loc._affEmployeeIds))
      return filtersState.privileges.employees.filter((employee) => {
        return allowedEmployeeIds.has(employee.value)
      })
    }
    //if there are any LGs or LOCs selected, we only need employees that are affiliated with them
    const locsToTraverse = selectedLocs && selectedLocs.length ? selectedLocs : getFiltersStateOptions.locations(filtersState)
    let allowedEmployeeIds: string[] = []
    locsToTraverse.forEach((item) => {
      allowedEmployeeIds = [...allowedEmployeeIds, ...item._affEmployeeIds]
    })
    return filtersState.privileges.employees.filter((x) => allowedEmployeeIds.includes(x.value))
  },
}
export const updateFiltersStateSelections = {
  locationGroups: (newFiltersState: ContextFilterState, customerId?: string) => {
    newFiltersState.options = {
      ...newFiltersState.options,
      locationGroups: getFiltersStateOptions.locationGroups(newFiltersState, customerId),
    }
    newFiltersState.selections = {
      ...newFiltersState.selections,
      locationGroups: newFiltersState.selections.locationGroups?.filter((x) =>
        newFiltersState.options.locationGroups.some((el) => el.value === x.value)
      ),
    }
  },
  locations: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.locations) {
      newFiltersState.options = { ...newFiltersState.options, locations: getFiltersStateOptions.locations(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        locations: newFiltersState.selections.locations?.filter((x) =>
          newFiltersState.options.locations?.some((el) => el.value === x.value)
        ),
      }
    }
  },
  departments: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.departments) {
      newFiltersState.options = { ...newFiltersState.options, departments: getFiltersStateOptions.departments(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        departments: newFiltersState.selections.departments?.filter((x) =>
          newFiltersState.options.departments?.some((el) => el.value === x.value)
        ),
      }
    }
  },
  stations: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.stations) {
      newFiltersState.options = { ...newFiltersState.options, stations: getFiltersStateOptions.stations(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        stations: newFiltersState.selections.stations.filter((x) => newFiltersState.options.stations.some((el) => el.value === x.value)),
      }
    }
  },
  employees: (newFiltersState: ContextFilterState) => {
    if (newFiltersState.display.employees) {
      newFiltersState.options = { ...newFiltersState.options, employees: getFiltersStateOptions.employees(newFiltersState) }
      newFiltersState.selections = {
        ...newFiltersState.selections,
        employees: newFiltersState.selections.employees.filter((x) => newFiltersState.options.employees.some((el) => el.value === x.value)),
      }
    }
  },
}
const setContextFilterFromResponse = (
  currentFilterBarState: FiltersBarState,
  filterContext: FilteredDashboards,
  privilegeResponseObj: any
): FiltersBarState => {
  if (currentFilterBarState && currentFilterBarState.contextFiltersStates) {
    //first update display names
    const privilegeData = updateDisplayNames(privilegeResponseObj.data)
    const displayPrivileges = privilegeResponseObj.config
    const currentCachedFilterState = getCachedFilterState(filterContext, currentFilterBarState.contextFiltersStates)
    const otherContextFilterStates = currentFilterBarState.contextFiltersStates.filter((filterObj: ContextFilterState) => {
      return filterObj.context !== filterContext
    })
    const mappedDisplayPrivelages = mapValueFromKeyMatch(currentCachedFilterState?.display, displayPrivileges)
    if (currentCachedFilterState) {
      currentFilterBarState = {
        ...currentFilterBarState,
        contextFiltersStates: [
          ...otherContextFilterStates,
          {
            ...currentCachedFilterState,
            privileges: privilegeData,
            display: mappedDisplayPrivelages,
          },
        ],
      }
    }
    //If the current state matches the page context or is null, clone privileges into current state as well
    if (currentFilterBarState.filtersState.context === filterContext || currentFilterBarState.filtersState.context === null) {
      currentFilterBarState = {
        ...currentFilterBarState,
        filtersState: {
          ...currentFilterBarState.filtersState,
          context: filterContext, //in case it is null
          privileges: { ...privilegeData },
          display: mappedDisplayPrivelages,
        },
      }
    }
  }

  return currentFilterBarState
}
const getCachedFilterState = (context: FilteredDashboards, contextFilters: ContextFilterState[]) => {
  const cachedFilterState = contextFilters.filter((filtersObj: any) => {
    return filtersObj.context === context
  })
  if (cachedFilterState) {
    return cachedFilterState[0]
  } else {
    return undefined
  }
}
const setCachedFilterState = (currentState: FiltersBarState, context: FilteredDashboards, contextFilterState: ContextFilterState) => {
  if (currentState && currentState.contextFiltersStates) {
    const otherContextFilterStates = currentState.contextFiltersStates.filter((filterObj: ContextFilterState) => {
      return filterObj.context !== context
    })

    const updatedFilterBarState = {
      ...currentState,
      contextFiltersStates: [
        ...otherContextFilterStates,
        {
          ...contextFilterState,
        },
      ],
    }
    return { ...updatedFilterBarState }
  } else {
    console.warn('Current state in setCachedFilterState is ill-defined: ', _.cloneDeep(currentState))
    return currentState
  }
}
const setCurrentFilterState = (currentFilterBarState: FiltersBarState, filtersState: ContextFilterState): FiltersBarState => {
  if (filtersState && filtersState.context && filtersState.privileges) {
    currentFilterBarState = { ...currentFilterBarState, filtersState: { ...filtersState } }
  } else {
    console.warn(
      'Current filters state will be set with invalid filters object (missing privileges or context): ',
      _.cloneDeep(filtersState)
    )
  }
  return currentFilterBarState
}

const setCurrentFilterStateFromCache = (currentFilterBarState: FiltersBarState, filterContext: FilteredDashboards): FiltersBarState => {
  if (currentFilterBarState && currentFilterBarState.contextFiltersStates) {
    const cachedContextFilterState = getCachedFilterState(filterContext, currentFilterBarState.contextFiltersStates)
    if (cachedContextFilterState) {
      currentFilterBarState = setCurrentFilterState(currentFilterBarState, { ...cachedContextFilterState })
    }
  }
  return currentFilterBarState
}
const mergeCurrentStateToPageDefault = async (
  currentState: ContextFilterState,
  defaultFilterState: FilterBarComponentsState,
  responseData: any
) => {}
const getNonEmptyFilterComponents = (filterComponents: FilterBarComponentsState) => {}

//This function reduces the filter state to the union of the desired filter state and what is allowed. It uses sourcery to acheive that.
const reduceFiltersStateFromPrivileges = (filtersState: ContextFilterState) => {
  //Pathspot school of witchcraft and wizardry
  //updateFiltersStateSelections is a javascript object wherein each attribute/property is the updater function for the respective attribute key, and these keys match the filter components (locations, stattions, etc.)
  const reducedFilterState = Object.entries(updateFiltersStateSelections).reduce(
    (updatedState: ContextFilterState, [key, keyFunction]: any) => {
      keyFunction(updatedState)
      return updatedState
    },
    filtersState
  )

  return reducedFilterState
}

const applyNewFiltersState = (
  privileges: FilterBarComponentsState,
  displayConfig: FilterDisplayConfig,
  filtersState: ContextFilterState
) => {
  const targetFiltersState = { ...filtersState, privileges, display: { ...displayConfig } }
  const updatedTargetFiltersState = reduceFiltersStateFromPrivileges(targetFiltersState)

  return { ...updatedTargetFiltersState }
}
const coalesceEmptyFilterComponentsAFromB = (
  filterComponentsStateA: FilterBarComponentsState,
  filterComponentsStateB: FilterBarComponentsState
) => {
  const coalescedFilterComponents = Object.entries(filterComponentsStateA).map(([key, value]: any) => {
    if (key === 'dateRange') {
      if (value.start === null && value.end === null) {
        const returnValNullDate = filterComponentsStateB[key]
        return [key, returnValNullDate]
      } else {
        const returnValNonNullDate = filterComponentsStateA[key]
        return [key, returnValNonNullDate]
      }
    } else {
      const filterComponentALists = filterComponentsStateA as FilterBarListComponentsState
      if (filterComponentALists && filterComponentALists[key] && filterComponentALists[key].length === 0) {
        const returnListEmptyKey = filterComponentsStateB[key]
        return [key, returnListEmptyKey]
      } else {
        const returnListOriginal = filterComponentsStateA[key]
        return [key, returnListOriginal]
      }
    }
  })

  const returnFilterComponentsState: FilterBarComponentsState = Object.fromEntries(coalescedFilterComponents)
  return returnFilterComponentsState
}
//This function only uses the default filter state and the cached filter state privileges to coalesce the filter state.
//In other words, the current filter state is ignored and so are any selections on the cached filter state.
//This is useful for absolute filter state setting, such as that from a query string.
const coalesceBlindFilterState = (
  currentState: FiltersBarState,
  context: FilteredDashboards,
  filtersState: ContextFilterState,
  defaultFilterComponentsState: FilterBarComponentsState
) => {
  let updatedFilterBarState = { ...currentState }
  const cachedFilterState = getCachedFilterState(context, updatedFilterBarState.contextFiltersStates as ContextFilterState[])
  if (isEmptyFilterComponentsState(cachedFilterState?.privileges)) {
    console.warn(
      'Warning: Attempt to coalesce filter states with no established privileges, returning original state.',
      _.cloneDeep(updatedFilterBarState)
    )
    return updatedFilterBarState
  } else {
    const currentPrivileges = cachedFilterState?.privileges
    const currentDisplayConfig = cachedFilterState?.display
    if (currentPrivileges && currentDisplayConfig) {
      const privilegedFilterState = applyNewFiltersState(currentPrivileges, currentDisplayConfig, filtersState)
      privilegedFilterState.context = context
      privilegedFilterState.selections = coalesceEmptyFilterComponentsAFromB(privilegedFilterState.selections, defaultFilterComponentsState)

      updatedFilterBarState = setCachedFilterState(updatedFilterBarState, context, privilegedFilterState)

      updatedFilterBarState = setCurrentFilterState(updatedFilterBarState, privilegedFilterState)
    } else {
      console.warn('Warning: Privileges not set prior to coalesce operation. ', _.cloneDeep(currentState))
      return { ...currentState }
    }
  }
  return { ...updatedFilterBarState }
}
const coalesceWithCachedStates = (pendingState: any, cachedState: any) => {
  let { pendingFiltersState, pendingContext, defaultPendingFilterComponentsState } = pendingState
  const { cachedFiltersState, cachedContext, cachedDefaultState } = cachedState
  const cachedSelections = cachedFiltersState.selections
  const cachedDisplayConfig = cachedFiltersState.display

  const dateRangeStartIsDefault =
    cachedSelections?.dateRange?.start &&
    cachedSelections?.dateRange?.start !== null &&
    cachedDefaultState?.dateRange?.start &&
    cachedDefaultState?.dateRange?.start !== null
      ? filterMomentToString(cachedSelections?.dateRange?.start) === filterMomentToString(cachedDefaultState.dateRange.start)
      : false

  const dateRangeEndIsDefault =
    cachedSelections?.dateRange?.end &&
    cachedSelections?.dateRange?.end !== null &&
    cachedDefaultState?.dateRange?.end &&
    cachedDefaultState?.dateRange?.end !== null
      ? filterMomentToString(cachedSelections?.dateRange?.end) === filterMomentToString(cachedDefaultState.dateRange.end)
      : false

  //Set variables needed to evaluate pending state
  const pendingDisplayConfig = pendingFiltersState.display
  //Next we cycle through the pending state filter components to check for overlapping filter objects
  //Overlapping filter objects with non-empty entries will reset any selections carried over
  //from previous activity.
  const overlappingFilterObjects = Object.entries(cachedSelections).filter(([key, value]: any) => {
    //Use pending config as filter index since the display and filter components have the exact same keys, and display indicates whether or not that filter component is even used in this context.
    //Also need to check that the selection component is not empty. Null for date range, length = 0 for everything else
    return (
      pendingDisplayConfig[key] &&
      (key === FilterBarComponent.dateRange
        ? !(dateRangeStartIsDefault && dateRangeEndIsDefault)
        : (value && value.length > 0) || pendingFiltersState.selections[key].length > 0
        ? true
        : false)
    )
  })
  //Next find the filter components that are used in the new context but not in the old, if any
  const outerFilterObjects = Object.entries(pendingDisplayConfig).filter(([key, value]: any) => {
    return value === true && cachedDisplayConfig[key] === false
  })
  //Now coalesce the filter selections
  //Clone pending selections for use if coalesced overlapping filters results in a null filter state when it would not have been if valid values were used.
  const fallbackPendingSelections = _.cloneDeep({ ...pendingFiltersState.selections })
  if (overlappingFilterObjects?.length > 0) {
    //If there are overlapping filters, insert them into the default filter state of the pending state
    //This ensures the selections are clear
    pendingFiltersState = { ...pendingFiltersState, selections: { ...defaultPendingFilterComponentsState } }
    overlappingFilterObjects.forEach((filterEntry: any) => {
      const entryKey = filterEntry[0]
      const entryValue = filterEntry[1]
      pendingFiltersState = { ...pendingFiltersState, selections: { ...pendingFiltersState.selections, [entryKey]: entryValue } }
    })
  }
  if (outerFilterObjects?.length > 0) {
    outerFilterObjects.forEach((filterEntry: any) => {
      const entryKey = filterEntry[0]
      pendingFiltersState = {
        ...pendingFiltersState,
        selections: { ...pendingFiltersState.selections, [entryKey]: _.cloneDeep(fallbackPendingSelections[entryKey]) },
      }
    })
  }
  if (!(overlappingFilterObjects?.length > 0) && !(outerFilterObjects?.length > 0)) {
    //If there is no overlap or outside cases, just set it back to what it was
    pendingFiltersState = { ...pendingFiltersState, selections: { ...fallbackPendingSelections } }
  }
  return pendingFiltersState
}

const coalesceContextChangeFilterStates = (
  filterBarState: FiltersBarState,
  pendingContext: FilteredDashboards,
  pendingFiltersState: ContextFilterState,
  defaultPendingFilterComponentsState: FilterBarComponentsState
) => {
  let updatedFilterBarState = { ...filterBarState }
  //Set variables needed to evaluate current state
  const currentFiltersState = _.cloneDeep(updatedFilterBarState.filtersState)

  const currentContext = updatedFilterBarState.filtersState.context as FilteredDashboards
  //Before coalescing, we backup the current filters state to the cache
  updatedFilterBarState = setCachedFilterState(updatedFilterBarState, currentContext, currentFiltersState) as FiltersBarState
  //Make sure context is set
  pendingFiltersState = { ...pendingFiltersState, context: pendingContext }
  //Update pending filter state using all cached states
  const allCachedStates =
    updatedFilterBarState && updatedFilterBarState.contextFiltersStates ? updatedFilterBarState.contextFiltersStates : []
  const cachedFilterStates = allCachedStates.filter((cachedFiltersState: ContextFilterState) => {
    return cachedFiltersState && cachedFiltersState.context ? cachedFiltersState.context !== pendingContext : false
  })

  cachedFilterStates.forEach((cachedFiltersState: ContextFilterState) => {
    const pendingState = { pendingFiltersState, pendingContext, defaultPendingFilterComponentsState }
    const cachedContext = cachedFiltersState.context
    const cachedDefaultState = updatedFilterBarState.defaultComponentStates
      ? { ...updatedFilterBarState.defaultComponentStates[cachedContext as any] }
      : { ...emptyFilterComponentsState }

    const cachedState = { cachedFiltersState, cachedContext, cachedDefaultState }
    const updatedPendingState = coalesceWithCachedStates(pendingState, cachedState)
    pendingFiltersState = { ...pendingFiltersState, ...updatedPendingState }
  })

  //Reconcile whatever resulting selections there are with what is allowed
  pendingFiltersState = reduceFiltersStateFromPrivileges(pendingFiltersState)

  //Update the filter bar state cache
  updatedFilterBarState = setCachedFilterState(updatedFilterBarState, pendingContext, pendingFiltersState)

  //Set the pending filter state to the current state within the filters Bar
  updatedFilterBarState = setCurrentFilterState(updatedFilterBarState, pendingFiltersState)

  //Return the coalesced filter bar state
  return { ...updatedFilterBarState }
}

export const reconcileFilterBarState = (state: FiltersBarState, pageContext: FilteredDashboards) => {
  if (state && state.filtersState && state.filtersState.context !== null) {
    const currentState = _.cloneDeep({ ...state.filtersState })
    const cachedStates = _.cloneDeep([...(state.contextFiltersStates as ContextFilterState[])]) //
    const cachedFilterState = getCachedFilterState(pageContext, cachedStates)
    let updatedFiltersBarState: FiltersBarState = _.cloneDeep({ ...state })
    const currentPrivileges = currentState.privileges
    const currentDisplayConfig = currentState.display

    const privilegedFilterState = applyNewFiltersState(currentPrivileges, currentDisplayConfig, currentState)

    updatedFiltersBarState = setCachedFilterState(updatedFiltersBarState, pageContext, privilegedFilterState)

    updatedFiltersBarState = setCurrentFilterState(updatedFiltersBarState, privilegedFilterState)
    return { ...updatedFiltersBarState }
  }
  return { ...state }
}
export const coalesceFilterBarState = async (state: FiltersBarState, filterObjects: any): Promise<FiltersBarState> => {
  const { pageFilterContext: pageContext, parsedQueryParams, getFiltersForPage, defaultFilterState } = filterObjects
  const currentState = _.cloneDeep({ ...state.filtersState })
  const cachedStates = _.cloneDeep([...(state.contextFiltersStates as ContextFilterState[])]) //
  const cachedFilterState = getCachedFilterState(pageContext, cachedStates)
  let updatedFiltersBarState: FiltersBarState = _.cloneDeep({ ...state })
  //First store the default filter components values
  if (
    updatedFiltersBarState.defaultComponentStates &&
    isEmptyFilterComponentsState(updatedFiltersBarState.defaultComponentStates[pageContext] as FilterBarComponentsState)
  ) {
    updatedFiltersBarState = {
      ...updatedFiltersBarState,
      defaultComponentStates: {
        ...updatedFiltersBarState.defaultComponentStates,
        [pageContext]: { ...(defaultFilterState as FilterBarComponentsState) },
      },
    }
  }

  //First step is to check that the privileges exist for the current page context.
  if (isEmptyFilterComponentsState(cachedFilterState?.privileges)) {
    const responseObj = await getFiltersForPage()
    const rawResponse = { ...responseObj.data }
    const responseData = updateDisplayNames(rawResponse)
    updatedFiltersBarState = setContextFilterFromResponse(updatedFiltersBarState, pageContext, responseData)
  }

  //---At this point, we can gaurantee that the privileges have been set for the page context/filter cache
  //Since we don't know whether or not the cached state had to be fetched or not, we have to re-extract the
  //set of cached states that may or may not have just been updated.
  const updatedCachedStates = _.cloneDeep([...(updatedFiltersBarState.contextFiltersStates as ContextFilterState[])])
  const updatedCachedFilterState = getCachedFilterState(pageContext, updatedCachedStates)

  //Because the current state may (from query filters) or may not (from alternate dashboard) have a null context,
  //we check the query filter state using the page context privileges
  //First check to see if we got here via URL
  const queryFilterComponentsState = getFiltersOjectFromURL(parsedQueryParams, updatedFiltersBarState.filtersState.privileges)
  //Check if the query state is empty or not. (Filters from URL)
  if (!isEmptyFilterComponentsState(queryFilterComponentsState)) {
    //If there are query params, then coalesce with the default state and return
    const queryFilterState: ContextFilterState = {
      ...updatedFiltersBarState.filtersState,
      context: pageContext,
      selections: queryFilterComponentsState,
    }
    // Options for query state set within the next function
    updatedFiltersBarState = coalesceBlindFilterState(updatedFiltersBarState, pageContext, queryFilterState, defaultFilterState)
    //Since this corresponds to the first time a filter context is being set, we can return
    return updatedFiltersBarState
    //check privelages
    //set context
    //set to current
  } //If no query params, check to see if there is a current filter state
  else if (currentState.context === null) {
    //Neccessarily means that query filters were empty
    //null filter state means this is the first time a filter is being set or we got here from a query filter
    //If there isnt a current filter state (no context, no values that are not null, meaning not even default)
    //Then:
    //Set filterstate to coalesced version of default state and cached/just fetched filter state for page
    updatedFiltersBarState = coalesceBlindFilterState(updatedFiltersBarState, pageContext, emptyContextFilterState, defaultFilterState)
    // updatedFiltersBarState = setCurrentFilterStateFromCache(updatedFiltersBarState, pageContext)
    //Since this corresponds to the first time a filter context is being set, we can return
    return updatedFiltersBarState
  }
  //If the current state isn't totally empty we want to capture as much of it as possible
  else if (currentState.context !== null) {
    //context isn't null, that means we need to check it agains the page context
    //If the context is not null, it means we've navigated here from another dashboard
    if (currentState.context === pageContext) {
      //If the current context matches the page context, we don't really need to do anything,
      //the currentState is the most up to date state.
      //check privileges
      updatedFiltersBarState = reconcileFilterBarState(updatedFiltersBarState, pageContext)
    } else {
      //If contexts don't match, check to see if there is a cached version for the page
      //Make sure there are cached states
      //Check to see if there are any non-null fields within the selections that can be updated in the cached version
      //If there is any overlap, we'll clone the cached version and then modify the cached version to match the current filter state
      //Fields not included in the current filter are reset to empty or default if date dateRange
      //Lastly, we review the clone and see if there are non empty fields in it that are now empty in the newly cached version
      //If we find something that exists within the new set of cached options, it gets copied over
      //Otherwise just return the newly cached version
      //If no cached version is found, use the default value for the page (passed as an arg here)
      //Then modify the default state to include data from overlapping fields where privileges match
      // updatedFiltersBarState = mergeCurrentStateToPageDefault(currentState, defaultFilterState, responseData)
      //Copy new state to cachedState for page
      //New state becomes the current state for the filters bar
      //Fetched data gauranteed to exist in context states
      //Current state is the state we are changing from
      //Pending state is the state we are changing to
      const pendingFilterState: any = { ...updatedCachedFilterState }

      //We have to make sure the filter has at least the default values
      if (isEmptyFilterComponentsState(pendingFilterState?.selections)) {
        pendingFilterState.selections = { ...defaultFilterState }
      }
      updatedFiltersBarState = coalesceContextChangeFilterStates(
        updatedFiltersBarState,
        pageContext,
        pendingFilterState as ContextFilterState,
        defaultFilterState
      )
    }
  }
  return updatedFiltersBarState
}
