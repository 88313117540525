import { countrCodeVariableMask } from '../../constants/generic'

export const strList2CommaSpaceStr = (items: any) => {
  return `${items
    .map((item: any) => {
      return item && item.label ? `${item.label}` : `${item}`
    })
    .join(', ')}`
}

export const pkLabelHyphenatedString = (
  pk: any,
  label: string,
  nullBack: string = '-',
  unkBack: string = '-'
) => {
  const returnStr = `${pk} - ${label}`
  if (returnStr === 'null - null') {
    return nullBack
  } else if (returnStr === 'undefined - undefined') {
    return unkBack
  } else {
    return returnStr
  }
}

export const formatCountryCode = (currentVal: any) => {
  let strLength: any = null
  let numberString = ''
  // console.log('Current val is: ', currentVal)
  if (currentVal === '+') {
    strLength = 0
  } else if (currentVal.includes('+')) {
    numberString = currentVal.split('+')[1]
    // console.log('Number string is: ', numberString)
  } else {
    numberString = currentVal
  }
  strLength = numberString?.length
  if (numberString !== '') {
    // console.log('String length is: ', strLength)
    strLength = strLength > 3 ? 3 : strLength
    strLength = strLength <= 0 ? 1 : strLength
    return countrCodeVariableMask[strLength - 1]
  } else {
    return ['+']
  }
}
