import { CInputGroup, CRow, CSwitch } from '@coreui/react'
// @ts-ignore
import React from 'react'
import { CInputGroupRowPrepend, CInputGroupRowAppend } from '../..'
import StyledLabel from '../text/StyledLabel'
const FToggleGroupRow = (props: any) => {
  const { useFormik, fieldName, iconStart, displayStr, toggleStr, iconEnd, endStr, textStyle, style, borderStyle, handleChange, values, useDivLabel } = props
  //console.log('Toggle group row props are: ', props)
  return useDivLabel ? (
    <div className="div-labeled-row">
      <div className="div-label-text">{toggleStr}</div>
      <div className="div-flex-end">
        <CSwitch
          className="mx-2"
          color={'success'}
          checked={values[fieldName]}
          onChange={(val: any) => {
            if (useFormik) {
              handleChange(val.target.checked, fieldName)
            } else {
              handleChange(val)
            }
          }}
          name={fieldName}
          shape={'pill'}
          labelOn={'\u2713'}
          labelOff={'\u2715'}
          value={values[fieldName]}
        />
      </div>
    </div>
  ) : (
    <CRow className="mb-3">
      <CInputGroup>
        {/* <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} /> */}
        <StyledLabel labelStr={toggleStr} textStyle={textStyle} borderStyle={borderStyle} />
        <CSwitch
          className={'mx-2'}
          color={'success'}
          checked={values[fieldName]}
          onChange={(val: any) => handleChange(val)}
          name={fieldName}
          shape={'pill'}
          labelOn={'\u2713'}
          labelOff={'\u2715'}
          value={values[fieldName]}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
    </CRow>
  )
}
export default FToggleGroupRow
