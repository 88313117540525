import React, { useState, useEffect, useCallback } from 'react'
import StyledSpinner from '../../../components/spinners/StyledSpinner'

/**
 * Top level control of active tab among tabs
 */

export type TabKey = null | string | number
export interface TabState {
  active: TabKey
  setActiveTab: (tab: TabKey) => void
}

const initialState: TabState = {
  active: null,
  setActiveTab: () => {},
}
export const TabContext = React.createContext({ ...initialState })

const PTabsProvider = (props: any) => {
  const { children, activeTab, onActiveTabChange } = props

  const [active, setActive] = useState<TabKey>(null)

  useEffect(() => {
    if (activeTab !== null) {
      console.log('Incoming active tab to set is: ', activeTab)
      setActive(activeTab)
    } else {
      console.warn('Active tab not yet initialized.', activeTab)
    }
  }, [activeTab])

  const setActiveTab = useCallback((tab: TabKey) => {
    if (tab !== null) {
      console.log('Tab clicked in PTabsProvider is: ', tab)
      onActiveTabChange && onActiveTabChange(tab)
      setActive(tab)
    } else {
      console.warn('Attempted to set active tab to null.')
    }
  }, [])

  if (active === null) {
    return <StyledSpinner message="Initializing tabs..." />
  }
  console.log('Before provider, active is: ', active)
  return (
    <React.Fragment>
      <TabContext.Provider value={{ active, setActiveTab }}>{children}</TabContext.Provider>
    </React.Fragment>
  )
}

export default PTabsProvider
