import CIcon from '@coreui/icons-react'
import { CInputGroupAppend, CInputGroupText } from '@coreui/react'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

const key = uuidv4()
let count = 0
let count2 = 0
const CInputGroupRowAppend = (props: any) => {
  const { iconEnd, endStr } = props
  let appendItems: any[] = []
  if (iconEnd) {
    appendItems.push(
      <CInputGroupText key={`${key}-00${count++}`}>
        <CIcon name={iconEnd} />
      </CInputGroupText>
    )
  }
  if (endStr) {
    appendItems.push(<CInputGroupText key={`${key}-000${count2++}`}>{endStr}</CInputGroupText>)
  }
  if (appendItems.length > 0) {
    return <CInputGroupAppend key={key}>{appendItems}</CInputGroupAppend>
  } else {
    return <></>
  }
}
export default CInputGroupRowAppend
