import React from 'react'
import './buttons.scss'

const PWrapperButton = (props: any) => {
  let { className, onClick, disabled, active, block, color, size, pressed, shape, variant, buttonType, svgComponent: SVGComponent, children, ...attributes } = props

  const click = (e: any) => !disabled && onClick && onClick(e)

  return (
    <button className={className || 'btn-clear'} type={buttonType || 'button'} disabled={disabled} {...attributes} onClick={click}>
      {children}
    </button>
  )
}

export default PWrapperButton
