import * as React from 'react'
import * as colors from '../../../../styles/ts/colors'
function PathSpotHandScannerRaw(props: any) {
  const { secondaryBodyColor, bodyColor, screenColor, strokeColor, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 513.62 294.33'} className={className || 'p-icon-3xl'}>
      <path
        fill={secondaryBodyColor || colors.pathspotGrayLight}
        d="M505.52 164.6l.1.9-.2 1.1-.8 1.3-1.2 1.3-1.8 1.2-130.3 77.43-2.2 1.2-2.6 1-3.1.89-3.3.81-3.5.6-3.7.5-3.8.2-3.9.09-3.7-.09-3.6-.3-3.4-.4-.12-.02v-.01l-1.08-16.38.1-4.4.4-5 .5-3.21.6-3.1.7-2.8 1.4-4.6 1.7-4.21 1.8-3.7 2-3.1 69.6-108.6 6-8 6.3-6.7 6.7-5.1 6.8-3.6 29.35-11.36 4.01.58 3.87.91 2.97 1.27 2.5 1.3 2.4 1.7 2.1 1.6 1.9 1.9 2.6 3.2 2.5 4 1.2 2.5 1.1 3.1 1 3.5.65 3.69-.05.01 9.5 76.9z"
      />
      <path
        fill={secondaryBodyColor || colors.pathspotGrayLight}
        d="M128.38 249.66l-.17-.04-.9-.09-.4-.05-.4-.06h-.7l-.2-.09-95.6-16.19v-.02l-5.9-1.6-2.7-1.3-2.6-1.6-2.3-2.1-1.8-2.41-1.3-2.7-.5-2.1-.1-.1-4.8-27.41.4 1.3.8 1.2 1.4 1.2 1.9 1 2.4 1 2.8.8 3.1.6 313.59 53.41.12.02 3.4.4 3.6.3 3.7.09 3.9-.09 3.8-.2 3.7-.5 3.5-.6 3.3-.81 3.1-.89 2.6-1 2.2-1.2 130.3-77.43 1.8-1.2 1.2-1.3.8-1.3.2-1.1-3 36.4-.1.3-.3 1.4-1 1.7-1.6 1.7-2.1 1.4.01.01-18.51 10.8-20.7 11.61-21.8 12.01-22.7 12.5-23.4 13.09-23 13.41-3.5 1.7-3.2 1.09-2.8.61-.5.1-.1.09-1.1.2-2.1.3-1.9.2-1.3.11-1.5.1h-4.5l-2.6-.21-2.9-.39h-.1l-95.61-16.31-1.4-.19-1-.31-1.2-.19-1-.2H239.41l-1.1-.11-.8-.09-.7-.1H236.51l-103.8-17.61-1.3-.29-1-.21-1.2-.29-.83-.17z"
      />
      <path
        d="M298.23 171.15l53.52-77.61 1.57-2.28.5-.76.1-.3-.1-.4-.1-.2-.2-.1-.3-.1-.4-.1-189.31-32.2-.5-.1h-.4l-.5.2-.4.2-.6.5-.6.8-58.3 84.18-2.3 3.32-2.2 3.2-1.09 2.35-.41.85-.1.56.03.32.17.22.2.13.47.16L285.22 186h.9l.3-.1.3-.2.8-.5.9-.8 1.9-2.2 2.55-3.27 5.36-7.78zM467.27 58.44L437.92 69.8l-6.8 3.6-6.7 5.1-6.3 6.7-6 8-69.6 108.6-2 3.1-1.8 3.7-1.7 4.21-1.4 4.6-.7 2.8-.6 3.1-.5 3.21-.4 5-.1 4.4 1.08 16.38v.01L20.81 198.9l-3.1-.6-2.8-.8-2.4-1-1.9-1-1.4-1.2-.8-1.2-.4-1.3-.01-1 1.31-16.2.4-3.4.7-3.3 1.9-6 2.4-5.1 2.8-4.3-.02-.01L96.81 45.8l3.8-4.8 3.2-3.7 2.8-2.9 2.8-2.6 2.4-2 2.5-2.1 3.5-2.4 4.4-2.7 5.5-2.6 26-10.7.1.2 2.1-.9 4.1-.6 4.3.2 302.96 50.24zM347.52 123.8v-1.1l-.4-1-1.5-1.5-2-.6-1.4-.1-1.6.1-1.6.4-1.7.6-1.6.9-1.4 1-1.2 1.3-.8 1.3-.5 1.3-.1 1.2.7 2 1.4 1.1 1.8.6 1.3.1 1.5-.1 1.6-.4 1.5-.5 3-1.7 2.1-2.4.9-2.5z"
        fill={secondaryBodyColor || colors.pathspotGrayLight}
      />
      <path
        fill={bodyColor || colors.pathspotGrayDark}
        d="M353.82 89.8l.1.4-.1.3-.5.76-1.57 2.28L164.51 61.7h-.1l-.6.1-.9.3-.7.4-.46.55-54.06 76.91-3.28 4.44-2.2-1.52 58.3-84.18.6-.8.6-.5.4-.2.5-.2h.4l.5.1 189.31 32.2.4.1.3.1.2.1.1.2z"
      />
      <path
        fill={screenColor || colors.dgrey}
        d="M351.75 93.54l-53.52 77.61-5.41 6.05-.4.4-.41.3-.59.2-.46.13-.41.08-.49-.09-184.6-31.37-.65-.25-.4-.3-.2-.3-.2-.4.1-.7.3-.5 3.28-4.44 54.06-76.91.46-.55.7-.4.9-.3.6-.1h.1l187.24 31.84z"
      />
      <path
        fill={bodyColor || colors.pathspotGrayDark}
        d="M347.52 122.7v1.1l-.9 2.5-2.1 2.4-3 1.7-1.5.5-1.6.4-1.5.1-1.3-.1-1.8-.6-1.4-1.1-.7-2 .1-1.2.5-1.3.8-1.3 1.2-1.3 1.4-1 1.6-.9 1.7-.6 1.6-.4 1.6-.1 1.4.1 2 .6 1.5 1.5.4 1zM292.82 177.2l5.41-6.05-5.36 7.78-2.55 3.27-1.9 2.2-.9.8-.8.5-.3.2-.3.1H285.22L96.98 153.99l-.47-.16-.2-.13-.17-.22-.03-.32.1-.56.41-.85 1.09-2.35 2.2-3.2 2.3-3.32 2.2 1.52-.3.5-.1.7.2.4.2.3.4.3.65.25 184.6 31.37.49.09.41-.08.46-.13.59-.2.41-.3.4-.4z"
      />
      <g fill="none" stroke={strokeColor || colors.pathspotBlack} strokeLinecap="round" strokeLinejoin="round" strokeWidth={16}>
        <path d="M153.81 9.5l2.1-.9 4.1-.6 4.3.2M164.51 61.7h-.1M160.51 58.7l.6-.8.6-.5.4-.2.5-.2h.4l.5.1M96.98 153.99l-.47-.16-.2-.13-.17-.22-.03-.32.1-.56.41-.85 1.09-2.35 2.2-3.2M105.46 146.85l-.65-.25-.4-.3-.2-.3-.2-.4.1-.7.3-.5M104.41 144.4l3.28-4.44 54.06-76.91.46-.55.7-.4.9-.3.6-.1" />
        <path d="M99.91 146.2l2.3-3.32 58.3-84.18M164.51 61.7l187.24 31.84M437.92 69.8l29.35-11.36L164.31 8.2M412.12 93.2l6-8 6.3-6.7 6.7-5.1 6.8-3.6M290.06 178.22l-184.6-31.37M285.22 186h.3M292.87 178.93l-2.55 3.27-1.9 2.2-.9.8-.8.5-.3.2-.3.1h-.6M298.36 171.01l-.13.14-5.41 6.05-.4.4-.41.3-.59.2-.46.13-.41.08-.49-.09M343.62 119.6l2 .6 1.5 1.5.4 1v1.1l-.9 2.5-2.1 2.4-3 1.7-1.5.5-1.6.4-1.5.1-1.3-.1" />
        <path d="M335.62 131.3l-1.8-.6-1.4-1.1-.7-2 .1-1.2.5-1.3.8-1.3 1.2-1.3 1.4-1 1.6-.9 1.7-.6 1.6-.4 1.6-.1 1.4.1M163.51 57.1l189.31 32.2.4.1.3.1.2.1.1.2.1.4-.1.3-.5.76-1.57 2.28-53.52 77.61-5.36 7.78M285.22 186L96.98 153.99M334.4 252.3l-1.08-16.38M333.32 235.92l.1-4.4.4-5 .5-3.21.6-3.1.7-2.8 1.4-4.6 1.7-4.21 1.8-3.7 2-3.1M412.12 93.2l-69.6 108.6M128.21 249.62l.1.11.07-.07.03-.04M239.11 268.53H239.41" />
        <path d="M128.21 249.62l.17.04.83.17 1.2.29 1 .21 1.3.29M236.51 268.23H236.81l.7.1.8.09.8.11M239.11 268.53l1 .2 1.2.19 1 .31 1.4.19M126.41 249.42h.1l.4.06.4.05.9.09M125.61 249.33l.2.09M350.02 286.33l.9-.1 1.3-.11 1.9-.2 2.1-.3 1.1-.2.1-.09M20.81 198.9l313.59 53.41.12.02M371.32 247.83l130.3-77.43M501.62 170.4l1.8-1.2 1.2-1.3.8-1.3.2-1.1-.1-.9M334.52 252.33l3.4.4 3.6.3 3.7.09 3.9-.09 3.8-.2 3.7-.5 3.5-.6 3.3-.81 3.1-.89 2.6-1 2.2-1.2M153.71 9.3l-26 10.7M127.71 20l-5.5 2.6-4.4 2.7-3.5 2.4-2.5 2.1-2.4 2-2.8 2.6-2.8 2.9-3.2 3.7-3.8 4.8M9.31 174.6L8 190.8l.01 1 .4 1.3.8 1.2 1.4 1.2 1.9 1 2.4 1 2.8.8 3.1.6M505.52 164.6l-9.5-76.9" />
        <path d="M17.51 152.5l-2.8 4.3-2.4 5.1-1.9 6-.7 3.3-.4 3.4M496.12 88l-.05-.31-.65-3.69-1-3.5-1.1-3.1-1.2-2.5-2.5-4-2.6-3.2-1.9-1.9-2.1-1.6-2.4-1.7-2.5-1.3-2.97-1.27-3.87-.91-4.01-.58M96.81 45.8L17.49 152.49l-.08.11M497.52 208.4l2.1-1.4 1.6-1.7 1-1.7.3-1.4M502.62 201.9l3-36.4M12.91 219.31l.5 2.1 1.3 2.7 1.8 2.41 2.3 2.1 2.6 1.6 2.7 1.3 5.9 1.6M8.01 191.8l4.8 27.41M125.81 249.42h.6l.5.06.5.05 1.1.09M236.81 268.23l.5.1 1 .09 1.1.11M367.42 281.83l23-13.41 23.4-13.09 22.7-12.5 21.8-12.01 20.7-11.61 18.51-10.8.19-.11M29.91 233.12l.1.02 95.6 16.19M132.71 250.62l103.8 17.61M243.71 269.42l95.61 16.31M339.42 285.73l2.9.39 2.6.21h4.5l1.5-.1M357.42 285.33l.5-.1 2.8-.61 3.2-1.09 3.5-1.7" />
      </g>
    </svg>
  )
}

const PathSpotHandScanner = React.memo(PathSpotHandScannerRaw)
export default PathSpotHandScanner
