import { pathspotPrimary, pathspotWhite, toastifyError } from '../../styles/ts/colors'
import * as css from './css-properties'

export const modalwrapper = (args: any) => {
  return {
    display: 'flex !important',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  }
}

export const modalContainer = (args: any) => {
  return {
    display: 'inline-flex !important',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignitems: 'center',
    height: '90%',
    width: '90%',
  }
}
export const modalHeader = (args: any) => {
  return {
    borderBottom: '0px solid !important',
  }
}

export const modalTitleContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    ...css.alignItemsCenter,
    ...css.justifyContentCenter,
    ...css.flexNoWrap,
    ...css.fullWidth,
  }
}
export const modalTitleContent = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.autoHeight,
    marginBottom: '2rem',
  }
}
export const modalTitleText = (args: any) => {
  const { justifyContent, alignItems, textAlign, fontWeight, fontStyle, fontSize, margin } = args
  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    justifyContent: justifyContent,
    color: pathspotPrimary,
    fontSize: fontSize || '2rem',
    textAlign: textAlign,
    alignItems: alignItems,
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || 'bold',
    ...margin,
  }
}
export const modalDetailsContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    width: '80%',
    ...css.justifyContentSpaceBetween,
    ...css.flexColumn,
    ...css.flexWrap,
    ...css.alignItemsCenter,
    ...css.alignSelfCenter,
  }
}
export const modalDetailsLineContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.fullWidth,
    ...css.flexRow,
    ...css.justifyContentSpaceBetween,
    ...css.alignItemsBaseline,
    ...css.alignSelfCenter,
    marginBottom: '1rem',
  }
}
export const modalDetailsHeaderContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    width: '30%',
    marginRight: '1rem',
  }
}
export const modalDetailsHeaderText = (args: any) => {
  return {
    ...css.inlineFlex,
    fontSize: '1.25rem',
    color: pathspotPrimary,
    fontWeight: 'bold',
  }
}
export const modalDetailsFieldContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    ...css.justifyContentStart,
    width: '70%',
    marginLeft: '1rem',
  }
}
export const modalDetailsFieldContent = (args: any) => {
  return {
    minWidth: '80%',
    border: ' 0px dashed $pathspot-secondary',
  }
}
export const modalInputWrapper = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexColumn,
    ...css.justifyContentSpaceBetween,
    ...css.alignItemsCenter,
  }
}
export const modalInputContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexColumn,
    ...css.flexNoWrap,
    backgroundColor: pathspotWhite,
    color: pathspotPrimary,
    ...css.justifyContentCenter,
    ...css.alignItemsCenter,
    borderColor: pathspotPrimary,
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    width: args.width || '80%',
    height: args.height || 'auto',
    // margin: args.margin || '0.5rem',
  }
}
export const modalInputItem = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    ...css.flexWrap,
    ...css.justifyContentStart,
    ...css.alignItemsStart,
    ...css.fullHeight,
    borderWidth: '0px',
    wordBreak: 'normal',
    overflowWrap: 'normal',
    textAlign: 'left',
    width: args.width || '95%',
    fontSize: args.fontSize || '1rem',
  }
}

export const modalAnchorText = (args: any) => {
  return {
    ...css.inlineFlex,
    fontSize: '1rem',
    color: args.color || toastifyError,
    marginLeft: '0.5rem',
    fontWeight: 600,
    ':hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }
}
