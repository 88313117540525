import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilFolderOpen,
  cilSpeech,
  cilSun,
  cilMoon,
  cilSettings,
  cilLockLocked,
  cilUser,
  cilPeople,
  cilChevronBottom,
  cilChevronTop,
  cilAccountLogout,
  cilCloudDownload,
  cilCast,
  cilBan,
  cilScrubber,
  cilEnvelopeClosed,
  cilShortText,
  cilPhone,
  cilBuilding,
  cilAddressBook,
  cilLaptop,
  cilList,
  cilPlus,
  cilCenterFocus,
  cilObjectGroup,
  cilUsb,
  cilCalendar,
  cilNotes,
  cilLifeRing,
  cilCloud,
  cilCloudUpload,
  cilDelete,
  cilTrash,
  cilInfo,
  cilDevices,
  cilTablet,
  cilInput,
  cilMemory,
  cilBarcode,
  cilCamera,
  cilStream,
  cilDescription,
  cilListNumbered,
  cilTag,
  cilTextSquare,
  cilTask,
  cilTerminal,
  cilLevelUp,
  cilLan,
  cilCreditCard,
  cilTruck,
  cilText,
  cilSmile,
  cilLocationPin,
  cilScreenSmartphone,
  cilDialpad,
  cilAsterisk,
  cilPenNib,
  cilPen,
  cilMediaPause,
  cilColorBorder,
  cilAvTimer,
  cilCheck,
  cilMap,
  cilLibraryAdd,
  cilActionRedo,
  cilClone,
  cilCalendarCheck,
  cilChartLine,
  cilCalculator,
  cilGlobeAlt,
  cilClock,
  cilBarChart,
  cilImage,
  cilArrowBottom,
  cilArrowTop,
  cilArrowRight,
  cilChart,
  cilLibraryBuilding,
  cilUserPlus,
  cilHouse,
  cilActionUndo,
  cilSwapHorizontal,
  cilSwapVertical,
  cilPlaylistAdd,
  cilPlant,
  cil3d,
  cilArrowThickTop,
  cilArrowThickBottom,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilLoop,
  cilLoopCircular,
  cilLightbulb,
  cilLink,
  cilLinkAlt,
  cilListNumberedRtl,
  cilExpandDown,
  cilExpandUp,
  cilExpandLeft,
  cilExpandRight,
  cilInputPower,
  cilLineStyle,
  cilPaperclip,
  cilNoteAdd,
  cilOptions,
  cilSend,
  cilResizeBoth,
  cilResizeHeight,
  cilResizeWidth,
  cilSitemap,
  cilShare,
  cilRoom,
  cilRss,
  cilReload,
  cilPuzzle,
  cilPowerStandby,
  cilSpreadsheet,
  cilStorage,
  cilViewQuilt,
  cilAppsSettings,
  cilAperture,
  cilAt,
  cilAudio,
  cilBackspace,
  cilBellExclamation,
  cilBell,
  cilBeaker,
  cilBlurCircular,
  cilBookmark,
  cilBluetooth,
  cilBorderAll,
  cilChartPie,
  cilChatBubble,
  cilChevronLeft,
  cilChevronRight,
  cilChevronDoubleDown,
  cilChevronDoubleUp,
  cilChevronDoubleLeft,
  cilChevronDoubleRight,
  cilExternalLink,
  cilCheckAlt,
  cilCart,
  cilMediaStop,
  cilLoop1, cilSignLanguage,
  cilXCircle,
  cilX, cilClipboard, cilSearch,
  cilListRich,
  cilWarning,
  cilViewColumn,
  cilViewModule,
  cilFlagAlt,
  cilCode,
  cilStar,
  cilDrop,
cilArrowThickFromTop,
cilPrint,
cilFilter,
cilFilterX,
cilGraph,
cilDataTransferDown,
cilDataTransferUp, cilFile, cilCursor



} from "@coreui/icons/js/free";
import { pathspotHandScanner } from "./pathspot/catalog/pathspot-hand-scanner";
export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilFolderOpen,
    cilSpeech,
    cilSun,
    cilMoon,
    cilSettings,
    cilLockLocked,
    cilUser,
    cilChevronBottom,
    cilChevronTop,
    cilAccountLogout,
    cilCloudDownload,
    cilCast,
    cilBan,
    cilScrubber,
    cilEnvelopeClosed,
    cilShortText,
    cilPhone,
    cilBuilding,
    cilAddressBook,
    cilLaptop,
    cilList,
    cilPeople,
    cilPlus,
    cilCenterFocus,
    cilObjectGroup,
    cilUsb,
    cilCalendar,
    cilNotes,
    cilLifeRing,
    cilCloud,
    cilCloudUpload,
    cilDelete,
    cilTrash,
    cilInfo,
    cilDevices,
    cilTablet,
    cilInput,
    cilMemory,
    cilBarcode,
    cilCamera,
    cilStream,
    cilDescription,
    cilListNumbered,
    cilTag,
    cilTextSquare,
    cilTask,
    cilTerminal,
    cilLevelUp,
    cilLan,
    cilCreditCard,
    cilTruck,
    cilSmile,
    cilText,
    cilLocationPin,
    cilScreenSmartphone,
    cilDialpad,
    cilAsterisk,
    cilPenNib,
    cilPen,
    cilMediaPause,
    cilColorBorder,
    cilAvTimer,
    cilCheck,
    cilMap,
    cilLibraryAdd,
    cilActionRedo,
    cilClone,
    cilCalendarCheck,
    cilChartLine,
    cilCalculator,
    cilGlobeAlt,
    cilClock,
    cilBarChart,
    cilImage,
    cilArrowBottom,
    cilArrowTop,
    cilArrowRight,
    cilChart,
    cilLibraryBuilding,
    cilUserPlus,
    cilHouse,
    cilActionUndo,
    cilSwapHorizontal,
    cilSwapVertical,
    cilPlaylistAdd,
    cilPlant,
    cil3d,
    cilArrowThickTop,
    cilArrowThickBottom,
    cilArrowThickLeft,
    cilArrowThickRight,
    cilLoop,
    cilLoopCircular,
    cilLightbulb,
    cilLink,
    cilLinkAlt,
    cilListNumberedRtl,
    cilExpandDown,
    cilExpandUp,
    cilExpandLeft,
    cilExpandRight,
    cilInputPower,
    cilLineStyle,
    cilPaperclip,
    cilNoteAdd,
    cilOptions,
    cilSend,
    cilResizeBoth,
    cilResizeHeight,
    cilResizeWidth,
    cilSitemap,
    cilShare,
    cilRoom,
    cilRss,
    cilReload,
    cilPuzzle,
    cilPowerStandby,
    cilSpreadsheet,
    cilStorage,
    cilViewQuilt,
    cilAppsSettings,
    cilAperture,
    cilAt,
    cilAudio,
    cilBackspace,
    cilBellExclamation,
    cilBell,
    cilBeaker,
    cilBlurCircular,
    cilBookmark,
    cilBluetooth,
    cilBorderAll,
    cilChartPie,
    cilChatBubble,
    cilChevronLeft,
    cilChevronRight,
    cilChevronDoubleDown,
    cilChevronDoubleUp,
    cilChevronDoubleLeft,
    cilChevronDoubleRight,
    cilExternalLink,
    cilCheckAlt,
    cilCart,
    cilMediaStop,
    cilLoop1, cilSignLanguage,
    cilXCircle,
    cilX, cilClipboard, cilSearch,
    cilListRich,
    cilWarning,
    cilViewColumn,
    cilViewModule,
    cilFlagAlt,
    cilCode,
    cilStar,
    cilDrop,
    pathspotHandScanner,
    cilArrowThickFromTop,
    cilPrint,
    cilFilter,
    cilFilterX,
    cilGraph,
    cilDataTransferDown,
cilDataTransferUp,
cilFile,cilCursor

  }
);
