import { pathspotPrimary, pathspotWhite, toastifyError } from '../../styles/ts/colors'
import * as css from './css-properties'

// export const maxWidth = (args: any = { width: null }) => {
//   const { width, offset } = args
//   const widthValue = width ? (width === null ? '100%' : width) : '100%'
//   const widthExp = offset ? `calc(${widthValue} - ${offset})` : widthValue
//   console.log('Width value is: ', widthExp)
//   return widthExp
// }

export const maxWidthClass = (args: any = { width: null }) => {
  const { width, offset } = args
  const widthValue = width ? (width === null ? '100%' : width) : '100%'
  const widthExp = offset ? `calc(${widthValue}-${offset})` : widthValue
  return {
    width: widthExp,
  }
}

export const border = (args: any) => {
  return {
    borderWidth: args?.borderWidth ? args.borderWidth : undefined,
    borderColor: args?.borderColor ? args.borderColor : pathspotPrimary,
    borderStyle: args?.borderStyle ? args.borderStyle : 'solid',
    borderRadius: args?.borderRadius ? args.borderRadius : '1rem',
  }
}
// border={{ borderWidth: '2px', borderStyle: 'solid', borderColor: toastifyError }
export const debugBorder = (args: any = {}) => {
  const { borderColor, borderStyle, borderWidth, borderRadius, show } = args
  if (show === false)
    return {
      borderWidth: borderWidth || '0px',
      borderColor: borderColor || toastifyError,
      borderStyle: borderStyle || 'solid',
      borderRadius: borderRadius || '0rem',
    }
  return {
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || toastifyError,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '0rem',
  }
}

export const wrapper = (args: any) => {
  const { asCard, flexDirection, flexWrap, margin, width, padding, border, justifyContent, alignItems } = args

  const cardAttrs = asCard
    ? {
        border: 0,
        boxShadow:
          '0 1px 1px 0 rgba(var(--elevation-base-color), .14), 0 2px 1px -1px rgba(var(--elevation-base-color), .12), 0 1px 3px 0 rgba(var(--elevation-base-color), .20)',
        padding: '1%',
      }
    : {}

  return {
    display: 'flex !important',
    flexDirection: flexDirection || 'column',
    flexWrap: flexWrap || 'nowrap',
    width: width || '100%',
    justifyContent: justifyContent,
    alignItems: alignItems,
    ...margin,
    ...margin,
    ...padding,
    ...border,
    ...cardAttrs,
  }
}
export const container = (args: any) => {
  // console.log('Container args are: ', args)

  const {
    display,
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    width,
    height,
    minWidth,
    maxWidth,
    maxHeight,
    minHeight,
    margin: _margin,
    padding: _padding,
    border,
    isGroup,
  } = args
  const padding = typeof _padding === 'object' ? { ..._padding } : { padding: _padding }
  const margin = typeof _margin === 'object' ? { ..._margin } : { margin: _margin }
  return isGroup
    ? {
        position: 'relative',
        display: 'flex !important',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        width: '100%',
        '::before, ::after': {
          boxSizing: 'border-box',
        },
        ...margin,
      }
    : {
        display: display || 'inline-flex !important',
        flexDirection: flexDirection || 'row',
        flexWrap: flexWrap || 'nowrap',
        justifyContent: justifyContent || 'center',
        alignItems: alignItems || 'center',
        width: width || '100%',
        minWidth: minWidth,
        maxWidth: maxWidth,
        height: height,
        minHeight: minHeight,
        maxHeight: maxHeight,
        ...margin,
        ...padding,
        ...border,
      }
}
export const element = (args: any) => {
  const { flexDirection, flexWrap, justifyContent, alignItems, width, height, minWidth, maxWidth, maxHeight, margin, padding, border } =
    args

  return {
    display: 'inline-flex !important',
    flexDirection: flexDirection || 'column',
    flexWrap: flexWrap || 'nowrap',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
    width: width,
    height: height,
    ...margin,
    ...padding,
    ...border,
  }
}
export const content = (args: any) => {
  const { flexDirection, flexWrap, justifyContent, alignItems, width, position: _position, margin, border, padding, height, color } = args
  const positions = typeof _position === 'object' ? { ..._position } : undefined
  const position = _position ? (typeof _position === typeof ' ' ? _position : 'absolute') : undefined
  // if (typeof _position === 'object') {
  //   console.log('Position is: ', { lo: _position, position })
  //   console.log('Positions are: ', positions)
  // }

  return {
    display: 'inline-flex !important',
    flexDirection: flexDirection || 'row',
    flexWrap: flexWrap || 'nowrap',
    justifyContent: justifyContent || 'flex-start',
    alignItems: alignItems || 'center',
    width: width || '98%',
    position: position,
    height: height || undefined,
    color,
    ...margin,
    ...padding,
    ...border,
    ...positions,
  }
}
export const headerDivider = (args: any) => {
  return {
    borderBottom: '0px solid !important',
  }
}
export const headerContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    width: args.width || '100%',
  }
}
export const headerText = (args: any) => {
  return {
    ...css.inlineFlex,
    color: args.color || pathspotPrimary,
    fontSize: args.fontSize || '2rem',
    fontWeight: 'bold',
  }
}
export const rowFieldWrapper = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.fullWidth,
    ...css.flexRow,
    ...css.justifyContentSpaceBetween,
    ...css.alignItemsBaseline,
    ...css.alignSelfCenter,
    marginBottom: '1rem',
  }
}
export const rowFieldContainer = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    ...css.justifyContentStart,
    width: args.width || '100%',
    marginLeft: '1rem',
  }
}

export const iconAlignment = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width, textAlign, margin } = args

  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: width || '100%',
  }
}
export const tooltipAlignment = (args: any) => {
  const {
    color,
    fontSize,
    fontStyle,
    fontWeight,
    width,
    textAlign,
    justifyContent,
    margin,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
  } = args

  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    flexWrap: 'noWrap',
    width: width || '8%',
    justifyContent: justifyContent || 'flex-start',
    marginLeft: marginLeft || '1rem',
    alignItems: 'center',
  }
}
