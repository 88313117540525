import * as React from 'react'
import { pathspotGreen, pathspotPrimary } from '../../../../styles/ts/colors'

function PathSpotWarningRaw(props: any) {
  const { fillColor, strokeColor, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 503.583 449.928'} className={className || 'p-icon-5xl'}>
      <path
        d="M221.267 34.479l-199.75 345.9a35.162 35.162 0 0030.46 52.759h399.634a35.161 35.161 0 0030.459-52.759l-199.75-345.9a35.195 35.195 0 00-61.053 0z"
        fill="none"
        stroke={strokeColor || pathspotGreen}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={33.583}
      />
      <path
        d="M222.308 146.642a6.716 6.716 0 016.717-6.716h46.881a6.716 6.716 0 016.717 6.716l-14.441 135.742a15.851 15.851 0 01-15.75 14.172 15.817 15.817 0 01-15.75-14.172zM252.163 312.944h.672a27.135 27.135 0 0127.135 27.135 27.135 27.135 0 01-27.135 27.135h-.672a27.135 27.135 0 01-27.133-27.135 27.135 27.135 0 0127.133-27.135z"
        fill={fillColor || pathspotPrimary}
      />
    </svg>
  )
}

const PathSpotWarning = React.memo(PathSpotWarningRaw)
export default PathSpotWarning
