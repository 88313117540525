import { constant } from 'lodash'
import {
  grayInput,
  grayInputBackground,
  grayInputBorder,
  pathspotBgWhite,
  pathspotPrimary,
  pathspotWhite,
  toastifyError,
} from '../../styles/ts/colors'
import * as css from './css-properties'
import { defaultBorderRadius, defaultButtonTransition, fontWeightBold } from './variables'
import { paddingX, paddingY } from './shorthand'

export const baseText = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight } = args
  return {
    ...css.inlineFlex,
    color: color || pathspotPrimary,
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '600',
  }
}
export const pageTitle = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    fontSize: fontSize || '2rem',
    fontStyle: args?.fontStyle ? args.fontStyle : 'normal',
    fontWeight: args?.fontWeight ? args.fontWeight : 'bolder',
  }
}
export const buttonText = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotWhite,
    fontSize: fontSize || '1.25rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || 'bolder',
    width: width,
  }
}
export const itemText = (args: any) => {
  const { color, backgroundColor, fontSize, fontStyle, fontWeight, width, textAlign, margin } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    textAlign: textAlign || 'center',
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '600',
    justifyContent: 'center',
    alignItems: 'center',
    width: width,
    backgroundColor: backgroundColor,
    ...margin,
  }
}
export const tableInput = (args: any) => {
  const { color, fontSize, fontStyle, fontWeight, width, textAlign, margin, borderWidth, borderColor, borderStyle, borderRadius } = args

  return {
    ...css.inlineFlex,
    flexDirection: 'row',
    flexWrap: 'noWrap',
    color: color || pathspotPrimary,
    textAlign: textAlign || 'center',
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '600',
    justifyContent: 'center',
    alignItems: 'center',
    width: width || '100%',
    ...margin,
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || pathspotPrimary,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '0.25rem',
  }
}
export const fieldlabel = (args: any) => {
  const {
    textAlign,
    color,
    lineHeight,
    borderStyle,
    borderWidth,
    borderColor,
    borderRadius,
    margin,
    fontColor,
    fontSize,
    fontStyle,
    fontWeight,
  } = args
  return {
    //whitespace, overflow-wrap
    ...baseText(args),
    textAlign: textAlign,
    lineHeight: lineHeight,
    borderWidth: borderWidth || '0px',
    borderColor: borderColor || pathspotPrimary,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '1rem',
    color: fontColor || color || pathspotPrimary,
    fontSize: fontSize || '1rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || 'bold',
    ...margin,
  }
}
export const inputText = (args: any) => {
  const {
    fontSize,
    fontStyle,
    fontWeight,
    textAlign,
    color,
    backgroundColor,
    justifyContent,
    alignItems,
    flexWrap,
    flexDirection,
    width,
    height,
    margin,
    border,
    padding: _padding,
  } = args
  const { borderWidth, borderColor, borderStyle, borderRadius } = border || {}
  const autoPadding = '2%'
  const padding = _padding
    ? { ..._padding }
    : {
        paddingRight: autoPadding,
        paddingLeft: autoPadding,
        paddingTop: autoPadding,
        paddingBottom: autoPadding,
      }
  return {
    display: 'inline-flex !important',
    flexDirection: flexDirection || 'row',
    flexWrap: flexWrap || 'noWrap',
    color: color || grayInput,
    backgroundColor: backgroundColor || pathspotWhite,
    textAlign: textAlign || 'center',
    fontSize: fontSize || '0.9rem',
    fontStyle: fontStyle || 'normal',
    fontWeight: fontWeight || '500',
    justifyContent: justifyContent || 'flex-start',
    alignItems: alignItems || 'center',
    width: width || '100%',
    height: height || '100%',
    ...margin,
    ...padding,
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || grayInputBorder,
    borderStyle: borderStyle || 'solid',
    borderRadius: borderRadius || '0.25rem',
  }
}

const badgePaddingY = '.5rem'
const badgePaddingX = '.75rem'
const badgeFocusWidth = '.2rem'

const badgePillPaddingX = '.6rem'
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
const badgePillBorderRadius = '10rem'

export const badge = (args: any = {}) => {
  const { isPill, color, backgroundColor, justifyContent, alignItems, margin } = args
  return {
    display: 'inline-block',
    color: color || pathspotWhite,
    backgroundColor: backgroundColor || pathspotPrimary,
    ...paddingY(badgePaddingY),
    ...paddingX(isPill ? badgePillPaddingX : badgePaddingX),
    fontSize: '75% !default',
    fontWeight: fontWeightBold,
    lineHeight: 1,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: isPill ? badgePillBorderRadius : defaultBorderRadius,
    transition: defaultButtonTransition,
    ...margin,
    // '@at-root a#{&}': {
    //   ':hover :focus': {
    //     textDecoration: 'none',
    //   },
    // },
    // Empty badges collapse automatically
    '&:empty': {
      display: 'none',
    },
  }
}
