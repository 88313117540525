
export const matyellow = "rgba(251, 220, 143, 0.5)"
export const matblue = "rgba(103, 181, 231, 0.5)"
export const dgrey = "rgba(196, 191, 201, 0.5)"

export const grey = "rgba(234, 237, 243, 0.5)"
export const red = "rgba(249, 133, 157, 0.5)"
export const yellow = "rgba(251, 218, 124, 0.3)"

export const trq = "rgba(106, 197, 206, 0.5)"
export const blue = "rgba(100, 180, 236, 0.5)"
export const lightblue = "rgba(129, 198, 231, 0.35)"
export const darkblue = "rgba(69, 166, 211, 0.5)"

export const matgreen = "rgba(124, 178, 180, 0.75)"
export const purple = "rgba(132, 156, 212, 0.5)"
export const greengrass = "rgba(115, 186, 29, 0.5)"
export const darkGrey = "rgba(46, 49, 49, 0.25)"

export const reddish = "rgba(255,0,0,0.4)"
export const reddishnew = "rgba(255,0,0,0.35)"
export const lightreddish = "rgba(255,0,0,0.05)"


export const darkGrey2 = "rgba(46, 49, 49, 0.25)"

