import React from 'react';
import {CCard, CCardBody, CCardHeader, CCol, CDataTable, CRow} from '@coreui/react';
import ConditionalRender from '../hoc/ConditionalRender';

const CDataTableGroupColumnFilters = (props: any) => {
  const {tableData, dataFields, columnNames, divStyle, panelHeader, cRowClass, mdColSize, headerText, perPageSelect, paginationStyle, scopedSlots, noItemsViewSlot} = props;
  return (
    <div style={divStyle}>
      <ConditionalRender shouldRender={panelHeader}>{panelHeader}</ConditionalRender>
      <CRow className={cRowClass}>
        <CCol md={mdColSize}>
          <CCard>
            <CCardHeader>{headerText}</CCardHeader>
            <CCardBody>
              <CDataTable
                items={tableData}
                fields={dataFields}
                columnHeaderSlot={columnNames}
                columnFilter
                tableFilter
                itemsPerPageSelect={perPageSelect}
                itemsPerPage={perPageSelect.items}
                hover
                sorter
                pagination={paginationStyle}
                noItemsViewSlot={noItemsViewSlot}
                scopedSlots={scopedSlots}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default CDataTableGroupColumnFilters;
