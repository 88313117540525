import * as React from 'react'
import { pathspotPrimary, bananaYellow, pathspotGray, pathspotWhite50p } from '../../../../styles/ts/colors'

const PathSpotDropRaw = (props: any) => {
  const { fillColor, strokeWidth, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '-10 0 380 520'} className={className || 'p-icon-5xl'}>
      <path
        d="M179.652 10.306s125.232 164.309 156 245.022c45.247 118.694-28.975 242.813-156 242.813s-201.247-124.119-156-242.813c30.768-80.712 156-245.022 156-245.022"
        fill={fillColor || pathspotWhite50p}
        stroke={fillColor || pathspotPrimary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth || 40}
      />
    </svg>
  )
}

const PathSpotDrop = React.memo(PathSpotDropRaw)
export default PathSpotDrop
// "0 0 359.303 508.448"
