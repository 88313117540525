import React from 'react'
import { useFela } from 'react-fela'
import { content } from '../../styles/containers'

const PContentRow = (props: any) => {
  const { flexWrap } = props
  const { css } = useFela()

  return <div className={css(content({ flexWrap, row: true }))}>{props.children}</div>
}
export default PContentRow
