import { ENV } from '../../constants/generic'
import { Buffer } from 'buffer'
import { unzipSync } from 'browserify-zlib'

export const unzipb64Payload = (payload: string) => {
  try {
    if (payload === undefined) {
      return undefined
    }
    const decodedPayload = Buffer.from(payload, 'base64')
    const restoredBuffer = unzipSync(decodedPayload)
    const restoredJsonString = Buffer.from(restoredBuffer).toString('utf-8')
    const restoredJson = JSON.parse(restoredJsonString)
    return restoredJson
  } catch (e) {
    const err: any = e
    if (process.env.NODE_ENV === ENV.DEV) console.log('Error unzipping payload...', [err.toString(), Buffer.from(payload, 'base64')])
    return undefined
  }
}

export const b64decode = (data: any) => {
  const decodedPayload = Buffer.from(data, 'base64')
  // console.log('B64 decoded string is: ', decodedPayload)
  const restoredBuffer = unzipSync(decodedPayload)
  const restoredString = Buffer.from(restoredBuffer).toString('utf-8')
  return restoredString
}
