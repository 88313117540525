export const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement)
export const DOMElement = (props: any, propName: string, componentName: string) => {
  if (!(props[propName] instanceof Element)) {
    return new Error(
      'Invalid prop `' + propName + '` supplied to `' + componentName + '`. Expected prop to be an instance of Element. Validation failed.'
    )
  }
}
export const getRefDimensions = (anyRef: any) => {
  if (anyRef?.current !== null) {
    const domRect = anyRef?.current?.getBoundingClientRect()

    return domRect
  } else {
    return null
  }
}

export const getRef = (anyRef: any) => (anyRef && anyRef.current ? anyRef.current : null)
export const setRef = (anyRef: any, value: any) => (anyRef?.current !== undefined ? (anyRef.current = value) : (anyRef.current = null))
