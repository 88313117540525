import { createSlice } from '@reduxjs/toolkit'
import { SensorAction, SensorState } from '../../modules/sensors/api/sensor-types'
import { v4 as uuidv4 } from 'uuid'

export const sensorsKey = 'sensors'

const initSensorState: SensorState = {
  actions: [],
  sensorType: 1,
}
export const sensorSlice = createSlice({
  name: sensorsKey,
  initialState: initSensorState,
  reducers: {
    setActions: (state: SensorState, action: any) => {
      const { actions } = action.payload

      state.actions = actions
    },
    addAction: (state: SensorState, action: any) => {
      const { sensorAction } = action.payload
      if (!sensorAction?.id) {
        sensorAction.id = uuidv4().toString() + '-new'
      }

      const actions: SensorAction[] = state?.actions?.filter((sa: SensorAction) => sa?.id !== sensorAction?.id)

      state.actions = actions?.length > 0 ? [...actions, sensorAction] : [sensorAction]
    },
    removeAction: (state: SensorState, action: any) => {
      const { actionId } = action.payload

      if (actionId.includes('-new')) {
        const actionState: SensorAction[] = state.actions?.filter((sa: SensorAction) => sa?.id !== actionId)
        state.actions = actionState
      } else {
        const prevState: SensorAction[] = state.actions?.filter((sa: SensorAction) => sa?.id !== actionId)
        let deletedAction: SensorAction | undefined = state.actions?.find((action: SensorAction) => action.id == actionId)
        if (deletedAction) {
          deletedAction = {
            ...deletedAction,
            active: false,
          }
          state.actions = [...prevState, deletedAction]
        }
      }
    },
    resetActions: (state: SensorState) => {
      const actions: SensorAction[] = state.actions?.filter((sa: SensorAction) => !sa?.id?.includes('-new'))
      state.actions = actions
    },
    updateAction: (state: SensorState, action: any) => {
      const { sensorAction } = action.payload

      let sa: SensorAction | undefined = state.actions?.find((a: SensorAction) => a?.id == sensorAction?.id)
      let prevActions: SensorAction[] = state.actions?.filter((a: SensorAction) => a?.id != sensorAction?.id)
      const sortOrder: any[] = state?.actions?.map((action: SensorAction) => action?.id)

      if (sa) {
        const newActions: SensorAction[] = [...prevActions, sensorAction]
        const orderedActions: SensorAction[] = newActions.sort(
          (a: SensorAction, b: SensorAction) => sortOrder.indexOf(a?.id) - sortOrder.indexOf(b?.id)
        )
        state.actions = orderedActions
      }
    },
    setSensorType: (state: SensorState, action: any) => {
      const { stype } = action.payload

      state.sensorType = stype
    },
  },
})

export const { setActions, addAction, removeAction, resetActions, updateAction, setSensorType } = sensorSlice.actions
export default sensorSlice.reducer
