import React from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'
import { buttonText, pageTitle } from '../../styles/text'
import PElement from '../containers/PElement'
const componentUUID = uuidv4()
const PButtonText = (props: any) => {
  const { displayText, alignItems, justifyContent, width, fontSize, color, fontWeight, isRow, margin, padding, border } = props
  const flexDirection = isRow ? 'row' : 'column'
  const { css } = useFela()

  const key = `${displayText}-${componentUUID}`

  return (
    <PElement {...{ alignItems, justifyContent, width, margin, padding, border, flexDirection, isRow }}>
      <span className={css(buttonText({ fontSize, color, fontWeight, flexDirection, margin, padding, border }))}>{`${displayText}`}</span>
    </PElement>
  )
}

export default PButtonText
