import React from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { v4 as uuidv4 } from 'uuid'
import { buttonText, itemText, pageTitle } from '../../styles/text'
import PElement from '../containers/PElement'
import PContainer from '../containers/PContainer'
import { toastifyError } from '../../../styles/ts/colors'
const componentUUID = uuidv4()
const PErrorMessage = (props: any) => {
  // console.log('////////////////////////Pitem text props are: ', props)
  const {
    message,
    isError,
    alignItems,
    justifyContent,
    flexDirection,
    width,
    fontSize,
    color,
    backgroundColor,
    fontWeight,
    fontStyle,
    isRow,
    margin,
    padding,
    border,
    textAlign,
  } = props

  const { css } = useFela()
  const key = `${message}-${componentUUID}`

  return (
    <PContainer isRow width="100%" justifyContent="center" alignItems="center">
      {isError && (
        <PElement
          isRow
          margin={margin || { marginLeft: '0.5rem', marginTop: '0rem', marginBottom: '0.5rem' }}
          width={width || '100%'}
          justifyContent={justifyContent || 'flex-start'}
          alignItems={alignItems || 'center'}
          flexWrap={'wrap'}
        >
          <span
            key={key}
            className={css(
              itemText({
                fontSize: fontSize || '0.75rem',
                color: color || toastifyError,
                backgroundColor,
                fontWeight: fontWeight || 'bold',
                flexDirection,
                margin,
                padding,
                border,
                textAlign: textAlign || 'left',
                fontStyle,
              })
            )}
          >{`${message}`}</span>
        </PElement>
      )}
    </PContainer>
  )
}

export default PErrorMessage
