import React from 'react'
import { useFela } from 'react-fela'
import PFieldLabel from '../text/PFieldLabel'
import PContent from '../containers/PContent'
import { datepicker, dropdown } from '../../styles/selectors'
import DatePicker from 'react-datepicker'
import { debugBorder } from '../../styles/containers'
import { toastifyTextGray } from '../../../styles/ts/colors'

export const datePickerContainer = (args: any) => {
  const { justifyContent, alignItems, width } = args
  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    width: width || '50%',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
  }
}

export const labeledDatepicker = (args: any) => {
  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    borderColor: toastifyTextGray,
    minHeight: '2rem',
    borderWidth: '1px',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }
}

const PLabeledDatePicker = (props: any) => {
  const {
    fieldLabel,
    options,
    placeholder,
    value,
    onChange,
    monthYear,
    labelWidth,
    width,
    setSelectedDate,
    selectedDate,
    justifyContent,
    alignItems,
    first,
    labelIndent,
    pickerWidth,
  } = props
  const { css } = useFela()
  console.log('Props in plabeleddatepicker are: ', props)

  return (
    <PContent
      isRow
      width={width}
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent || 'space-between'}
      margin={{ marginBottom: '1rem', marginTop: first ? '1rem' : undefined }}
    >
      <PFieldLabel
        label={fieldLabel}
        alignItems="center"
        justifyContent="flex-start"
        marginLeft={labelIndent || '0rem'}
        width={labelWidth || '33%'}
      />
      <div className={css(datePickerContainer({ width: pickerWidth || '67%' }))}>
        {monthYear && (
          <DatePicker
            className={css(labeledDatepicker)}
            selected={selectedDate}
            onSelect={setSelectedDate}
            onChange={setSelectedDate} //only when value has changed
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        )}
        {!monthYear && (
          <DatePicker
            className={css(labeledDatepicker)}
            selected={selectedDate}
            onSelect={setSelectedDate}
            onChange={setSelectedDate} //only when value has changed
          />
        )}
      </div>
    </PContent>
  )
}
export default PLabeledDatePicker
