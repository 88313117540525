import React, {useState} from 'react';
import CIcon from '@coreui/icons-react';
import {CButton, CCol, CRow, CButtonToolbar} from '@coreui/react';
import SpinnerIcon from '../icons/SpinnerIcon';

export const SaveCancelButtons = (props: any) => {
  const {applyStr, cancelStr, handleReset, spinnerState, setSpinnerState} = props;
  return (
    <CRow className="mb-3">
      <CCol className="justify-content-center">
        <CButtonToolbar justify="center">
          <CButton
            type="submit"
            onClick={() => {
              setSpinnerState(true);
            }}
            color="primary"
            size="sm"
            className="px-4 mx-4"
          >
            <SpinnerIcon spinnerState={spinnerState} iconName={'cil-check'} iconClassName={'mr-2 text-center'} spinnerSize={'sm'} />
            <span>{spinnerState ? 'Submitting...' : applyStr}</span>
          </CButton>
          <CButton
            onClick={() => {
              setSpinnerState(false);
              handleReset();
            }}
            type="reset"
            color="danger"
            size="sm"
            className="px-4 mx-4"
          >
            <CIcon name="cil-ban" className="mx-2" />
            {cancelStr}
          </CButton>
        </CButtonToolbar>
      </CCol>
    </CRow>
  );
};
export default SaveCancelButtons;
