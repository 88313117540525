import React from 'react'
import classNames from 'classnames'
import PLink from '../link/PLink'
import './buttons.scss'

const PButton = (props: any) => {
  let {
    className,
    innerRef,
    onClick,
    disabled,
    active,
    block,
    color,
    size,
    pressed,
    shape,
    variant,
    buttonType,
    colorOverride,
    buttonWrapperClass,
    ...attributes
  } = props

  const click = (e: any) => !disabled && onClick && onClick(e)

  const isLink = attributes.to || attributes.href
  const colorVarClass = colorOverride ? false : variant || color ? `btn${variant ? '-' + variant : ''}-${color}` : false
  const classes = classNames(
    className,
    colorVarClass,
    // pressed ? 'btn-pressed' : false,
    'btn',
    size ? `btn-${size}` : false,
    block ? 'btn-block' : false,
    shape ? `btn-${shape}` : false,
    { active: active && !isLink, disabled: disabled && !isLink }
  )
  // console.log('PButton: attributes: checked: ', { attributes, active, classes })
  if (isLink) {
    return <PLink {...attributes} active={active} disabled={disabled} className={classes} onClick={click} innerRef={innerRef} />
  } else if (buttonWrapperClass) {
    return colorOverride ? (
      <div className={buttonWrapperClass}>
        <button
          // className={`${classes} ${pressed ? 'btn-pressed' : ''}`}
          className={classes}
          type={buttonType || 'button'}
          disabled={disabled}
          {...attributes}
          onClick={click}
          ref={innerRef}
        />
      </div>
    ) : (
      <div className={buttonWrapperClass}>
        <button
          // className={`${classes} ${pressed ? 'btn-pressed' : ''}`}
          className={classes}
          type={buttonType || 'button'}
          disabled={disabled}
          {...attributes}
          onClick={click}
        />
      </div>
    )
  } else {
    return colorOverride ? (
      <button
        // className={`${classes} ${pressed ? 'btn-pressed' : ''}`}
        className={classes}
        type={buttonType || 'button'}
        disabled={disabled}
        {...attributes}
        onClick={click}
        ref={innerRef}
      />
    ) : (
      <button
        // className={`${classes} ${pressed ? 'btn-pressed' : ''}`}
        className={classes}
        type={buttonType || 'button'}
        disabled={disabled}
        {...attributes}
        onClick={click}
      />
    )
  }
}

export default PButton
