import React from 'react'
import { useFela } from 'react-fela'
import PContentRow from '../rows/PContentRow'

import { v4 as uuidv4 } from 'uuid'
import PIcon from '../../../components/icons/PIcon/PIcon'
import PContent from '../containers/PContent'
import PButtonRow from './PButtonRow'
const componentKey = uuidv4()

const PCenterButtonRow = (props: any) => {
  const { buttons, width } = props
  const { css } = useFela()

  return <PButtonRow alignItems="center" buttons={buttons} justifyContent="center" width={width || '80%'} />
}

export default PCenterButtonRow
//     <PContent isRow width={'35%'} alignItems="center" justifyContent="space-between" margin={{ marginBottom: '1rem', marginTop: '1rem' }}>
//       {buttons.map((button: any) => {
//         const cssClasses = `btn btn-${button.shape} btn-${button.size} } `
//         const buttonDisabled = button.disabled
//         return (
//           <button
//             key={`${componentKey}-brow-${button.text}-button`}
//             style={{ cursor: buttonDisabled ? 'not-allowed' : 'pointer' }}
//             className={button?.fela ? `${cssClasses} ${css(button.fela({ disabled: buttonDisabled }))}` : cssClasses}
//             type={button.type}
//             disabled={buttonDisabled}
//             onClick={(val: any) => button.onClick(val)}
//           >
//             {button.icon && <PIcon key={`${componentKey}-icon`} name={button.icon} className={'mx-2'} />}
//             {button.text && <span className={button.icon ? 'ml-2' : 'm-2'}>{button.text}</span>}
//           </button>
//         )
//       })}
//     </PContent>
