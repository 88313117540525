import React, { useRef, useCallback, useEffect, useLayoutEffect, useState, HTMLAttributes, SVGAttributes } from 'react'
import { pathspotGreen, pathspotPrimary, pathspotSecondary, toastifyError50 } from '../../styles/ts/colors'

import _ from 'lodash'
export interface PathSpotFillableThermometerProps extends SVGAttributes<SVGSVGElement> {
  cid: number
  fillColor?: string
  strokeColor?: string
  className?: string
  arrowFill?: string
  fillPercent: number
  stemClass?: string
  outlineColor?: string
  innerFill?: string
  stemFill?: string
  bubbleFill?: string
  backgroundFill?: string
  bubbleFillClass?: string
  stemTipClass?: string
}
export const PathSpotFillableThermometer = React.forwardRef<SVGSVGElement, PathSpotFillableThermometerProps>(
  (
    {
      cid,
      fillColor,
      strokeColor,
      className,
      arrowFill,
      fillPercent,
      stemClass,
      outlineColor,
      innerFill,
      stemFill,
      bubbleFill,
      backgroundFill,
      bubbleFillClass,
      stemTipClass,
      ...props
    },
    ref
  ) => {
    let viewBox: string = '0 0 514 82'
    const stemId = `${cid}-stem`
    const stemTipId = `${cid}-stem-tip`
    const percentComplete = useRef<number | null>(null)

    const adjustProgress = useCallback(() => {
      percentComplete.current = fillPercent
      // console.log('adjustProgress to: ', percentComplete.current)
      const coefficientBarA = 8444
      const coefficientBarC = 84.39
      const coefficientP1 = -0.8058
      const coefficientP2 = 80.31
      const progressAmount = percentComplete.current === null ? 0.1 : percentComplete.current
      const progressBar = percentComplete.current === null ? 0.1 : percentComplete.current
      const translateAmountEq = coefficientBarA * (1 / progressAmount) - coefficientBarC
      const translateBar = translateAmountEq
      const stemEndTranslateEq = coefficientP1 * progressAmount + coefficientP2
      const stemEndTranslate = stemEndTranslateEq
      const stemElement = document.getElementById(stemId)
      const stemEndElement = document.getElementById(stemTipId)
      if (stemElement !== null) {
        stemElement.style.transform = `scaleX(${progressBar}%) translateX(${translateBar}%)`
      }
      if (stemEndElement !== null) {
        stemEndElement.style.transform = `translateX(${stemEndTranslate}%)`
      }
    }, [fillPercent, stemId, stemTipId])

    useLayoutEffect(() => {
      adjustProgress()
      const intervalId = setInterval(adjustProgress, 900000)
    }, [])

    return (
      <svg ref={ref} viewBox={viewBox} className={className || 'p-icon-5xl'}>
        <path
          d="M434.84 53H13C6.37 53 1 47.63 1 41c0-3.32 1.34-6.32 3.51-8.49S9.68 29 13 29h421.84"
          fill={backgroundFill || '#fff'}
          stroke={strokeColor || '#58595b'}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          className={bubbleFillClass}
          d="M508 41c0 19.33-15.67 35-35 35-16.04 0-29.55-10.79-33.69-25.5-.23-.82-.43-1.66-.6-2.5-.06-.28-.12-.57-.17-.85-.36-2-.54-4.05-.54-6.15s.18-4.15.54-6.15c.05-.28.11-.57.17-.85.17-.84.37-1.68.6-2.5C443.45 16.79 456.96 6 473 6c19.33 0 35 15.67 35 35z"
          fill={bubbleFill || pathspotPrimary}
          stroke={bubbleFill || pathspotPrimary}
          strokeMiterlimit={10}
        />

        <g id={stemTipId} className={`${stemTipClass}`} fill={stemFill || innerFill || pathspotPrimary}>
          <path d="M11.76 46.99c-3.263 0-5.973-2.589-6.041-5.771v-.459c.067-3.161 2.777-5.75 6.041-5.75h8.94v5.846l-9.71-5.604V46.75l9.71-5.605v5.846h-8.94z" />
          <path d="M19.691 36.019v3.088l-5.35-3.088h5.35m-9.71.32v9.322c-1.86-.69-3.209-2.423-3.251-4.421v-.437c.043-2.032 1.391-3.771 3.251-4.464m9.71 6.553v3.088h-5.35l5.35-3.088m2.019-8.893h-9.95c-3.79 0-6.97 2.98-7.05 6.76v.48c.08 3.78 3.26 6.76 7.05 6.76h9.95v-14zm-9.71 11v-8l6.93 4-6.93 4z" />
        </g>
        <g fill={stemFill || pathspotGreen}>
          <path d="M435.01 46.99V35.01h2.766c-.341 1.955-.515 3.971-.515 5.99s.173 4.031.515 5.99h-2.766z" />
          <path d="M436.595 36.019a36.09 36.09 0 00-.001 9.962h-.576V36.02h.576m2.405-2.019h-5v14h5a33.739 33.739 0 01-.73-7 33.739 33.739 0 01.73-7z" />
        </g>
        <g id={stemId} className={`${stemClass}`} fill={stemFill || pathspotSecondary}>
          <path d="M21.01 35.01h411.98v11.98H21.01z" />
          <path d="M431.981 36.019v9.961H22.019v-9.961h409.962M434 34H20v14h414V34z" />
        </g>
        <path
          d="M434.84 29C439.93 12.77 455.09 1 473 1c22.09 0 40 17.91 40 40s-17.91 40-40 40c-17.91 0-33.07-11.77-38.16-28"
          fill="none"
          stroke={strokeColor || '#58595b'}
          strokeMiterlimit={10}
          strokeWidth={2}
        />
      </svg>
    )
  }
)
PathSpotFillableThermometer.displayName = 'PathSpotFillableThermometer'
export default PathSpotFillableThermometer
