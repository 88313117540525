import * as React from 'react'
import {
  pathspotPrimary,
  bananaYellow,
  pathspotGray,
  pathspotRed,
  numericOrange,
  pathspotWhite,
  pathspotLavender,
  pathspotSecondary,
} from '../../../../styles/ts/colors'
import { AlertLevel } from '../../../../api/types/enums.types'
import { getAlertFillColor } from '../../../../api/base/mappers'

const PathSpotAlertRaw = (props: any) => {
  const { fillColor, strokeColor, textColor, className, viewBox, width, height, alertLevel } = props

  const alertFillColor = getAlertFillColor(alertLevel)

  return (
    <svg viewBox={viewBox || '0 0 502 502'} className={className || 'p-icon-5xl'}>
      <circle
        cx={250.909}
        cy={250.909}
        r={230}
        fill={alertFillColor || fillColor || pathspotGray}
        stroke={alertFillColor || strokeColor || pathspotGray}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={41.818}
      />
      <path
        d="M214.808 411.995c0-19.485 16.042-36.676 36.101-36.676s36.101 17.192 36.101 36.676-16.042 36.101-36.101 36.101-36.101-16.621-36.101-36.101zm67.617-328.94c0 21.202-6.303 75.641-13.749 155.872l-7.453 79.656h-20.627l-7.453-79.656c-7.446-80.231-13.749-134.67-13.749-155.872 0-24.645 12.031-34.959 31.516-34.959s31.516 10.314 31.516 34.959z"
        fill={textColor || pathspotWhite}
      />
    </svg>
  )
}

const PathSpotAlert = React.memo(PathSpotAlertRaw)
export default PathSpotAlert
// <svg viewBox="0 0 501.818 501.818" width="1em" height="1em" {...props}>
