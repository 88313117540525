import * as React from 'react'
import {
  pathspotPrimary,
  bananaYellow,
  pathspotGray,
  pathspotRed,
  numericOrange,
  pathspotWhite,
  pathspotSecondary,
  pathspotWhite50p,
} from '../../../../styles/ts/colors'
const PathSpotFilterRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 512 512'} className={className || 'p-icon-auto'}>
      <path
        fill={fillColor || pathspotPrimary}
        d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015zM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172z"
        className="prefix__ci-primary"
      />
    </svg>
  )
}

const PathSpotFilter = React.memo(PathSpotFilterRaw)
export default PathSpotFilter
