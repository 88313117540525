import React from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { fieldlabel } from '../../styles/text'
import { v4 as uuidv4 } from 'uuid'
import PContainer from '../containers/PContainer'
const componentUUID = uuidv4()
const PFieldLabel = (props: any) => {
  const {
    label,
    alignItems,
    justifyContent,
    marginRight,
    marginLeft,
    width,
    marginTop,
    marginBottom,
    border,
    fontColor,
    fontSize,
    fontStyle,
    fontWeight,
  } = props
  const { css } = useFela()
  const key = `${label}-${componentUUID}`

  return (
    <PContainer isRow alignItems={alignItems} justifyContent={justifyContent} width={width} border={border}>
      <span
        className={css(
          fieldlabel({ fontColor, fontSize, fontStyle, fontWeight, margin: { marginRight, marginLeft, marginTop, marginBottom } })
        )}
      >{`${label}`}</span>
    </PContainer>
  )
}

export default PFieldLabel
