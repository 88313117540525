import React from 'react'
import { CModal, CModalBody, CSpinner } from '@coreui/react'

const StyledSpinner = (props: any) => {
  const { message, containerClass, asModal, windowSize, spinnerSize } = props
  return asModal ? (
    <CModal className="justify-content-center" size={windowSize || 'lg'} show={true}>
      <CModalBody>
        <br />
        <div className={containerClass || 'spinner-line'}>
          <CSpinner size={spinnerSize || 'lg'} />
        </div>
        <br />
        {message && <div className="spinner-message">{`${message}`}</div>}
      </CModalBody>
    </CModal>
  ) : (
    <div className={containerClass || 'text-center my-5'}>
      <h2>
        <br />
        <div className={containerClass || 'spinner-line'}>
          <CSpinner size={spinnerSize || 'xl'} />
        </div>
        {message && <div className="spinner-message">{`${message}`}</div>}
        <br />
      </h2>
    </div>
  )
}
export default StyledSpinner
