import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/scss/p-icon.scss'
import PathSpotIcon from '../PathSpotIcon'

const toCamelCase = (str: string) => {
  return str
    .replace(/([-_][a-z0-9])/gi, ($1) => {
      return $1.toUpperCase()
    })
    .replace(/-/gi, '')
}

const PIcon = (props: any) => {
  const { className, name, size, title, outerTagClass, sizeRef, ...attributes } = props
  //console.log('!!!!P-Icon props are: ', props);
  const [change, setChange] = useState(0)

  useMemo(() => setChange(change + 1), [name])

  const iconName = useMemo(() => {
    const iconNameIsKebabCase = name && name.includes('-')
    return iconNameIsKebabCase ? toCamelCase(name) : name
  }, [change])
  //console.log('58.1-]]]] PIcon: iconName: UE', iconName);
  const titleCode = title ? `<title>${title}</title>` : ''

  const PathSpotIconMZ = useMemo(() => {
    return <PathSpotIcon iconName={iconName} {...props} sizeRef={sizeRef} />
  }, [change])

  return (
    <div className="p-icon">
      {titleCode}
      {PathSpotIconMZ}
    </div>
  )
}
export default PIcon

export const PIconWarn = (props: any) => {
  return <PIcon {...props} />
}
