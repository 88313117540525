export const lineHeightLg = '.5'
export const lineHeightSm = '1.5'

export const spacer = '1rem'
export const borderWidth = '1px'
export const fontSizeBase = '.875rem'
export const fontSizeLg = `${fontSizeBase} * 1.25`
export const fontSizeSm = `${fontSizeBase} * .875`

export const fontWeightLighter = 'lighter'
export const fontWeightLight = '300'
export const fontWeightNormal = '400'
export const fontWeightBold = '700'
export const fontWeightBolder = 'bolder'

export const fontWeightBase = `${fontWeightNormal}`
export const lineHeightBase = '1.5'

export const h1FontSize = `${fontSizeBase} * 2.5`
export const h2FontSize = `${fontSizeBase} * 2`
export const h3FontSize = `${fontSizeBase} * 1.75`
export const h4FontSize = `${fontSizeBase} * 1.5`
export const h5FontSize = `${fontSizeBase} * 1.25`
export const h6FontSize = `${fontSizeBase}`

export const headingsMarginBottom = `${spacer} / 2`
export const headingsFontFamily = 'null'
export const headingsFontWeight = '500'
export const headingsLineHeight = '1.2'
export const headingsColor = 'null'

export const display1Size = '6rem'
export const display2Size = '5.5rem'
export const display3Size = '4.5rem'
export const display4Size = '3.5rem'

export const display1Weight = '300'
export const display2Weight = '300'
export const display3Weight = '300'
export const display4Weight = '300'
export const displayLineHeight = `${headingsLineHeight}`

export const leadFontSize = `${fontSizeBase} * 1.25`
export const leadFontWeight = '300'

export const smallFontSize = '80%'

export const textMuted = 'gray700'

export const blockquoteSmallColor = 'gray600'
export const blockquoteSmallFontSize = `${smallFontSize}`
export const blockquoteFontSize = `${fontSizeBase} * 1.25`

export const hrBorderColor = 'rgba(black, .2)'
export const hrBorderWidth = `${borderWidth}`

export const vrBg = 'rgba(black, .2)'
export const vrWidth = `${borderWidth}`

export const markPadding = '.2em'

export const dtFontWeight = `${fontWeightBold}`

export const kbdBoxShadow = 'inset 0 .1rem 0 rgba(black, .25)'
export const nestedKbdFontWeight = `${fontWeightBold}`

export const listInlinePadding = '.5rem'

export const markBg = '#fcf8e3'

export const hrMarginY = `${spacer}`

export const defaultBorderRadius = '0.25rem'
export const defaultBorderWidth = '1px'
export const defaultBorderStyle = 'solid'

export const defaultButtonTransition =
  'color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out'
