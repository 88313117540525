import React from 'react'
import { CInput, CInputGroup, CRow, CInvalidFeedback } from '@coreui/react'
import { CInputGroupRowPrepend, CInputGroupRowAppend, PAlertErrors } from '../..'
const CInputGroupRow = (props: any) => {
  const { inputType, fieldName, iconStart, displayStr, placeholderStr, iconEnd, endStr, style, borderStyle, handleChange, values, touched, errors, useDivLabel, useFormik } = props
  console.log('Input group row errors are: ', errors)

  return useDivLabel ? (
    <React.Fragment>
      <div className="div-labeled-row">
        <div className="div-label-text">{displayStr}</div>
        <div className="div-flex-end">
          <CInput
            style={borderStyle}
            type={inputType}
            name={fieldName}
            placeholder={placeholderStr}
            onChange={(val: any) => {
              if (useFormik) {
                handleChange(val.target.value, fieldName)
              } else {
                handleChange(val)
              }
            }}
            value={values[fieldName]}
            invalid={errors && errors[fieldName] ? true : false}
          />
        </div>
      </div>
      {errors && errors[fieldName] && (
        <div>
          <PAlertErrors className="div-flex-end" errors={errors[fieldName]} />
        </div>
      )}
    </React.Fragment>
  ) : (
    <CRow className="mb-3">
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <CInput
          style={borderStyle}
          type={inputType}
          name={fieldName}
          placeholder={placeholderStr}
          onChange={(val: any) => handleChange(val)}
          value={values && (values[fieldName] || values[fieldName] === '') ? values[fieldName] : displayStr}
          invalid={errors && errors[fieldName] ? true : false}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
      <CInvalidFeedback>{errors[fieldName]}</CInvalidFeedback>
    </CRow>
  )
}
export default CInputGroupRow
