import React from "react";
import { CCol, CRow, CAlert } from "@coreui/react";

const CAlertRow = (props: any) => {
  const { errors, rowClass, alertDivClass, alertColor } = props;
  if (!(errors && errors.length > 0)) {
    return <div></div>;
  }

  let errorList: any[] = errors.length === 1 ? [...errors] : [errors];
  //Check if error is a string or TypedError
  return (
    <div>
      <CRow className={rowClass || "justify-content-center"}>
        <CCol md="12" sm="12">
          <div className={alertDivClass || "mt-2"}>
            {errorList.map((error: any) => (
              <CAlert color={alertColor || "danger"}>
                {error.content ? error.content.body : error}
              </CAlert>
            ))}
          </div>
        </CCol>
      </CRow>
    </div>
  );
};
export default CAlertRow;
