import { CInputGroup, CRow, CSwitch } from "@coreui/react";
// @ts-ignore
import React from "react";
import { CInputGroupRowPrepend, CInputGroupRowAppend } from "../..";
import StyledLabel from "../text/StyledLabel";
const CToggleGroupRow = (props: any) => {
  const {
    id,
    fieldName,
    iconStart,
    displayStr,
    toggleStr,
    iconEnd,
    endStr,
    textStyle,
    style,
    borderStyle,
    handleChange,
    checkedValue,
    switchShape,
    styleVariant
  } = props;
  //console.log('Toggle group row props are: ', props)
  return (
    <CRow className="mb-3">
      <CInputGroup>
        {/* <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} /> */}
        <StyledLabel
          labelStr={toggleStr}
          textStyle={textStyle}
          borderStyle={borderStyle}
        />
        <CSwitch
          id={id}
          className={"mx-2"}
          variant={styleVariant}
          color={"success"}
          checked={checkedValue}
          value={checkedValue}
          onChange={(val: any) => handleChange(val)}
          name={fieldName}
          shape={switchShape}
          labelOn={"\u2713"}
          labelOff={"\u2715"}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
    </CRow>
  );
};
export default CToggleGroupRow;
