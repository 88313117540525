export const BACKEND_OVERRIDE = 'BE/C'
export const PHONE_NUMBER_MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
export const NaS: string = '__NaS'
export const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const countrCodeVariableMask = [
  ['+', /[0-9]/],
  ['+', /[0-9]/, /[0-9]/],
  ['+', /[0-9]/, /[0-9]/, /[1-9]/],
]

// export const TIME_MASK = ['(', '/[0 - 1]', '/[0 - 9]', 2[0 - 3]): [0 - 5][0 - 9]$"]
// const timePattern = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
export enum ENV {
  PROD = 'production',
  STAGING = 'staging',
  DEV = 'development',
}

export const degreeSymbol = '\xB0'

export const breakpointColumns = {
  xs: 8,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 14,
}

export const FLOAT_MASK = /[^0-9.-]/g
