import * as React from 'react'
import { pathspotPrimary, bananaYellow, pathspotGray } from '../../../../styles/ts/colors'
const PathSpotStarRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props

  return (
    <svg viewBox={viewBox || '0 0 512 490'} className={className || 'p-icon-5xl'}>
      {/* <svg viewBox="0 0 512 490" width="1em" height="1em" {...props}> */}
      <path
        fill={checked ? fillColor || bananaYellow : fillColor || pathspotGray}
        stroke={checked ? fillColor || bananaYellow : strokeColor || pathspotGray}
        strokeWidth={66}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M187.618 173.574l67.984-137.75 67.983 137.75 152.017 22.089-110 107.224 25.967 151.402-135.967-71.483-135.968 71.483 25.968-151.402-110-107.224z"
      />
    </svg>
  )
}

const PathSpotStar = React.memo(PathSpotStarRaw)
export default PathSpotStar
