import { AlertLevel } from '../types/enums.types'
import { DropdownItem } from '../types/components-general.types'
import { alertCritical, alertLow, alertMedium, bananaYellow, pathspotGray, pathspotRed, pathspotSecondary } from '../../styles/ts/colors'
export const mapValueToDropdown = (v: string) => {
  return {
    label: v,
    value: v,
  }
}

export const getDropdownValue = (v: any) => {
  return v.value
}

export const createDropdown = (label: any, value: any) => {
  let dropdownItem: DropdownItem = {
    label: label,
    value: value,
  }
  return dropdownItem
}

export const getAlertFillColor = (alertLevel: any) => {
  return alertLevel === AlertLevel.NONE
    ? pathspotGray
    : alertLevel === AlertLevel.HIGH
    ? alertCritical
    : alertLevel === AlertLevel.MEDIUM
    ? alertMedium
    : AlertLevel.LOW
    ? alertLow
    : undefined
}
