import React from 'react'
import { CPopover } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { popoverTypes } from '../ComponentsTypes'

const CPopoverGroup = (props: any) => {
  //console.log("Props in CPopover group are: ", props)
  const { outerTagClass, header, content, contentStyle, placement, body, iconProps, popoverType } = props
  if (popoverType === popoverTypes.withIcon) {
    return (
      <div className={outerTagClass}>
        <CPopover header={header} content={content} placement={placement || 'bottom-start'}>
          <span style={contentStyle}>
            <CIcon name={iconProps.iconName} /> <span>{iconProps.content}</span>
          </span>
        </CPopover>
      </div>
    )
  } else {
    return (
      <td className={outerTagClass}>
        <CPopover header={header} content={content} placement={placement || 'bottom-start'}>
          <div style={contentStyle}>{body}</div>
        </CPopover>
      </td>
    )
  }
}
export default CPopoverGroup
