import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CDataTable, CRow } from '@coreui/react'
import { replaceEmptyObjectFields, statusClassNameMapping } from '../../api/base/object'

const PDataTableGroup = (props: any) => {
  const { isFetching, tableData, layout, tableProps, tableFilter } = props
  //console.log('tableData in wellness check: ', tableData)
  const cleanedTableData = tableData?.map((item: any) => replaceEmptyObjectFields(item, tableProps.emptyFieldString))
  const itemsPerPageSelect =
    typeof true === typeof layout?.itemsPerPage?.select ? layout.itemsPerPage.select : { ...layout?.itemsPerPage?.select }
  return (
    <div style={layout?.baseStyle}>
      <CRow className={layout?.rowClassName}>
        <CCol md={layout?.cardColWidth}>
          <CCard>
            <CCardHeader>{layout?.title}</CCardHeader>
            <CCardBody>
              <CDataTable
                items={cleanedTableData}
                fields={tableProps?.tableDataFields}
                columnHeaderSlot={tableProps?.tableDataColumnNames}
                loading={isFetching}
                columnFilter
                tableFilter={tableFilter}
                itemsPerPageSelect={itemsPerPageSelect}
                itemsPerPage={layout?.itemsPerPage?.default}
                hover
                sorter
                pagination={{ ...layout?.pagination }}
                scopedSlots={{ ...layout?.dynamicFields }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  )
}
export default PDataTableGroup
