export const continueButtonWarn = {
  buttonType: 'button',
  buttonText: 'Continue',
  buttonIcon: 'cil-chevron-right',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'warning',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const continueButton = {
  buttonType: 'button',
  buttonText: 'Continue',
  buttonIcon: 'cil-chevron-right',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'primary',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const applyButton = {
  buttonType: 'button',
  buttonText: 'Apply',
  buttonIcon: 'cil-chevron-right',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'primary',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const cancelButtonLight = {
  buttonType: 'button',
  buttonText: 'Cancel',
  buttonIcon: 'cil-backspace',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'light',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const confirmButton = {
  buttonType: 'button',
  buttonText: 'Confirm',
  buttonIcon: 'cil-check-alt',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'primary',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const successButton = {
  buttonType: 'button',
  buttonText: 'OK',
  buttonIcon: 'cil-check-alt',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'success',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const failButton = {
  buttonType: 'button',
  buttonText: 'OK',
  buttonIcon: 'cil-x-circle',
  buttonClass: 'mx-1 pr-3',
  buttonColor: 'danger',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const deleteButton = {
  buttonType: 'button',
  buttonText: 'Delete',
  buttonIcon: 'cil-x',
  buttonClass: 'pr-3 mx-1',
  buttonColor: 'danger',
  buttonShape: 'standard',
  buttonSize: 'sm',
};

export const trashButtonRed = {
  buttonType: 'button',
  buttonIcon: 'cil-trash',
  buttonClass: 'mx-1',
  buttonColor: 'danger',
  buttonShape: 'standard',
  buttonSize: 'sm',
};

export const addButton = (item: string) => {
  return {
    buttonType: 'button',
    buttonText: `Add ${item}`,
    buttonIcon: 'cil-plus',
    buttonClass: 'pr-3 mx-1',
    buttonColor: 'primary',
    buttonShape: 'standard',
    buttonSize: 'sm',
  };
};
export const submitSaveButton = {
  buttonType: 'submit',
  buttonText: 'Save Changes',
  buttonIcon: 'cil-check',
  buttonClass: 'mx-2 px-3 my-3',
  buttonColor: 'primary',
  buttonShape: 'standard',
  buttonSize: 'sm',
  buttonDisabled: true,
  buttonVariant: 'outline',
};
export const resetCancelButton = {
  buttonType: 'reset',
  buttonText: 'Start Over',
  buttonIcon: 'cil-ban',
  buttonClass: 'mx-2 px-3 my-3',
  buttonColor: 'danger',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
