import { Moment } from 'moment'

export interface FormattedDateTime {
  formattedString: string
  format: string | Array<string>
}

export interface TimeStamp {
  unix: number //unix timestamp
  moment?: Moment
  asString?: FormattedDateTime
  label?: FormattedDateTime
  chartLabel?: FormattedDateTime
}

export enum TimeUnit {
  years = 'years',
  weeks = 'weeks',
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds',
  milliseconds = 'milliseconds',
}
