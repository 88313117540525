export const linspace = (start: number, end: number, step: number) => {
  let returnVector = [start]
  let nextVal = start
  if (start < end && step > 0) {
    while (nextVal <= end) {
      nextVal = nextVal + step
      returnVector = [...returnVector, nextVal]
    }
  } else if (start > end && step < 0) {
    while (nextVal >= end) {
      nextVal = nextVal + step
      returnVector = [...returnVector, nextVal]
    }
  } else if (start > end && step > 0) {
    while (nextVal >= end) {
      nextVal = nextVal - step
      returnVector = [...returnVector, nextVal]
    }
  } else if (start === end) {
    returnVector = [start, end]
  } else {
    throw new Error('Arguments not valid for creating a linespace..')
  }
  return returnVector
}
