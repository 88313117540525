import { utcToZonedTime, format } from 'date-fns-tz'
import moment from 'moment'

//Date time format pattern
export const dateTimeFormatPattern = 'M-d-yy h:mm:ss aaa'

export const dateForCSVFileNamePattern = 'M/d/yy'

export const formatTimeZonedDateTime = (dateToFormat: string, timeZone: string) => {
  if (!dateToFormat || !timeZone || dateToFormat == 'None') {
    return ''
  }

  const zonedDate = utcToZonedTime(dateToFormat, timeZone)
  return format(zonedDate, dateTimeFormatPattern, { timeZone })
}

export const fileNameCSVJoinDateRange = (fileName: string, startDate: any, endDate: any) => {
  let startFormatted = moment(startDate).format('M-D-YY')
  let endFormatted = moment(endDate).format('M-D-YY')

  return fileName + '_' + startFormatted + ' - ' + endFormatted
}

export const getTimeZone = () => {
  return moment.tz.guess()
}
