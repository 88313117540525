import { ACTIONS, TARGET_TYPE, STATE_TARGET } from './StateTypes'
// import { getListItemFromState, deepCloneSubList, deepCloneSubListItem, setListItemState } from './StateList'
// export const subItemInList = (
//     searchSubListItem: any,
//     subList: any,
//     matchProps: any
// ) => {
//     let foundStation = subList.find(
//         (subListItem: any) =>
//             subListItem[matchProps.subList] === searchSubListItem[matchProps.subList]
//     );
//     if (foundStation) {
//         return true;
//     } else {
//         return false;
//     }
// };
// export const getSubListFromState = (listProps: any, componentState: any) => {
//     console.log(
//         "90.0-]]]] Sublist List props, compenent state in getSubListFromState is: ",
//         [listProps, componentState]
//     );
//     let returnListItem = getListItemFromState(listProps, componentState);
//     let returnSubList = returnListItem[listProps.subListFieldName];
//     console.log("Sublist items to return are: ", returnSubList);
//     return returnSubList;
// };
// export const setSubListState = (listProps: any, componentState: any) => {
//     let { subList } = listProps;
//     let targetList = getListItemFromState(listProps, componentState);
//     targetList[listProps.subListFieldName] = deepCloneSubList(subList);
//     let newListProps = {
//         listObj: targetList,
//         listFieldName: listProps.listFieldName,
//         subListFieldName: listProps.subListFieldName,
//         matchProps: listProps.matchProps
//     };

//     return setListItemState(newListProps, componentState);
// }

// export const setSubList = (action: any, componentState: any) => {
//     let subList = deepCloneSubList(action.payload[action.subListFieldName]);
//     let listProps = {
//         subList: subList,
//         listObj: action.payload.listObj,
//         listFieldName: action.listFieldName,
//         subListFieldName: action.subListFieldName,
//         matchProps: action.matchProps
//     };
//     return setSubListState(listProps, { ...componentState });
// };

// export const appendSubListItems = (action: any, componentState: any) => {
//     let listProps = {
//         listObj: action.payload.listObj,
//         subListObj: action.payload.subListObj,
//         listFieldName: action.listFieldName,
//         subListFieldName: action.subListFieldName,
//         matchProps: action.matchProps
//     };
//     console.log(
//         "6-]]]] List props, action, componentState in appendSubListItems is: ",
//         [listProps, action, componentState]
//     );
//     let subList = getSubListFromState(listProps, { ...componentState });
//     console.log("7-]]]] Retreived sublist is: ", subList);
//     let newSubList = [...subList];
//     console.log("10-]]]] The new sublist is: ", newSubList);
//     if (subList) {
//         newSubList = newSubList.concat(deepCloneSubListItem(listProps.subListObj));
//     } else {
//         newSubList = [deepCloneSubList(listProps.subListObj)];
//     }
//     let newListProps: any = { ...listProps };
//     newListProps.subList = [...newSubList];
//     console.log("8-]]]] New list props in appendSubListItems is: ", newListProps);
//     let newState = setSubListState(newListProps, { ...componentState });
//     console.log("9-]]] Sub-list state after appending is: ", newState);
//     return { ...newState };
// };

// export const removeSubListItems = (action: any, componentState: any) => {
//     let { listFieldName, subListFieldName, matchProps } = action;
//     let listProps = {
//         listObj: action.payload.listObj,
//         subListObj: action.payload.subListObj,
//         listFieldName: action.listFieldName,
//         subListFieldName: action.subListFieldName,
//         matchProps: action.matchProps
//     };
//     console.log(
//         "91.0-]]]] Action, componentState, listProps in removeSubListItems is: ",
//         [action, componentState, listProps]
//     );
//     let subList = getSubListFromState(listProps, { ...componentState });
//     console.log("91.1-]]]] Returned station list is: ", subList);
//     if (subItemInList(listProps.subListObj, subList, matchProps)) {
//         let newSubList = deepCloneSubList(
//             subList.filter(
//                 (subListItem: any) =>
//                     subListItem[matchProps.subList] !==
//                     listProps.subListObj[matchProps.subList]
//             )
//         );
//         let newListProps: any = { ...listProps };
//         newListProps.subList = newSubList;
//         let newState = setSubListState(newListProps, { ...componentState });
//         console.log("91.2-]]]] New state after update is: ", newState);
//         return { ...newState };
//     } else {
//         throw new Error("Sublist item not found and can't be removed.");
//     }
// };

// export const updateSubListItems = (action: any, componentState: any) => {
//     throw new Error(
//         "Not a valid state, sublist items can't be updated using this tool."
//     );
// };

export const subListStateReducer = (componentState: any, action: any) => {
  //console.log("Component state in use reducer is: ", componentState);
  //console.log('Action is componentStatereducer is: ', action)
  // let isSubListTarget = !(action.target === TARGET_TYPE.SUBLIST);
  // console.log("isSubListTarget is: ", isSubListTarget);
  switch (action.type) {
    case ACTIONS.SET:
    // return setSubList(action, componentState)
    case ACTIONS.APPEND:
    // console.log('Adding department using reducer: ', action.payload)
    // return appendSubListItems(action, componentState)
    case ACTIONS.REMOVE:
    // removeSubListItems(action, componentState)
    case ACTIONS.UPDATE:
    // return isSubListTarget
    // updateSubListItems(action, componentState)
    default:
      return { ...componentState }
  }
}
