import React from 'react';
import {CInput, CInputGroup, CInputGroupPrepend, CInputGroupText, CRow, CInputGroupAppend} from '@coreui/react';
import CIcon from '@coreui/icons-react';
const CEmptyGroupRow = (props: any) => {
  const {outerTagClass, endStrClass, inputGroupClass, prependClass, textClass, iconStart, textStr, startClass, startStr, iconEnd, endStr} = props;
  //console.log('Input group row props are: ', props);
  return (
    // <span className={outerTagClass}>
    <span style={{width: '100%'}}>
      <CInputGroup>
        {(startStr || iconStart) && (
          <CInputGroupPrepend className={prependClass}>
            {iconStart && (
              <CInputGroupText>
                <CIcon name={iconStart} />
              </CInputGroupText>
            )}
            {startStr && (
              <CInputGroupText>
                <span className={startClass}>{startStr}</span>
              </CInputGroupText>
            )}
          </CInputGroupPrepend>
        )}

        <CInputGroupText className={textClass}>{textStr}</CInputGroupText>

        {(endStr || iconEnd) && (
          <CInputGroupAppend>
            {endStr && <CInputGroupText className={endStrClass}>{endStr}</CInputGroupText>}
            {iconEnd && (
              <CInputGroupText>
                <CIcon name={iconEnd} />
              </CInputGroupText>
            )}
          </CInputGroupAppend>
        )}
      </CInputGroup>
    </span>
  );
};
export default CEmptyGroupRow;
