import React from 'react'
import { CAlert } from '@coreui/react'
import { TypedError } from '../../api/types/components-general.types'
import { v4 as uuidv4 } from 'uuid'
const idkey = uuidv4()
const CAlertErrors = (props: any) => {
  const { cardErrors } = props

  let finalErrors = cardErrors
    ? cardErrors.map((item: TypedError) => (item && item.content && item.content.body ? item.content.body : undefined))
    : []

  if (finalErrors.length === 0) {
    return <div></div>
  }

  return (
    <div className="mt-2">
      {finalErrors.map((errMessage: string) => {
        return (
          <CAlert key={idkey} color="danger">
            {errMessage}
          </CAlert>
        )
      })}
    </div>
  )
}
export default CAlertErrors
