import 'react-toastify/dist/ReactToastify.css'

export const defaultBr = 3
export const defaultRoundedBorder = { borderRadius: defaultBr }

export const styles = {
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
}

export const containerStyle = {
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
}
export const selectorStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 38,
    minWidth: 100,
    position: 'relative',
    boxSizing: 'border-box',
    msFlex: 1,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    //minWidth: 200,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    //width: 60,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
}
export const cardDropStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    boxSizing: 'border-box',
    msFlex: 1,
    cursor: 'pointer',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    paddingTop: 0,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    // width: 60,
    paddingTop: 0,
  }),

  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
}

export const toastStyle = {
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
}

// .Toastify__toast - body {
//     margin: auto 0;
//     flex: 1 1 auto;
// }
// *, *:: before, *::after {
//     box - sizing: border - box;
// }
// .Toastify__toast {
//     position: relative;
//     min - height: 64px;
//     box - sizing: border - box;
//     margin - bottom: 1rem;
//     padding: 8px;
//     border - radius: 1px;
//     box - shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
//     display: flex;
//     justify - content: space - between;
//     max - height: 800px;
//     overflow: hidden;
//     font - family: sans - serif;
//     cursor: pointer;
//     direction: ltr;
// }

// .Toastify__toast - container {
//     z - index: 9999;
//     -webkit - transform: translate3d(0, 0, 9999px);
//     position: fixed;
//     padding: 4px;
//     width: 320px;
//     box - sizing: border - box;
//     color: #fff;
// }

// .c - app {
//     overflow - x: hidden;
//     --primary: #025C71;
//     --secondary: #ced2d8;
//     --success: #2eb85c;
//     --info: #00A29B;
//     --warning: #f9b115;
//     --danger: #e55353;
//     --light: #ebedef;
//     --dark: #636f83;
//     color: #3c4b64;
//     background - color: #ebedef;
//     --color: #3c4b64;
// }
