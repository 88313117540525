import {CInputGroup, CRow} from '@coreui/react';
// @ts-ignore
import {TextMask, InputAdapter} from 'react-text-mask-hoc';
import React from 'react';
import {CInputGroupRowPrepend, CInputGroupRowAppend} from '../..';
import {v4 as uuidv4} from 'uuid';
const MaskedCInputGroupRow = (props: any) => {
  const {fieldName, iconStart, displayStr, placeholderStr, iconEnd, endStr, inputMask, style, borderStyle, handleChange, values, touched, errors, allowEdit} = props;
  return (
    <CRow key={uuidv4()} className="mb-3">
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <TextMask
          disabled={typeof allowEdit === 'boolean' ? !allowEdit : false}
          style={borderStyle}
          mask={inputMask}
          Component={InputAdapter}
          className="form-control"
          placeholder={placeholderStr}
          name={fieldName}
          onChange={handleChange}
          value={values[fieldName]}
          invalid={touched[fieldName] && errors[fieldName] ? true : false}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
    </CRow>
  );
};
export default MaskedCInputGroupRow;
