import * as React from 'react'

const PathSpotCircleCheckFillRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 495 495'} className={className || 'p-icon-5xl'}>
      <circle
        cx={247.5}
        cy={247.5}
        r={230}
        fill="#fff"
        stroke={strokeColor || '#27b34b'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={35}
      />
      <path
        fill="none"
        stroke={strokeColor || '#27b34b'}
        strokeMiterlimit={10}
        strokeWidth={48.74}
        d="M67.015 263.718l98.97 98.969 236-236"
      />
    </svg>
  )
}

const PathSpotCircleCheckFill = React.memo(PathSpotCircleCheckFillRaw)
export default PathSpotCircleCheckFill
