import { CInput, CInvalidFeedback, CInputGroup, CInputGroupText, CRow } from '@coreui/react'
import React from 'react'
import { CInputGroupRowPrepend, CInputGroupRowAppend } from '../..'
import { v4 as uuidv4 } from 'uuid'
const uuidVal = uuidv4()
const StyledCInput = (props: any) => {
  const { inputType, fieldName, textStyle, displayStr, placeholderStr, borderStyle, handleChange, componentId, values, touched, errors, setFieldValue, id, targetId, allowEdit } =
    props
  //console.log('Styled CInput props are: ', props)
  if (values[fieldName] === null) {
    //console.log('Null found as real null on: ', values[fieldName])
  }
  if (values[fieldName] === 'null') {
    //console.log('Null found as string null on: ', values[fieldName])
  }
  const keyVal = `${fieldName}-${uuidVal}`
  return (
    <span>
      <CInput
        key={keyVal}
        disabled={typeof allowEdit === 'boolean' ? !allowEdit : true}
        id={id}
        className={textStyle}
        style={borderStyle}
        type={inputType}
        name={fieldName}
        placeholder={placeholderStr}
        onChange={(e: any) => {
          setFieldValue(fieldName, e.target.value)
        }}
        onBlur={(e: any) => {
          setFieldValue(fieldName, e.target.value)
          handleChange(e.target.value, fieldName, targetId)
        }}
        value={values && values[fieldName] !== null && (values[fieldName] || values[fieldName] === '') ? values[fieldName] : displayStr}
        invalid={touched && touched[fieldName] && errors && errors[fieldName] ? true : false}
      />
      {errors && errors[fieldName] && <CInvalidFeedback>{errors[fieldName]}</CInvalidFeedback>}
    </span>
  )
}
export default StyledCInput
