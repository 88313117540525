import React from 'react'
import { v4 as uuidv4 } from 'uuid'
// import '../../styles/webappstyles.scss';
type BadgeProps = {
  className: string
  color: any
  shape: string
}
const elementId = uuidv4()
const PBadge = (props: any) => {
  let { tag, className, color, shape, ...attributes } = props
  // console.log('Props in PBadge are: ', props);

  const combinedClassName = className ? `${className} p-badge` : 'p-badge'

  return <div id={elementId} className="p-badge" {...attributes} style={{ color: color }} />

  // if (color) {
  //   // let colorRule: any = getCSSRule('p-badge');
  //   // console.log('CSS Rule is: ', colorRule);
  //   // if (colorRule !== null) {
  //   //   colorRule.style.backgroundColor = `${color}`;
  //   // }
  //   // const currentStyle = document.getElementById(elementId);
  //   // if (currentStyle !== null){

  //   //   const currentSheet = currentStyle.
  //   //   currentSheet.in   insertRule(`p-badge {background-color: $${color}; }`)
  //   // }
  //   // if (document.styleSheets) {
  //   //   const styleSheets = document.styleSheets;
  //   //   // console.log('Stylesheets are: ', styleSheets);
  //   //   const cssRuleIndex = Object.keys(styleSheets).findIndex((key: any) => {
  //   //     // console.log('Style sheet rules are: ', styleSheets[key].cssRules);
  //   //     let idx = 0;
  //   //     const foundRule = Object.values(styleSheets[key].cssRules).reduce((foundObject: any, entryValue: any) => {
  //   //       if (idx <= 3) {
  //   //         console.log('Entry value is: ', entryValue?.selectorText);
  //   //       }
  //   //       if (entryValue?.selectorText?.includes('p-badge')) {
  //   //         foundObject = true;
  //   //       }
  //   //       return foundObject;
  //   //     }, false);
  //   //     if (foundRule) {
  //   //       console.log('Found a match at key: ', key);
  //   //     }
  //   //     return foundRule;
  //   //   });
  //     // if (found)
  //     // console.log('Found rule list is: ', styleSheets[cssRuleIndex]);
  //   }
  // }
}

export default PBadge
