import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react'
import React from 'react'

export const CIconButton = (props: any) => {
  const { id, onClickAction, iconClass, buttonType, buttonText, buttonIcon, buttonClass, buttonColor, buttonStyle, buttonShape, buttonSize, buttonDisable, buttonHide } = props
  //console.log('Button props are: ', props)
  const onClickFunction: Function = onClickAction ? onClickAction : () => null
  return buttonHide === true ? (
    <div></div>
  ) : (
    <CButton
      id={id}
      disabled={buttonDisable || false}
      style={buttonStyle || { cursor: 'pointer' }}
      className={buttonClass || 'px-3 ml-1 mr-3 my-3'}
      type={buttonType || 'button'}
      color={buttonColor}
      size={buttonSize || 'sm'}
      shape={buttonShape || 'pill'}
      onClick={(val: any) => onClickFunction(val)}
    >
      {buttonIcon && <CIcon name={buttonIcon} className={iconClass || 'mx-2'} />}
      {buttonText}
    </CButton>
  )
}
export default CIconButton
