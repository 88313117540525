import React from 'react'
import { CInput, CInputGroup, CRow, CInvalidFeedback, CEmbedItem, CEmbed, CElementCover } from '@coreui/react'
import { CInputGroupRowPrepend, CInputGroupRowAppend, PAlertErrors } from '../..'
import DateTime from 'react-datetime'
const CTimeInputGroupRow = (props: any) => {
  const {
    rowClass,
    timeClass,
    timeFormat,
    onBlur,
    timeConstraints,
    dateFormat,
    dateClass,
    fieldName,
    iconStart,
    displayStr,
    placeholderStr,
    iconEnd,
    endStr,
    handleChange,
    values,
    required,
    disabled,
    inputStyle,
    inputClassName,
    errors,
    useDivLabel,
    useFormik,
  } = props
  //console.log('Errors for time input group row are ', errors)
  //console.log('FieldName errors for time input group row are ', errors && fieldName ? errors[fieldName] : undefined)

  const initialInputProps = {
    placeholder: placeholderStr,
    required: required,
    name: fieldName,
    disabled: disabled,
    className: inputClassName,
    // pattern: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
    // onBlur: () => onBlur()
    // style: {
    //   ...styles,
    //   ...inputStyle
    // }
  }
  const inputProps = onBlur ? { ...initialInputProps, onBlur: onBlur } : { ...initialInputProps }

  return useDivLabel ? (
    <React.Fragment>
      <div className="div-labeled-row">
        <div className="div-label-text">{displayStr}</div>
        <div className="div-flex-end">
          <DateTime
            timeFormat={timeFormat || dateFormat ? false : 'hh:mm A'}
            dateFormat={dateFormat || false}
            //@ts-ignore
            timeConstraints={timeConstraints || { minutes: { step: 15 } }}
            className={timeClass || dateClass}
            onChange={(e: any) => {
              //console.log('20.5-]]]] In CTIMEInputGroupRow, e is: ', e)
              if (useFormik) {
                handleChange(e, fieldName)
              } else {
                handleChange(e)
              }
            }}
            inputProps={inputProps}
            value={values[fieldName]}
          />
        </div>
      </div>
      {errors && errors[fieldName] && (
        <div>
          <PAlertErrors className="div-flex-end" errors={errors[fieldName]} />
        </div>
      )}
    </React.Fragment>
  ) : (
    <CRow className={rowClass || 'mb-3'}>
      <CInputGroup>
        <CInputGroupRowPrepend iconStart={iconStart} displayStr={displayStr} />
        <DateTime
          timeFormat={timeFormat || dateFormat ? false : 'hh:mm A'}
          dateFormat={dateFormat || false}
          //@ts-ignore
          timeConstraints={timeConstraints || { minutes: { step: 15 } }}
          className={timeClass || dateClass}
          onChange={(e: any) => {
            //console.log('20.5-]]]] In CTIMEInputGroupRow, e is: ', e)
            handleChange(e)
          }}
          inputProps={inputProps}
          value={values[fieldName]}
        />
        <CInputGroupRowAppend iconEnd={iconEnd} endStr={endStr} />
      </CInputGroup>
      <CRow className={rowClass}>
        <PAlertErrors errors={errors} fieldName={fieldName} />
      </CRow>
    </CRow>
  )
}
export default CTimeInputGroupRow
