import pick from 'lodash/pick'

export const objectIncludes = (object: any, key: any) => Object.keys(object).includes(key)

export const removeProperty = (key: string, { [key]: value, ...restObject }) => restObject

export const deepCloneProperty = (key: string, obj: any) => {
  return pick(obj, key)
}
export const mergeObjects = (source: any, target: any) => {
  return Object.assign(target, source)
}

export const deepObjectsMerge = (target: any, source: any) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      Object.assign(source[key], deepObjectsMerge(target[key], source[key]))
    }
  }
  // Join `target` and modified `source`
  Object.assign(target || {}, source)
  return target
}

//should have type of Omit<keys>
export const pickByKeys = (originalObject: any, keys: Array<any>) => {
  var newObj: any = {}
  for (var i = 0; i < keys.length; i++) {
    newObj[keys[i]] = originalObject[keys[i]]
  }
  return newObj
}

export const omitByKeys = (originalObject: any, keys: Array<any>) => {
  var newObj: any = {}
  var objKeys = Object.keys(originalObject)
  for (var i = 0; i < objKeys.length; i++) {
    !keys.includes(objKeys[i]) && (newObj[objKeys[i]] = originalObject[objKeys[i]])
  }
  return newObj
}

export const emptyField = (attr: any) => {
  switch (typeof attr) {
    case 'object':
      if (attr === null) return true
      if (attr.length === 0) return true
      if (Object.keys(attr).length === 0) return true
      return false
    case 'string':
      if (attr === '') return true
      return false
    case 'number':
      if (isNaN(attr)) return true
      return false
    case 'undefined':
      return true
    case 'boolean':
      return false
    default:
      return false
  }
}
export const allFieldsEmpty = (object: any) => {
  return Object.keys(object).every((key: any) => emptyField(object[key]))
}

export const mapValueFromKeyMatch = (targetObject: any, sourceObject: any) => {
  return Object.fromEntries(
    Object.entries(sourceObject).map(([key, value]: any) => {
      let targetkey = Object.keys(targetObject).find((newkey: any) => key.toLowerCase().search(newkey.toLowerCase()) >= 0)
      return targetkey && typeof targetkey === 'string' ? [[targetkey], value] : [[key], value]
    })
  )
}

export const replaceObjectFieldsKVP = (targetObject: any, keyValuePairs: any) => {
  return Object.fromEntries(
    Object.entries(targetObject).map(([key, value]: any) => {
      const keyIdx = keyValuePairs.keys.indexOf(key)
      return keyIdx >= 0 ? [key, keyValuePairs.values[keyIdx]] : [key, value]
    })
  )
}

export const replaceEmptyObjectFields = (sourceObject: any, replacementValue: any) => {
  return Object.fromEntries(
    Object.entries(sourceObject).map(([key, value]: any) => {
      const testObject = { [key]: value }
      const returnVal = emptyField(value) ? [key, replacementValue] : [key, value]
      return returnVal
    })
  )
}

export const statusMapping = (stat: string) => {
  switch (stat) {
    case 'PASS':
      return 'Success'
    case 'FAIL':
      return 'Fail'
    case 'QUIT':
    case 'TIMEOUT':
      return 'Incomplete'
    default:
      return 'Not Started'
  }
}

export const statusClassNameMapping = (item: any) => {
  switch (item.displayStatus) {
    case 'Success':
      return { color: 'rgb(24, 200, 53)' }
    case 'Fail':
      return { color: 'rgb(202, 44, 39)' }
    case 'Incomplete':
      return { color: 'rgb(237, 142, 14)' }
    default:
      return { color: 'rgb(167, 174, 174)' }
  }
}

export const objectHasField = (targetObject: any, fieldPathList: any): boolean => {
  if (targetObject) {
    if (fieldPathList && fieldPathList.length > 1) {
      const newTargetObject = targetObject[fieldPathList.shift()]
      return objectHasField(newTargetObject, fieldPathList)
    } else if (fieldPathList.length === 1) {
      let objectDefined = targetObject[fieldPathList.shift()]
      return objectDefined === undefined ? false : true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const getValueFromFieldPath = (targetObject: any, fieldPathList: any): any => {
  if (targetObject) {
    if (fieldPathList && fieldPathList.length > 1) {
      const newTargetObject = targetObject[fieldPathList.shift()]
      return getValueFromFieldPath(newTargetObject, fieldPathList)
    } else if (fieldPathList.length === 1) {
      let objectDefined = targetObject[fieldPathList.shift()]
      return objectDefined
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}
export const getObjectFromFieldPath = (targetObject: any, fieldPathList: any): any => {
  if (fieldPathList.length > 1) {
    const newTargetObject = targetObject[fieldPathList.shift()]
    return getObjectFromFieldPath(newTargetObject, fieldPathList)
  } else if (fieldPathList.length === 1) {
    return {
      returnObject: targetObject,
      field: fieldPathList.shift(),
    }
  } else {
    return {
      returnObject: targetObject,
      field: undefined,
    }
  }
}

export const mergeRefs = (...refs: any) => {
  return (node: any) => {
    for (const ref of refs) {
      ref.current = node
    }
  }
}
export const parseParamString = (paramString: string) => {
  switch (paramString) {
    case 'false':
      return false
    case 'true':
      return true
    case 'null':
      return null
    default:
      return parseInt(paramString)
  }
}
export const parseParam = (param: any, searchString: any) => {
  const paramSplit = searchString.split(param)
  if (paramSplit && paramSplit.length > 1) {
    const terminatedValue = paramSplit[1].split('=')
    if (terminatedValue && terminatedValue.length > 1) {
      const paramValue = terminatedValue[1].split('&')
      if (paramValue && paramValue.length > 0) {
        return paramValue[0]
      }
    }
  }
  return undefined
}

//= Object.keys(errors).some((key: string) => errors[key] && errors[key].length > ˆ∆)
// Object.keys(eventDetails).forEach((key: string) => errors[key] = []

// export const cloneProperty = (key: string, { [key]: value, ...restObject }) => { return { [key]: value } };

// const clonePropertyA = (targetKey: string, restObject: any) => {
//     let newObject = { ...restObject }
//     return newObject[targetKey]
// }

// return Object.fromEntries(Object.entries(restObject).map(([key, value]: any) => {
//     if (key === targetKey) {
//         return [key, value]
//     }
// }
// )
// )

// const win = window.open(`#/sensors/${item.sensorId}`, '_blank')
// if (win !== null) win.focus()
// let returnVal: any = ''
// // TODO (nathan): should refactor this to make sure it cant effect anything else
// if (key == 'status') {
//   returnVal = [key, statusMapping(value)]
// } else if (key == 'submissionDateTime' && value && (!sourceObject['status'] || sourceObject['status'] == 'Not Started')) {
//   returnVal = ['submissionDateTime', replacementValue]
// } else if (key == 'firstScan' || key == 'lastScan') {
//   returnVal = !sourceObject['employeeId'] ? [key, 'No data - Anonymous mode'] : [key, value ? value : replacementValue]
// } else {
