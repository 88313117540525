import React from 'react'
import { useFela } from 'react-fela'
import { container, debugBorder } from '../../styles/containers'
import { fieldlabel } from '../../styles/text'
import { v4 as uuidv4 } from 'uuid'
import PContainer from '../containers/PContainer'
import { grayInput, grayInputBackground, pathspotGreen, pathspotPrimary } from '../../../styles/ts/colors'
const componentUUID = uuidv4()

export const pInputPrependBlock = (args: any) => {
  const { width, margin } = args

  return {
    display: 'flex',
    whiteSpace: 'no-wrap',
    verticalAlign: 'middle',
    marginRight: '-1px',
    '::before, ::after': {
      boxSizing: 'border-box',
    },
    width,
    ...margin,
  }
}

export const pInputPrependText = (args: any) => {
  const { borderColor, color, fontSize, fontStyle, fontWeight, textAlign, margin, backgroundColor } = args
  return {
    display: 'flex',
    alignItems: 'center',
    padding: '0.375rem 0.75rem',
    marginBottom: 0,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    border: '1px solid',
    borderRadius: '0.25rem',
    color: color || '#5c6873',
    backgroundColor: backgroundColor || '#f0f3f5',
    borderColor: borderColor || '#e4e7ea',
    '::before, ::after': {
      boxSizing: 'border-box',
    },
  }
}

const borderCss = (args: any) => {
  const {
    borderRadius,
    borderRadiusLeft,
    borderTop,
    borderRadiusRight,
    borderBottom,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
  } = args
  return {
    borderRadius: `${borderRadius ? borderRadius : '0'} !important`,
    borderBottomRightRadius: borderRadiusRight ? (borderBottomRightRadius ? borderBottomRightRadius : borderRadius) : '0.25rem',
    borderTopRightRadius: borderRadiusRight ? (borderTopRightRadius ? borderTopRightRadius : borderRadius) : '0.25rem',
    borderBottomLeftRadius: borderRadiusLeft ? (borderBottomLeftRadius ? borderBottomLeftRadius : borderRadius) : '0.25rem',
    borderTopLeftRadius: borderRadiusLeft ? (borderTopLeftRadius ? borderTopLeftRadius : borderRadius) : '0.25rem',
    borderColor: pathspotGreen,
    borderWidth: '2px',
    borderStyle: 'solid',
  }
}
const PInputPrepend = (props: any) => {
  const {
    fontSize,
    fontStyle,
    fontWeight,
    label,
    alignItems,
    justifyContent,
    margin: _margin,
    width,
    height,
    borderRadiusLeft,
    borderRadiusRight,
    borderRadius: _borderRadius,
    borderWidth,
    borderColor,
    borderStyle,
  } = props
  const margin = _margin
    ? _margin
    : {
        marginRight: '-1px',
      }
  const borderRadius = _borderRadius || {
    borderTopRightRadius: borderRadiusRight || 0,
    borderBottomRightRadius: borderRadiusRight || 0,
    borderTopLeftRadius: borderRadiusLeft || '0.25rem',
    borderBottomLeftRadius: borderRadiusLeft || '0.25rem',
  }
  const border = {
    borderWidth: borderWidth || '1px',
    borderColor: borderColor || pathspotPrimary,
    borderStyle: borderStyle || 'solid',
    ...borderRadius,
  }
  const { css } = useFela()
  const key = `${label}-${componentUUID}`
  return (
    <div key={key} className={css(pInputPrependBlock({ margin, width }))}>
      <span className={css(pInputPrependText({ fontSize, fontStyle, fontWeight }), borderRadius, margin)}>{`${label}`}</span>
    </div>
  )
}

export default PInputPrepend

// return {
//   display: 'flex',
//   // height: height || '2.35rem',
//   flexDirection: 'row',
//   flexWrap: 'noWrap',
//   color: color || grayInput,
//   backgroundColor: backgroundColor || grayInputBackground,
//   textAlign: textAlign || 'center',
//   fontSize: fontSize || '0.9rem',
//   fontStyle: fontStyle || 'normal',
//   fontWeight: fontWeight || '500',
//   justifyContent: 'center',
//   alignItems: 'center',
//   width: '100%',
//   boxSizing: 'border-box',
//   whiteSpace: 'no-wrap',
//   verticalAlign: 'middle',
//   // borderBottomRightRadius: borderRadius || '0rem',
//   // borderTopRightRadius: borderRadius || '0rem',
//   // borderBottomLeftRadius: borderRadius || '0.25rem',
//   // borderTopLeftRadius: borderRadius || '0.25rem',
//   ...margin,
// }

//  <PContainer
//     key={key}
//     isRow
//     showComment
//     comment="PInputPrepend Container"
//     alignItems={alignItems}
//     justifyContent={justifyContent}
//     margin={{ ...margin }}
//     width={width || '20%'}
//     className={css(pInputPrependBlock({margin}))}
//     // border={{ ...border }}
//     // height={'100%'}
//   >
//     <span className={css(pInputPrependText({ fontSize, fontStyle, fontWeight }), borderRadius, margin)}>{`${label}`}</span>
//   </PContainer>
