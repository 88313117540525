import React from 'react';
import {CCol, CRow, CAlert} from '@coreui/react';
import {v4 as uuidv4} from 'uuid';

const componentKey = uuidv4();
const FNotifyRow = (props: any) => {
  const {notifications, rowClass, alertDivClass, alertColor, fieldName} = props;
  if (!notifications) {
    return <div></div>;
  }

  let displayList: any[] = notifications.length === 1 ? [...notifications] : [notifications];
  //Check if error is a string or TypedError
  return (
    <div>
      <div className={alertDivClass || 'mt-2'}>
        {displayList.map((error: any) => (
          <CAlert color={alertColor || 'primary'}>{notifications[fieldName]}</CAlert>
        ))}
      </div>
    </div>
  );
};
export default FNotifyRow;
