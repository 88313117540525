import * as React from 'react'

const PathSpotCircleXFillRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  return (
    <svg viewBox={viewBox || '0 0 495 495'} className={className || 'p-icon-5xl'}>
      <circle
        cx={247.5}
        cy={247.5}
        r={230}
        fill="#fff"
        stroke={strokeColor || '#e64c3c'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={35}
      />
      <path
        fill={strokeColor || '#e64c3c'}
        d="M247.5 271.211L132.068 386.644l-26.053-26.051 115.433-115.434-115.433-115.434 26.053-26.05L247.5 219.108l115.433-115.433 26.052 26.05-115.433 115.434 115.433 115.434-26.052 26.051L247.5 271.211z"
      />
      <path
        d="M362.933 112.16l17.567 17.566L265.066 245.16 380.5 360.594l-17.567 17.566L247.5 262.727 132.067 378.16 114.5 360.594 229.934 245.16 114.5 129.726l17.567-17.566L247.5 227.593 362.933 112.16m0-16.97l-8.485 8.485-106.947 106.947-106.947-106.947-8.485-8.485-8.485 8.484-17.567 17.566-8.486 8.485 8.486 8.486 106.948 106.948-106.948 106.948-8.486 8.486 8.486 8.485 17.567 17.566 8.485 8.484 8.485-8.485 106.947-106.947 106.947 106.947 8.485 8.485 8.485-8.484 17.567-17.566 8.486-8.485-8.486-8.486-106.948-106.948 106.948-106.948 8.486-8.486-8.486-8.485-17.567-17.566-8.485-8.484z"
        fill={strokeColor || '#e64c3c'}
      />
    </svg>
  )
}

const PathSpotCircleXFill = React.memo(PathSpotCircleXFillRaw)
export default PathSpotCircleXFill
