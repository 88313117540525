import * as React from 'react'
import {
  pathspotPrimary,
  bananaYellow,
  pathspotGray,
  pathspotRed,
  numericOrange,
  pathspotWhite,
  pathspotSecondary,
  pathspotWhite50p,
} from '../../../../styles/ts/colors'

const PathSpotThermometerRaw = (props: any) => {
  const { fillColor, strokeWidth, strokeColor, textColor, className, viewBox, width, height, batteryPercent } = props

  return (
    <svg viewBox={viewBox || '0 0 198.08 508'} className={className || 'p-icon-auto'}>
      <path
        d="M140.42 338.21C165.45 352.89 181 380 181 408.98c-.01 45.18-36.77 81.93-81.96 81.93s-81.96-36.75-81.96-81.94c0-28.98 15.56-56.09 40.58-70.76.11-.06.18-.14.28-.2.07-.05.12-.1.18-.14.3-.2.57-.43.82-.67.03-.03.06-.07.09-.1.27-.27.51-.56.71-.86.04-.04.05-.1.08-.13.2-.31.38-.62.52-.96.03-.08.06-.15.09-.23.12-.32.22-.63.29-.96.03-.11.04-.21.06-.32.05-.33.09-.64.1-.97 0-.04.01-.09.01-.13V316h9.63c3.62 0 6.55-2.93 6.55-6.55s-2.93-6.54-6.55-6.54h-9.63V269.8h9.63a6.54 6.54 0 006.55-6.55c0-3.61-2.93-6.55-6.55-6.55h-9.63v-33.1h9.63c3.62 0 6.55-2.93 6.55-6.55s-2.93-6.54-6.55-6.54h-9.63v-33.1h9.63c3.62 0 6.55-2.93 6.55-6.55s-2.93-6.55-6.55-6.55h-9.63v-33.1h9.63c3.62 0 6.55-2.93 6.55-6.54s-2.93-6.56-6.55-6.56h-9.63V85.02h9.63c3.62 0 6.55-2.94 6.55-6.55s-2.93-6.55-6.55-6.55h-9.63V53.63c0-23.98 19.85-36.54 39.46-36.54 9.92 0 19.01 3.19 25.59 8.95 7.36 6.46 11.25 16 11.25 27.59v41.39h-10.55c-3.62 0-6.55 2.93-6.55 6.54s2.93 6.55 6.55 6.55h10.55v33.1h-10.55c-3.62 0-6.55 2.93-6.55 6.55s2.93 6.55 6.55 6.55h10.55v33.1h-10.55a6.54 6.54 0 00-6.55 6.55c0 3.61 2.93 6.54 6.55 6.54h10.55v33.11h-10.55c-3.62 0-6.55 2.93-6.55 6.54s2.93 6.55 6.55 6.55h10.55v33.11h-10.55c-3.62 0-6.55 2.92-6.55 6.54s2.93 6.55 6.55 6.55h10.55v39.64c0 .04.01.08.01.11.01.35.05.72.11 1.07.02.08.02.14.04.21.08.37.19.73.33 1.07.02.05.03.09.05.13.16.36.34.71.57 1.05.01.01.01.02.02.03.23.33.48.64.78.94.01.01.01.03.03.04.26.25.56.49.87.71.05.04.09.07.13.1.1.08.18.15.29.21zm2.82 70.74c-.01-22.13-16.39-40.46-37.66-43.65V132.57c0-3.62-2.92-6.55-6.54-6.55s-6.54 2.93-6.54 6.55V365.3c-21.27 3.17-37.66 21.52-37.66 43.65 0 24.38 19.83 44.21 44.19 44.21s44.21-19.83 44.21-44.21z"
        fill={fillColor || pathspotWhite50p}
      />
      <path
        d="M150.28 328.98c27.09 17.39 43.79 47.67 43.8 79.99 0 52.4-42.63 95.03-95.04 95.03S4 461.37 4 408.97c0-32.32 16.71-62.61 43.8-80V53.63C47.8 21.39 74.87 4 100.35 4c13.1 0 25.26 4.33 34.22 12.2 10.28 9.02 15.71 21.96 15.71 37.43v275.35zm30.72 80c0-28.98-15.55-56.09-40.58-70.77-.11-.06-.19-.13-.29-.21-.04-.03-.08-.06-.13-.1-.31-.22-.61-.46-.87-.71-.02-.01-.02-.03-.03-.04-.3-.3-.55-.61-.78-.94-.01-.01-.01-.02-.02-.03-.23-.34-.41-.69-.57-1.05-.02-.04-.03-.08-.05-.13-.14-.34-.25-.7-.33-1.07-.02-.07-.02-.13-.04-.21-.06-.35-.1-.72-.11-1.07 0-.03-.01-.07-.01-.11V292.9h-10.55c-3.62 0-6.55-2.93-6.55-6.55s2.93-6.54 6.55-6.54h10.55V246.7h-10.55c-3.62 0-6.55-2.93-6.55-6.55s2.93-6.54 6.55-6.54h10.55V200.5h-10.55c-3.62 0-6.55-2.93-6.55-6.54a6.54 6.54 0 016.55-6.55h10.55v-33.1h-10.55c-3.62 0-6.55-2.94-6.55-6.55s2.93-6.55 6.55-6.55h10.55v-33.1h-10.55c-3.62 0-6.55-2.93-6.55-6.55s2.93-6.54 6.55-6.54h10.55V53.63c0-11.59-3.89-21.13-11.25-27.59-6.58-5.76-15.67-8.95-25.59-8.95-19.61 0-39.46 12.56-39.46 36.54v18.29h9.63c3.62 0 6.55 2.93 6.55 6.55s-2.93 6.55-6.55 6.55h-9.63v33.09h9.63c3.62 0 6.55 2.94 6.55 6.56s-2.93 6.54-6.55 6.54h-9.63v33.1h9.63c3.62 0 6.55 2.93 6.55 6.55s-2.93 6.55-6.55 6.55h-9.63v33.1h9.63c3.62 0 6.55 2.93 6.55 6.54s-2.93 6.55-6.55 6.55h-9.63v33.1h9.63c3.62 0 6.55 2.94 6.55 6.55a6.54 6.54 0 01-6.55 6.55h-9.63v33.11h9.63c3.62 0 6.55 2.92 6.55 6.54S74.14 316 70.52 316h-9.63v16.54c0 .04-.01.09-.01.13-.01.33-.05.64-.1.97-.02.11-.03.21-.06.32-.07.33-.17.64-.29.96-.03.08-.06.15-.09.23-.14.34-.32.65-.52.96-.03.03-.04.09-.08.13-.2.3-.44.59-.71.86-.03.03-.06.07-.09.1-.25.24-.52.47-.82.67-.06.04-.11.09-.18.14-.1.06-.17.14-.28.2-25.02 14.67-40.58 41.78-40.58 70.76 0 45.19 36.77 81.94 81.96 81.94s81.95-36.75 81.96-81.93z"
        fill={fillColor || pathspotPrimary}
      />
      <path
        d="M105.58 365.3c21.27 3.19 37.65 21.52 37.66 43.65 0 24.38-19.84 44.21-44.21 44.21s-44.19-19.83-44.19-44.21c0-22.13 16.39-40.48 37.66-43.65V132.57c0-3.62 2.93-6.55 6.54-6.55s6.54 2.93 6.54 6.55V365.3z"
        fill={fillColor || pathspotPrimary}
      />
      <g fill="none" stroke={strokeColor || pathspotPrimary} strokeMiterlimit={10} strokeWidth={strokeWidth || 8}>
        <path d="M105.58 365.3V132.57c0-3.62-2.92-6.55-6.54-6.55s-6.54 2.93-6.54 6.55V365.3c-21.27 3.17-37.66 21.52-37.66 43.65 0 24.38 19.83 44.21 44.19 44.21s44.21-19.83 44.21-44.21c-.01-22.13-16.39-40.46-37.66-43.65z" />
        <path d="M150.28 328.98V53.63c0-15.47-5.43-28.41-15.71-37.43C125.61 8.33 113.45 4 100.35 4 74.87 4 47.8 21.39 47.8 53.63v275.34C20.71 346.36 4 376.65 4 408.97 4 461.37 46.63 504 99.04 504s95.04-42.63 95.04-95.03c-.01-32.32-16.71-62.6-43.8-79.99z" />
        <path d="M99.04 490.91c-45.19 0-81.96-36.75-81.96-81.94 0-28.98 15.56-56.09 40.58-70.76.11-.06.18-.14.28-.2.07-.05.12-.1.18-.14.3-.2.57-.43.82-.67.03-.03.06-.07.09-.1.27-.27.51-.56.71-.86.04-.04.05-.1.08-.13.2-.31.38-.62.52-.96.03-.08.06-.15.09-.23.12-.32.22-.63.29-.96.03-.11.04-.21.06-.32.05-.33.09-.64.1-.97 0-.04.01-.09.01-.13V316h9.63c3.62 0 6.55-2.93 6.55-6.55s-2.93-6.54-6.55-6.54h-9.63V269.8h9.63a6.54 6.54 0 006.55-6.55c0-3.61-2.93-6.55-6.55-6.55h-9.63v-33.1h9.63c3.62 0 6.55-2.93 6.55-6.55s-2.93-6.54-6.55-6.54h-9.63v-33.1h9.63c3.62 0 6.55-2.93 6.55-6.55s-2.93-6.55-6.55-6.55h-9.63v-33.1h9.63c3.62 0 6.55-2.93 6.55-6.54s-2.93-6.56-6.55-6.56h-9.63V85.02h9.63c3.62 0 6.55-2.94 6.55-6.55s-2.93-6.55-6.55-6.55h-9.63V53.63c0-23.98 19.85-36.54 39.46-36.54 9.92 0 19.01 3.19 25.59 8.95 7.36 6.46 11.25 16 11.25 27.59v41.39h-10.55c-3.62 0-6.55 2.93-6.55 6.54s2.93 6.55 6.55 6.55h10.55v33.1h-10.55c-3.62 0-6.55 2.93-6.55 6.55s2.93 6.55 6.55 6.55h10.55v33.1h-10.55a6.54 6.54 0 00-6.55 6.55c0 3.61 2.93 6.54 6.55 6.54h10.55v33.11h-10.55c-3.62 0-6.55 2.93-6.55 6.54s2.93 6.55 6.55 6.55h10.55v33.11h-10.55c-3.62 0-6.55 2.92-6.55 6.54s2.93 6.55 6.55 6.55h10.55v39.64c0 .04.01.08.01.11.01.35.05.72.11 1.07.02.08.02.14.04.21.08.37.19.73.33 1.07.02.05.03.09.05.13.16.36.34.71.57 1.05.01.01.01.02.02.03.23.33.48.64.78.94.01.01.01.03.03.04.26.25.56.49.87.71.05.04.09.07.13.1.1.08.18.15.29.21C165.45 352.89 181 380 181 408.98c-.01 45.18-36.77 81.93-81.96 81.93z" />
      </g>
    </svg>
  )
}

const PathSpotThermometer = React.memo(PathSpotThermometerRaw)
export default PathSpotThermometer
