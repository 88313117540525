import React, { useState } from 'react'
import { CCol, CRow, CButtonToolbar, CLabel } from '@coreui/react'
import { ButtonProps, ButtonRow, LabelButtonRow } from '../buttons/types'
import PIconButton from '../buttons/PIconButton'

export const PButtonRow: React.FC<ButtonRow> = ({ ...props }) => {
  const { buttonProps, rowClass, colClass, toolbarClass } = props
  // const {
  //   iconClass,
  //   buttonType,
  //   buttonText,
  //   buttonIcon,
  //   buttonClass,
  //   buttonColor,
  //   buttonStyle,
  //   buttonShape,
  //   buttonSize,
  //   buttonDisable,
  //   buttonVariant,
  //   divClassName,
  //   buttonTextClass,
  //   inline,
  // } = { ...props }
  if (buttonProps && Array.isArray(buttonProps)) {
    return (
      <div className={rowClass || 'mb-3'}>
        <div className={colClass || 'p-inline'}>
          {buttonProps.map((buttonAttrs: ButtonProps) => {
            return (
              <div className={toolbarClass || 'mr-3 ml-3 justify-content-center'}>
                <PIconButton {...buttonAttrs} />
              </div>
            )
          })}
        </div>
      </div>
    )
  } else {
    return (
      <CRow className={rowClass || 'mb-3'}>
        <CCol className={colClass || 'justify-content-center'}>
          <CButtonToolbar className={toolbarClass || 'justify-content-center'}>
            <PIconButton {...buttonProps} />
          </CButtonToolbar>
        </CCol>
      </CRow>
    )
  }
}
export default PButtonRow
