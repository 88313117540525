import React, { useMemo, useState } from 'react'
import classNames from 'classnames'

const toCamelCase = (str: string) => {
  return str
    .replace(/([-_][a-z0-9])/gi, ($1) => {
      return $1.toUpperCase()
    })
    .replace(/-/gi, '')
}

//component - CoreUI / CIcon
const PFontIcon = (props: any) => {
  const { className, name, content, customClasses, size, src, title, use, ...attributes } = props

  const [change, setChange] = useState(0)

  useMemo(() => setChange(change + 1), [name, JSON.stringify(content)])

  const iconName = useMemo(() => {
    const iconNameIsKebabCase = name && name.includes('-')
    return iconNameIsKebabCase ? toCamelCase(name) : name
  }, [change])

  const titleCode = title ? `<title>${title}</title>` : ''

  const code = useMemo(() => {
    if (content) {
      return content
    } else if (name && React.icons) {
      return React.icons[iconName]
        ? React.icons[iconName]
        : console.log(`Icon with name '${iconName}' does not exist in React.icons object. `)
    }
  }, [change])

  const iconCode = useMemo(() => {
    return Array.isArray(code) ? code[1] || code[0] : code
  }, [change])

  const scale = (() => {
    return Array.isArray(code) && code.length > 1 ? code[0] : '64 64'
  })()

  const viewBox = (() => {
    return attributes.viewBox || `0 0 ${scale}`
  })()

  return (
    <React.Fragment>
      {!src && !use && (
        <svg
          {...attributes}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          className={className}
          role="img"
          dangerouslySetInnerHTML={{ __html: titleCode + iconCode }}
        />
      )}
      {src && !use && <img {...attributes} className={className} src={src} role="img" />}
      {!src && use && (
        <svg {...attributes} xmlns="http://www.w3.org/2000/svg" className={className} role="img">
          <use href={use}></use>
        </svg>
      )}
    </React.Fragment>
  )
}

export default PFontIcon
