import React from 'react'
import { useFela } from 'react-fela'
import { badge } from '../../styles/text'

const PBadge = (props: any) => {
  const { text, color, backgroundColor, margin, padding } = props
  const { css } = useFela()
  return <span className={css(badge({ color, backgroundColor, margin, padding }))}>{text}</span>
}
export default PBadge
