import React, { useLayoutEffect, useState } from 'react'
import _ from 'lodash'
import { useFela } from 'react-fela'
import PItemText from '../text/PItemText'
import { pathspotGrayLight, pathspotPrimary, pathspotWhite } from '../../../styles/ts/colors'
import PContainer from '../containers/PContainer'
import { v4 as uuidv4 } from 'uuid'

const componentUUID = uuidv4()

const checkMarginValue = 3
const checkMargin = `${checkMarginValue}px`
const checkMarginHalf = `${checkMarginValue / 2}px`

const checkdot = (args: any) => {
  const { width, height, color, cbWidth, isText, dotColor } = args
  return {
    display: 'inline-flex !important',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: cbWidth || '1rem',
    height: cbWidth || 'auto',
    cursor: 'pointer',
    borderColor: dotColor || pathspotPrimary,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    marginLeft: isText ? '1rem' : '0',
  }
}
export const checkFill = (args: any) => {
  const { isChecked } = args
  // console.log('^^^^^^^^^^is checked is: ', isChecked)
  return {
    display: 'inline-flex !important',
    backgroundColor: isChecked ? pathspotPrimary : pathspotWhite,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    width: `calc(100% - ${isChecked ? checkMargin : 0})`,
    height: `calc(100% - ${isChecked ? checkMargin : 0})`,
    '-webkit-transition': isChecked ? '0.4s' : 0,
    transition: isChecked ? '0.4s' : 0,
    borderColor: pathspotPrimary,
    borderWidth: isChecked ? checkMargin : 0,
    borderStyle: 'solid',
    borderRadius: '50%',
    transform: `translateX(${checkMarginHalf})`,
  }
}

const PRadioButton = (props: any) => {
  const {
    callback,
    label,
    item,
    value,
    alignItems,
    justifyContent,
    width,
    fontSize: _fontSize,
    color,
    fontWeight,
    margin,
    padding,
    border,
    textAlign,
    dotColor: _dotColor,
    textColor: _textColor,
    disabled,
  } = props

  const checkdotText = label
  const key = `${checkdotText}-${componentUUID}`
  const fontSize = _fontSize === undefined ? '0.9rem' : _fontSize
  const dotColor = disabled ? pathspotGrayLight : _dotColor
  const textColor = disabled ? pathspotGrayLight : _textColor

  const { css } = useFela()
  const [checkedState, setCheckedState] = useState<boolean | null>(null)

  useLayoutEffect(() => {
    setCheckedState(value)
  }, [value])

  const updatedCheckedState = () => {
    if (checkedState !== null) {
      if (callback) {
        callback(!checkedState, item)
      }
      setCheckedState(!checkedState)
    }
  }
  if (checkedState === null) {
    return <></>
  }
  return (
    <PContainer isRow width={width || '100%'} justifyContent={checkdotText ? 'flex-start' : 'center'}>
      <div
        key={`${key}-div`}
        className={css(checkdot({ cbWidth: `${parseFloat(fontSize) * 1.1}rem`, isText: checkdotText, dotColor }))}
        onClick={() => !disabled && updatedCheckedState()}
      >
        <span key={`${key}-span`} className={css(checkFill({ isChecked: checkedState }))} />
      </div>
      {checkdotText && checkdotText !== '' && (
        <PItemText
          isRow
          margin={{ marginLeft: '0.25rem' }}
          displayText={checkdotText}
          textAlign="left"
          {...{
            alignItems: alignItems || 'center',
            justifyContent: justifyContent || 'flex-start',
            width: '75%',
            fontSize,
            color: textColor || pathspotPrimary,
            fontWeight,
            padding,
            border,
          }}
        />
      )}
    </PContainer>
  )
}
export default PRadioButton
