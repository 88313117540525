import React from 'react'
import { useFela } from 'react-fela'
import { pathspotPrimary, toastifyError, toastifySuccess, toastifyWarning } from '../../../styles/ts/colors'
import { alignItems } from '../../styles/css-properties'
import { Atomic } from '../../..'

const tableContainer = (args: any) => {
  return {
    display: 'inline-flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    borderColor: toastifyError,
    borderStyle: 'solid',
    borderWidth: '0px',
  }
}
const PTable = (props: any) => {
  const { items, tableColumns } = props
  const { css } = useFela()
  return (
    <Atomic.PContainer>
      <div className={css(tableContainer)}>{props.children}</div>
    </Atomic.PContainer>
  )
}

export default PTable
