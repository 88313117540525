import React from "react";
import {
  CModalGroup,
} from "../..";
import CIconButtonRow from "../rows/CIconButtonRow";
//@ts-ignore
import cloneDeep from "lodash/cloneDeep";

import { successButton } from "../buttons/button-styles";

const SuccessModal = (props: any) => {
  const { modalProps, modalState } = props;
  //console.log("65.0-]]]] In success modal, modal props are: ", cloneDeep(modalProps))
  let newModalProps = { ...modalProps };
  newModalProps.headerColor = "success";

  let buttonProps: any = { ...successButton };
  buttonProps.onClickAction = modalProps.onConfirm;
  const buttonRowProps = { buttonProps }
  newModalProps.content.footer = (
    <CIconButtonRow buttonRowProps={buttonRowProps} />
  );
  return (
    <span>
      <CModalGroup modal={newModalProps} />;
    </span>
  );
};
export default SuccessModal;
