import React from 'react'

const PathSpotSupport = (props: any) => {
  const { preText, postText, className } = props
  return (
    <p className={className}>
      {preText || ``} &nbsp; <a href={'mailto:support@pathspottech.com'}>{`support@pathspottech.com`}</a>
      &nbsp; {` or call/text `} &nbsp;
      <a href={'tel:718-550-0040'}>{`718-550-0040`}</a> &nbsp; {postText || ``}
    </p>
  )
}
export default PathSpotSupport
