import React from 'react'
import { useFela } from 'react-fela'
import { wrapper } from '../../styles/containers'

const PWrapper = (props: any) => {
  const { asCard, height, width, flexDirection, flexWrap, margin, padding, border, justifyContent, alignItems } = props
  const { css } = useFela()

  return (
    <div className={css(wrapper({ asCard, height, width, flexDirection, flexWrap, margin, padding, border, justifyContent, alignItems }))}>
      {props.children}
    </div>
  )
}
export default PWrapper
