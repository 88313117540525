export const isDefined = (arg: any): boolean => typeof arg !== 'undefined'
export const hasValuePayloadArg = (arg: any) => typeof arg !== 'undefined' && arg !== null
export const isNullOrUndefined = <T>(arg: T | null | undefined): arg is null | undefined => {
  return arg === null || arg === undefined
}
type EmptyString = '' | ''
export const isNullUndefinedOrEmptyString = <T>(arg: T | null | undefined | string): arg is null | undefined | EmptyString => {
  return arg === null || arg === undefined || arg === ''
}
export const isNullUndefinedOrZero = <T>(arg: T | null | undefined | number): arg is null | undefined | 0 => {
  return arg === null || arg === undefined || arg === 0
}
export const isNumber = <T>(arg: T | number): arg is number => {
  return typeof arg === typeof 1
}
