import * as React from 'react'
import { pathspotPrimary } from '../../../../styles/ts/colors'

const PathSpotDecrementRaw = (props: any) => {
  const { fillColor, strokeColor, checked, className, viewBox, width, height } = props
  // console.log('Props in pathspot decrement are: ', props)
  return (
    <svg viewBox={viewBox || '0 0 341.356 512.053'} className={className || 'p-icon-5xl'}>
      <path
        d="M340.83 25.222V486.83a24.694 24.694 0 01-40.044 19.346L9.873 275.373a24.696 24.696 0 010-38.692L300.786 5.876a24.69 24.69 0 0126.092-2.89 24.693 24.693 0 0113.952 22.236zm-49.391 410.492V76.339L64.955 256.027l226.484 179.687z"
        fill={fillColor || pathspotPrimary}
        stroke={strokeColor || pathspotPrimary}
        strokeMiterlimit={10}
        strokeWidth={1.053}
      />
    </svg>
  )
}

const PathSpotDecrement = React.memo(PathSpotDecrementRaw)
export default PathSpotDecrement
