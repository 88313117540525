import React from 'react'
import { CLabel, CInputGroupText, CRow } from '@coreui/react'
import { ArgPlacement } from '../../api/types/enums.types'
import StyledLabel from './StyledLabel'

const CTextArgRow = (props: any) => {
  const { textStyle, borderStyle, labelStr, textClass, textArg, textArgPlacement } = props
  let newLabelStr: string = ''

  if (textArg) {
    switch (textArgPlacement) {
      case ArgPlacement.append:
        {
          newLabelStr = `${labelStr} ${textArg}`
        }
        break
      case ArgPlacement.prepend:
        {
          newLabelStr = `${textArg} ${labelStr}`
        }
        break
      default: {
        newLabelStr = `${labelStr || textArg}`
        break
      }
    }
  } else {
    newLabelStr = `${labelStr}`
  }
  //console.log("2.0-]]]] Props in CTextArgRow are: ", props);
  //console.log("2.1-]]]] NewLabelStr in CTextArgRow", newLabelStr);
  return (
    <CRow>
      <div className={textClass || 'edit-goals-header'}>
        {/* <div className="edit-goals-header"> */}

        <StyledLabel textStyle={textStyle} borderStyle={borderStyle} labelStr={newLabelStr} />
      </div>
    </CRow>
  )
}
export default CTextArgRow
