import { Time } from '../time/time'
import { CardType, EventType, RenderType, ERROR_TYPE, ErrorContentType, DataType, SCOPE } from './enums.types'
export type BaseDisplayState = 'visible' | 'hidden' | 'disabled'
export interface DropdownItem {
  value: any
  label: string
}
export interface BaseItem {
  id: number
  name: string
}
export interface SelectableItem<T> {
  source: T
  value: any
  label: string
}
export interface TypedObject {
  id?: any
  type?: any
  name?: string
}
export interface GroupContent {
  header?: string
  title?: string
  body?: string
  footer?: string
}
export interface TypedError extends TypedObject {
  origin?: any
  fieldName?: any
  type?: ERROR_TYPE
  contentType?: ErrorContentType
  content?: any
  kwargs?: any
  action?: any
}
export interface TypedRender extends TypedObject {
  type?: RenderType
  isFresh?: boolean
  display?: boolean
  content?: GroupContent
  handleRef?: any
  errors?: any
}
export interface TypedCard extends TypedRender {
  cardType?: CardType
  isFresh: boolean
}
export interface TypedContainer extends TypedObject {
  type?: any
  data?: any
  toRender?: TypedRender[]
}
export interface TypedEvent extends TypedObject {
  type?: EventType
  startTime?: any
  endTime?: any
}

//-----------PathSpot Types-----------
export interface LocationGroupItemAPIModel {
  id: number
  group_name: string
  locationIds: number[]
}

export interface LocationItemAPIModel {
  id: number
  name: string
}

export interface LocationAndLocationGroupAPIModel {
  locationGroups: LocationGroupItemAPIModel[]
  locations: LocationItemAPIModel[]
}

export interface TimeChartModel {
  x: Time[]
  y: number[]
}

export interface Shift {
  start: Time
  end: Time
}

export interface EmployeeSchedule {
  workerId?: string
  shifts?: Shift[]
}

export interface RowBreakRatio {
  units?: number
  lines?: number
}

export interface Scope {
  type?: SCOPE
  attributes?: any
}
export interface PComponentClasses {
  wrapper?: string
  container?: string
  content?: string
  dynamic?: string
}

export interface PComponentSectionProps {
  classes?: PComponentClasses
  content?: any
  errors?: any
  display?: boolean
  attributes?: any
  collapsible?: boolean
}
export interface PComponentContentProps {
  classes?: PComponentClasses
  content?: any
  attributes?: any
}
export interface PHeaderProps {
  classes?: PComponentClasses
  content?: PComponentSectionProps
  link?: PComponentSectionProps
}
export interface PCardHeaderProps {
  classes?: PComponentClasses
  title?: PComponentSectionProps
  link?: PComponentSectionProps
}
export interface PCardComponentProps {
  header: PCardHeaderProps
  card?: PComponentSectionProps
  button?: PComponentSectionProps
  children?: React.ReactNode
}

export interface FlexTableDimension {
  n?: number //number of columns/rows
  height?: number //height of columns/rows
  width?: number
}

export interface FlexTableField {
  displayLabel: string
  key?: string
  dataType?: DataType
  path?: string[]
  scoped?: boolean
  scope?: any
  style?: any
  size?: FlexTableDimension
}

export interface FlexTable {
  headers?: PHeaderProps
  elements?: PComponentContentProps
  layout?: PComponentSectionProps
  items?: Array<any>
  fields?: FlexTableField | FlexTableField[]
  rows?: FlexTableDimension
  cols?: FlexTableDimension
  components?: any
  handlers?: any
}

export enum GenericStateTarget {
  context = 'context',
  selections = 'selections',
  options = 'options',
  display = 'display',
  privileges = 'privileges',
}
export type DisplayState<T> = {
  [T in keyof T]: boolean
}
export type AttributeState<T> = {
  [key in keyof T]: Array<T[key]> | T[key]
}
export interface GenericState<C, A> {
  context: C
  selections: AttributeState<A>
  options: AttributeState<A>
  display?: DisplayState<A>
  privileges: AttributeState<A>
}
