import { formatNumberReversed } from '../formatting/number'
import { compareAsc, compareDesc } from 'date-fns'
import moment from 'moment'

const complianceColumnNames = ['highestCompliance', 'lowestCompliance']

const numberedColumnNames = ['totalHandwashes', 'recommendedHandwashes', 'teamMembersWashing', 'registeredCount', 'scans']

const dateColumnNames = ['date', 'lastOnline']

export const compareValues = (aObject: any, bObject: any, asc: boolean, columnName: string): any => {
  let aValue = aObject[columnName]
  let bValue = bObject[columnName]

  let aIsSmaller = true

  if (complianceColumnNames.includes(columnName)) {
    aIsSmaller = aObject[columnName].value < bObject[columnName].value
  } else if (dateColumnNames.includes(columnName)) {
    //=================

    // we need to compare all dates based on one common time zone (UTC)

    // so lets compare by the field that contains the UTC datetime returned from API
    // aValue = aObject[columnName + "RawUTC"]
    // bValue = bObject[columnName + "RawUTC"]

    // aValue = aObject[columnName]
    // bValue = bObject[columnName]

    //the above was changed - we no longer want to compare the dates based on timezones
    //the above is just for reference if the requirements ever change back to sorting with timezones

    //=================

    // this approach works properly on most browsers, but has issues on safari due to the way Data object gets parsed
    // let aDate = new Date(aValue)
    // let bDate = new Date(bValue)

    // return asc ? compareAsc(aDate, bDate) : compareDesc(aDate, bDate)

    //=================

    // this approach works across all browsers

    let pattern = 'M-D-YY H:mm A'
    let aMoment = moment(aValue, pattern)
    let bMoment = moment(bValue, pattern)

    aIsSmaller = aMoment.isBefore(bMoment)
  } else if (numberedColumnNames.includes(columnName)) {
    const aValueWithoutComma = formatNumberReversed(aValue)
    const bValuesWithoutComma = formatNumberReversed(bValue)
    aIsSmaller = aValueWithoutComma < bValuesWithoutComma
  } else if (columnName == 'verifiedHandwashingRate') {
    const aValueWithoutComma = parseFloat(aValue.replace('%', ''))
    const bValuesWithoutComma = parseFloat(bValue.replace('%', ''))
    aIsSmaller = aValueWithoutComma < bValuesWithoutComma
  } else {
    aIsSmaller = aValue < bValue
  }

  return asc ? (aIsSmaller ? -1 : 1) : aIsSmaller ? 1 : -1
}

export const compareValuesWithSecondary = (aObject: any, bObject: any, asc: boolean, columnName: string, secondaryColumnName: string) => {
  let aValue = aObject[columnName]
  let bValue = bObject[columnName]

  if (aValue === bValue) {
    return compareValues(aObject, bObject, asc, secondaryColumnName)
  }

  let aIsSmaller = true

  if (dateColumnNames.includes(columnName)) {
    let aDate = new Date(aValue)
    let bDate = new Date(bValue)

    return asc ? compareAsc(aDate, bDate) : compareDesc(aDate, bDate)
  } else if (numberedColumnNames.includes(columnName)) {
    const aValueWithoutComma = formatNumberReversed(aValue)
    const bValuesWithoutComma = formatNumberReversed(bValue)
    aIsSmaller = aValueWithoutComma < bValuesWithoutComma
  } else if (columnName == 'verifiedHandwashingRate') {
    const aValueWithoutComma = parseFloat(aValue.replace('%', ''))
    const bValuesWithoutComma = parseFloat(bValue.replace('%', ''))
    aIsSmaller = aValueWithoutComma < bValuesWithoutComma
  } else {
    aIsSmaller = aValue < bValue
  }

  return asc ? (aIsSmaller ? -1 : 1) : aIsSmaller ? 1 : -1
}
