import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'
import CIconButton from '../buttons/CIconButton'
import CardErrors from '../text/CAlertErrors'
import { dispatchResizeEvent } from '../../events/eventDispatcher'

import { CLink, CCardHeader, CCol, CRow, CCard, CCardBody, CCollapse } from '@coreui/react'

const ButtonCollapseCard = (props: any) => {
  const {
    headerClass,
    titleBoxClass,
    titleStrClass,
    titleStr,
    cLinkParentClass,
    cLinkClass,
    handleErrors,
    errorStates,
    showButton,
    buttonProps,
    cardBodyClassName,
    cardBodyStyle,
    headerButtonClass,
    headerRowClass,
    cardStyle,
    outerTagClass,
    cardErrors,
  } = props
  //console.log('Input group row props are: ', props)
  const [expanded, setExpanded] = useState(false)
  const handleExpansion = () => {
    setExpanded(!expanded)
    dispatchResizeEvent(50)
  }
  //console.log("$$$$$---- Title string for dropdown is: ", titleStr);
  // handleErrors(errorStates);
  return (
    <CRow className={headerRowClass}>
      <CCol md="12" sm="12">
        <CCard style={cardStyle}>
          <CCardHeader style={{ cursor: 'pointer' }} onClick={handleExpansion} className={headerClass}>
            <div className={cLinkParentClass}>
              <CLink className={cLinkClass}>
                <CIcon name={expanded ? 'cil-chevron-top' : 'cil-chevron-bottom'} />
              </CLink>
              <span className={titleStrClass}> {titleStr}</span>
            </div>

            {showButton && (
              <div className={headerButtonClass}>
                <CIconButton {...buttonProps} />
              </div>
            )}
          </CCardHeader>
          <CCollapse show={expanded}>
            <CCardBody className={cardBodyClassName} style={cardBodyStyle}>
              <CardErrors cardErrors={cardErrors} />
              {props.children}
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
  )
}
export default ButtonCollapseCard
