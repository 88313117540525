import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { AuthenticationProvider } from './api/authentication/AuthenticationContext'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./layout/TheLayout'))

// Pages
const Login = React.lazy(() => import('./layout/login/Login'))
const ForgotPassword = React.lazy(() => import('./layout/login/forgot-password/ForgotPassword'))
const EmailUnsubscribed = React.lazy(() => import('./layout/login/email-unsubscribed/EmailUnsubscribed'))
const ForgotPasswordInitial = React.lazy(() => import('./layout/login/forgot-password/ForgotPasswordInitial'))
const SetNewPassword = React.lazy(() => import('./layout/login/set-new-password/SetNewPassword'))
const SetNewPin = React.lazy(() => import('./layout/login/set-new-pin/SetNewPin'))

const App = ({ initialAuthState }: any) => {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <AuthenticationProvider initialAuthState={initialAuthState}>
          <Switch>
            <Route exact path="/email-unsubscribed" component={EmailUnsubscribed} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/initial-password" component={ForgotPasswordInitial} />
            {/* <Route exact path="/create-account" component={CreateAccount} /> */}
            <Route exact path="/set-new-password" component={SetNewPassword} />
            <Route exact path="/set-new-pin" component={SetNewPin} />
            {/* <Route exact path="/login" component={Login} props={}/> */}
            <Route exact path="/login" render={(props) => <Login {...props} />} />
            {/* {!currentUser && <Redirect to="/login" />} */}
            <Route path="/" component={TheLayout} />
          </Switch>
        </AuthenticationProvider>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
