import React, { useMemo, useState } from 'react'
import { SCOPE } from '../../api/types/enums.types'
import _ from 'lodash'
import RGL, { WidthProvider } from 'react-grid-layout'
import { getValueFromFieldPath, RowBreakRatio } from '../..'
import { v4 as uuidv4 } from 'uuid'

const ReactGridLayout = WidthProvider(RGL)
const componentKey = uuidv4()
const providerKey = uuidv4()
const keyIdx = 0
const generateHeader = (fields: any) => {
  //console.log('generateHeader: Fields are: ', fields);
  let idx = 0
  if (fields.some((field: any) => field && field.header)) {
    return (
      <thead>
        <tr className="react-grid-header-item">
          {fields.map((field: any) => {
            return (
              <th
                className="react-grid-header-cell-item"
                key={`${idx++}`}
                style={{ width: `${field.style.width}` }}
              >{`${field.header}`}</th>
            )
          })}
        </tr>
      </thead>
    )
  } else {
    return <thead></thead>
  }
}

const getScopedField = (item: any, field: any, idx: any) => {
  switch (field.scope.type) {
    case SCOPE.SUBTABLE:
      const subFields = field.scope.attributes.fields

      const header = generateHeader(subFields)
      const itemValue = getItemFieldValue(item, field)
      // if (itemValue.length > 0)
      let subIdx = 0
      let subRowIdx = 0
      const body = itemValue.map((subItem: any) => {
        const subItems = subFields.map((subField: any) => {
          //console.log('Checking subfield: ', subField);
          const subFieldValue = getItemField(subItem, subField, subIdx++)
          //  //console.log('Subfields value is: ', subFieldValue);
          return subFieldValue
        })
        return (
          <tr
            key={`${componentKey}-${field.key}-${idx}-${subRowIdx++}-subtr`}
            className={`react-grid-table react-grid-item`}
            style={{ width: field.style.width }}
          >
            {subItems}
          </tr>
        )
      })
      return (
        <table key={`${componentKey}-${field.key}-${idx}-subtab`} className="react-grid-table">
          {header}
          <tbody>{body}</tbody>
        </table>
      )
    case SCOPE.SUBLIST:
      return
    case SCOPE.BUTTON_EXPAND:
    case SCOPE.BUTTON_LINK:
      return
    case SCOPE.JSX:
      //console.log('Item in scope jsx is: ', item[field.key]);
      return item[field.key]
    default:
      return
  }
}

const getItemFieldValue = (item: any, field: any) => {
  return field && field.path && field.path.length > 0 ? getValueFromFieldPath(item, field.path) : item[field.key]
}

const getItemField = (item: any, field: any, idx: any) => {
  if (field.scoped) {
    const scopedCell = (
      <td key={`${uuidv4()}-${field.key}-${idx}-scoped`} className="react-grid-cell-item" style={{ width: `${field.style.width}` }}>
        {getScopedField(item, field, idx)}
      </td>
    )
    // console.log('For item, scoped field is:', [item, scopedCell]);
    return scopedCell
  } else {
    const fieldValue = getItemFieldValue(item, field)
    const displayValue = fieldValue === null ? '-' : `${fieldValue}`
    const normalCell = (
      <td key={`${componentKey}-${field.key}-${idx}-normal`} className="react-grid-cell-item" style={{ width: `${field.style.width}` }}>
        {displayValue}
      </td>
    )
    //console.log('For item, normal field is:', [item, normalCell]);
    return normalCell
  }
}

const generateDOM = (props: any) => {
  let idx = -1
  let tdx = 0
  return props.items.map((item: any) => {
    idx++
    //console.log('DOM key is:', `${idx}`);
    return (
      <div key={`${idx}`}>
        <table key={`${componentKey}-${idx}-table`} className='"react-grid-table'>
          <tbody>
            <tr key={`${componentKey}-tr-${idx}`} className={`${props.trClass}`}>
              {props.fields.map((field: any) => {
                return getItemField(item, field, idx)
              })}
            </tr>
          </tbody>
        </table>
      </div>
    )
  })
}

const getItemRowHeight = (item: any, tableFields: any, breakRatio: RowBreakRatio) => {
  let requiredRowHeight = 0
  const buttonBaseHeight = breakRatio && breakRatio.units ? breakRatio.units : 5

  tableFields.forEach((field: any) => {
    if (field.scoped) {
      switch (field.scope.type) {
        case SCOPE.SUBTABLE:
        case SCOPE.SUBLIST:
          const minHeight =
            breakRatio && breakRatio.lines && breakRatio.units
              ? Math.ceil(((item[field.key].length + 2) / breakRatio.lines) * breakRatio.units)
              : 0
          requiredRowHeight = minHeight > requiredRowHeight ? minHeight : requiredRowHeight
          return
        case SCOPE.BUTTON_EXPAND:
        case SCOPE.BUTTON_LINK:
          requiredRowHeight = buttonBaseHeight > requiredRowHeight ? buttonBaseHeight : requiredRowHeight
          return
        case SCOPE.JSX:
        default:
          requiredRowHeight = buttonBaseHeight > requiredRowHeight ? buttonBaseHeight : requiredRowHeight
          return
      }
    }
  })
  return requiredRowHeight
}
const generateLayout = (p: any) => {
  let n: number = 0

  const ySum: number = 0
  const minH: number = 5
  return p.items.map((item: any, i: any) => {
    //console.log('item in generate layout is:', _.cloneDeep(item));
    //console.log('iin generate layout is:', _.cloneDeep(i));
    n++
    const rowHeight = getItemRowHeight(item, p.fields, p.subRowBreakRatio)
    //console.log('Return row height is: ', rowHeight);
    const layoutHeight =
      rowHeight > 0 ? (rowHeight > minH ? rowHeight : minH) : p.subRowBreakRatio.units > minH ? p.subRowBreakRatio.units : minH
    //console.log('Layout height to be used is: ', layoutHeight);
    const layout = {
      x: 0,
      y: ySum,
      w: p.nColsItemWidth,
      h: layoutHeight + 2,
      minH: minH + 1,
      resizeHandles: false,
      i: i.toString(),
    }
    // item = {...item, layout: layout, idx: n - 1, layoutHeight: layoutHeight, i: i};
    // item.layout = {...layout, idx: n - 1, layoutHeight: layoutHeight, i: i};
    return layout
  })
}

export const layoutStyle = {
  border: 'none',
  marginBottom: '6px',
  background: '#226fff',
  borderRadius: 0,
}

const PDraggableList = (props: any) => {
  const { style, borderStyle, handleChange, errors, trClass, nColsItemWidth, fields, fixed, items } = props
  // console.log('Render location 4 -PDraggableList');

  //  //console.log('props in PDraggable list are: ', [props, fixed, fixed ? !fixed : true]);
  const layout = generateLayout({ ...props })

  const [layoutState, setLayoutState] = useState<any>(layout)

  const domValue = useMemo(() => {
    // console.log('Items in pdraggable list changed:', _.cloneDeep(items));
    setLayoutState(generateLayout({ ...props }))

    return generateDOM(props)
  }, [items])
  return (
    <div>
      <table className="react-grid-table">{generateHeader(fields)}</table>

      <ReactGridLayout
        className={`"layout ${trClass}"`}
        items={items}
        onLayoutChange={(layout: any) => {
          //console.log('State was: :', _.cloneDeep(state));
          //console.log('Layout passed is:', layout);
          setLayoutState(layout)
          handleChange(layout)
        }}
        cols={nColsItemWidth || 12}
        key={providerKey}
        layout={layoutState}
        //   style={layoutStyle}
        isBounded={true}
        preventCollision={false}
        isResizable={false}
        // breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        rowHeight={10}
        containerPadding={[0, 0]}
        margin={[0, 10]}
        isDraggable={fixed ? !fixed : true}
        {...props}
      >
        {domValue}
      </ReactGridLayout>
    </div>
  )
}
export default React.memo(PDraggableList)
