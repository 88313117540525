import React from 'react'
import { useFela } from 'react-fela'
import PContentRow from '../rows/PContentRow'

import { v4 as uuidv4 } from 'uuid'
import PIcon from '../../../components/icons/PIcon/PIcon'
import PContent from '../containers/PContent'
const componentKey = uuidv4()

const PButton = (props: any) => {
  const { buttons, width, alignItems, justifyContent, margin } = props
  const { css } = useFela()
  let buttonList = []
  if (buttons && !Array.isArray(buttons)) {
    buttonList = [buttons]
  } else {
    buttonList = [...buttons]
  }
  return (
    <PContent
      isRow
      width={width || '35%'}
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent || 'space-between'}
      margin={margin || { marginBottom: '1rem', marginTop: '1rem' }}
    >
      {buttonList.map((button: any) => {
        const cssClasses = `btn btn-${button.shape} btn-${button.size} } `
        const buttonDisabled = button.disabled
        // console.log('button name, button disabled, canApprove are: ', button.name, buttonDisabled, canApprove)
        return (
          <button
            key={`${componentKey}-brow-${button.text}-button`}
            style={{ cursor: buttonDisabled ? 'not-allowed' : 'pointer' }}
            className={button?.fela ? `${cssClasses} ${css(button.fela({ disabled: buttonDisabled }))}` : cssClasses}
            type={button.type}
            disabled={buttonDisabled}
            onClick={(val: any) => button.onClick(val)}
          >
            {button.icon && <PIcon key={`${componentKey}-icon`} name={button.icon} className={'mx-2'} />}
            {button.text && <span className={button.icon ? 'ml-2' : 'm-2'}>{button.text}</span>}
          </button>
        )
      })}
    </PContent>
  )
}

export default PButton

// import React from 'react'
// import './buttons.scss'

// const PWrapperButton = (props: any) => {
//   let {
//     className,
//     onClick,
//     disabled,
//     active,
//     block,
//     color,
//     size,
//     pressed,
//     shape,
//     variant,
//     buttonType,
//     svgComponent: SVGComponent,
//     children,
//     ...attributes
//   } = props

//   const click = (e: any) => !disabled && onClick && onClick(e)

//   return (
//     <button className={className || 'btn-clear'} type={buttonType || 'button'} disabled={disabled} {...attributes} onClick={click}>
//       {children}
//     </button>
//   )
// }

// export default PWrapperButton
