import { toast, ToastContainer } from 'react-toastify'
import { containerStyle } from '../styles/scss/styling'

const getToastOptions = (autoCloseInterval: number = 0) => {
  const toastOptions: any = {
    position: 'top-right',
    autoClose: autoCloseInterval,
    style: containerStyle,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  }
  return toastOptions
}

export const notify = (success: boolean = true, message: any = null, autoCloseInterval: number = 0) => {
  message = message ? message : success ? 'Success! Changes have been saved successfully.' : 'Error! Something went wrong....'
  autoCloseInterval = autoCloseInterval ? autoCloseInterval : success ? 10000 : 5000

  // console.log('In notify function success is : ', success)
  // console.log('In notify function message is : ', message)
  // console.log('In notify function autocloseinterval is : ', autoCloseInterval)

  success ? toast.success(message, getToastOptions(autoCloseInterval)) : toast.error(message, getToastOptions(autoCloseInterval))
}

export const raiseError = (e: any) => {
  if (e && e.status === 409) {
    const errObj = JSON.parse(e.message)
    notify(false, errObj.data)
  } else {
    notify(false)
  }
}
