import React from "react";
import {
  CModalGroup,
} from "../..";
import CIconButtonRow from "../rows/CIconButtonRow";
import { failButton } from "../buttons/button-styles";

const FailModal = (props: any) => {
  const { modalProps, modalState } = props;
  let newModalProps = { ...modalProps };
  newModalProps.headerColor = "danger";

  let buttonProps: any = { ...failButton };
  buttonProps.onClickAction = modalProps.onConfirm;

  const buttonRowProps = { buttonProps }
  newModalProps.content.footer = (
    <CIconButtonRow buttonRowProps={buttonRowProps} />
  );
  return (
    <span>
      <CModalGroup modal={newModalProps} />;
    </span>
  );
};
export default FailModal;
