import React from "react";
import CIconButton from "./CIconButton";

export const ErrorDisabledCIconButton = (props: any) => {
  const { errorsPresent } = props;
  if (errorsPresent) {
    return <CIconButton buttonDisable={true} {...props} />;
  } else {
    return <CIconButton buttonDisable={false} {...props} />;
  }
};
export default ErrorDisabledCIconButton;
