import { api } from '../../../api/api'
import { API_URL_LOG_IN } from '../../../api/constants'

export const logIn = async ({ ...values }) => {
  const apiReturn = api
    .noAuth()
    .url(`${API_URL_LOG_IN}`)
    .options({ credentials: 'include', mode: 'cors' })
    .post({ ...values })
    .json()
  return apiReturn
}
