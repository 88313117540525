import React from 'react'
import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'

export const CModalGroup = (props: any) => {
  const { modal, bodyDivClass, errorDivClass, windowSize } = props
  const { headerColor, onClose, onConfirm, content, error, display } = modal
  const { title, body, footer } = content
  //console.log('38.0-]]]] In CModalGroup, props are: ', props);
  return (
    <div>
      <CModal
        size={windowSize || 'lg'}
        show={modal && modal.display ? modal.display : false}
        onClose={() => (onClose ? onClose() : Object.assign(display, false))}
        color={headerColor || 'primary'}
      >
        <CModalHeader closeButton>
          <CModalTitle>{modal && modal.content ? modal.content.title : ''}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className={bodyDivClass}>{modal && modal.content ? modal.content.body : ''}</div>
          <div className={errorDivClass}>{modal && modal.error && modal.error.content ? modal.error.content.body : ''}</div>
        </CModalBody>
        <CModalFooter>{modal && modal.content && modal.content.footer ? modal.content.footer : ''}</CModalFooter>
      </CModal>
    </div>
  )
}
export default CModalGroup
