import React from 'react'
import { useFela } from 'react-fela'
import Select from 'react-select'
import { debugBorder } from '../../styles/containers'
import { selectorStyle, styles } from '../../../styles/scss/styling'
import { baseText } from '../../styles/text'
import { formControlBackground, grayInput, pathspotPrimary, pathspotWhite } from '../../../styles/ts/colors'
import { btnFocusBoxShadow, btnFormBoxShadow } from '../../styles/buttons'
export const dropdown = (args: any) => {
  const { flexDirection, width, boxShadow, height, maxHeight } = args
  return {
    display: 'block',
    width: '1%',
    flex: '1 1 auto',

    // flexDirection: flexDirection || 'row',
    // minWidth: width || '100%',
    boxShadow: boxShadow ? (boxShadow === 'none' ? undefined : boxShadow) : btnFormBoxShadow,
    // height,
    // maxHeight,
  }
}

const dropdownSelector = (args: any) => {
  const {
    borderRadius,
    borderLeft,
    borderTop,
    borderRight,
    borderBottom,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
  } = args
  return {
    borderRadius: `${borderRadius ? borderRadius : '0'} !important`,
    borderBottomRightRadius: borderRight ? (borderBottomRightRadius ? borderBottomRightRadius : borderRadius) : '0.25rem',
    borderTopRightRadius: borderRight ? (borderTopRightRadius ? borderTopRightRadius : borderRadius) : '0.25rem',
    borderBottomLeftRadius: `${borderLeft ? (borderBottomLeftRadius ? borderBottomLeftRadius : borderRadius) : '0rem'} !important`,
    borderTopLeftRadius: `${borderLeft ? (borderTopLeftRadius ? borderTopLeftRadius : borderRadius) : '0rem'} !important`,
  }
}

const LabelText = (props: any) => {
  const { className, label, value } = props

  return <span className={className}>{`${label}`}</span>
}

const PDropdownSelect = (props: any) => {
  const {
    value: _value,
    options,
    onChange,
    isMulti,
    placeholder,
    name,
    width,
    height,
    closeOnSelect,
    fontSize,
    fontWeight,
    label,
    color,
    boxShadow,
    backgroundColor,
    borderLeft,
    borderRight,
    disabled,
    isGroup,
  } = props
  const { css } = useFela()
  // console.log('PDropdownSelect value is: ', _value)
  let value = null
  if (_value !== undefined && _value !== null) {
    if (Array.isArray(_value) && _value.length === 0) {
      value = undefined
    } else if (typeof _value === typeof {}) {
      if (Object.keys(_value).length === 0) {
        value = undefined
      } else {
        value = _value
      }
    } else {
      value = _value
    }
  }
  // console.log('PDropdownSelect value is now: ', value)

  return (
    <div
      className={css(
        dropdown({
          width: width,
          height: height,
          maxHeight: height,
          boxShadow: boxShadow,
          boxSizing: 'border-box',
        })
      )}
    >
      <Select
        name={name || 'Select'}
        closeMenuOnSelect={closeOnSelect || true}
        className={css(dropdownSelector({ borderLeft, borderRight }))}
        placeholder={placeholder || 'Please select an item from the list.'}
        value={value ? value : undefined} //This funkiness is done because it seems to matter for being able to use the Select component from 'react-select' as either a controlled or uncontrolled componenent. For whatever reason, even if 'value' is undefined, if you pass in the varaible and not just undefined on its own, the select component will not behave like a normal uncontrolled component. That is, the selector value will always just appear to be the placeholder, even if you select something else, because it thinks you are setting the value and that value you chose is erroneously passed as undefined, but if you pass undefined not as a variable, the selector visually changes as things are selected, just as an uncontrolled component should behave. Must be related the variable reference....
        styles={
          isGroup
            ? { ...selectorStyle }
            : {
                placeholder: (base: any) => ({
                  ...base,
                  fontSize: fontSize || '1rem',
                  color: color || grayInput,
                  backgroundColor: backgroundColor || formControlBackground,
                  fontWeight: fontWeight || 400,
                }),
                ...styles,
              }
        }
        formatOptionLabel={(styles: any) => {
          return (
            <LabelText {...styles} className={css(baseText({ color: color || grayInput, fontSize: fontSize, fontWeight: 'normal' }))} />
          )
        }}
        options={options}
        isMulti={isMulti}
        isDisabled={disabled}
        onChange={(value: any) => {
          if (onChange) onChange(value)
        }}
      />
    </div>
  )
}
export default PDropdownSelect
