import React from 'react';
import {CAlert} from '@coreui/react';
import {isArray} from 'lodash';
import {v4 as uuidv4} from 'uuid';

const key = uuidv4();
let count = 0;

const PAlertErrors = (props: any) => {
  const {errors, fieldName} = props;
  console.log('Errors are: ', errors);
  let errorList = errors && errors[fieldName] ? errors[fieldName] : errors;
  if (!isArray(errorList) && Object.keys(errorList).length === 0) {
    return <></>;
  }
  if (!isArray(errorList)) {
    errorList = [errorList];
  }
  if (errorList && errorList.length > 0) {
    return (
      <div key={key} className="mb-2">
        {errorList.map((error: any) => {
          return (
            <div key={`${key}-00${count++}`} className="custom-invalid-field-feedback">
              {error}
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default PAlertErrors;
