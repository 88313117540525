import { wholeNumber } from './format'

export const computeMovingAverage = (originalDataVector: Array<number>, windowSize: number) => {
  const dataVector = originalDataVector
  const leftIndexOffset = Math.ceil(windowSize / 2) - 1
  const rightIndexOffset = Math.floor(windowSize / 2)
  let idx = 0
  const movingAverage = dataVector.reduce(
    (smoothVector: Array<number>, dataPoint: number) => {
      let currentLeftOffset = leftIndexOffset
      let currentRightOffset = rightIndexOffset
      let currentWindow = windowSize
      if (idx - currentLeftOffset <= 0) {
        currentLeftOffset = 0
        currentRightOffset = currentRightOffset + Math.abs(idx - leftIndexOffset)

        // console.log("Reset current right offset to: ", {abs: Math.abs(idx-currentLeftOffset)})
      }
      if (idx + currentRightOffset > dataVector.length - 1) {
        currentRightOffset = dataVector.length - 1 - idx
        if (currentRightOffset < 0) currentRightOffset = 0
      }
      const leftStart = currentLeftOffset === 0 ? 0 : idx - currentLeftOffset >= 0 ? idx - currentLeftOffset : 0
      const rightEnd = idx + currentRightOffset
      //console.log('Left start, rightEnd, idx are: ', { leftStart, rightEnd, idx })
      let windowPointer = leftStart
      let runningSum = 0
      while (windowPointer < rightEnd + 1) {
        runningSum = runningSum + smoothVector[windowPointer]
        windowPointer++
      }
      currentWindow = rightEnd - leftStart + 1
      //console.log('Final window pointer is: ', windowPointer)
      const smoothedDataPoint = runningSum / currentWindow
      smoothVector[idx] = smoothedDataPoint
      idx++
      return smoothVector
    },
    [...dataVector]
  )
  return movingAverage.map((smoothVal: number) => Math.round(smoothVal * 10) / 10)
}

export const computeConstantAverage = (originalDataVector: Array<number>) => {
  const vectorSum = originalDataVector.reduce((runningSum: number, dataPoint: number) => {
    runningSum = runningSum + dataPoint
    return runningSum
  }, 0)
  const constantAverage = wholeNumber(vectorSum / originalDataVector.length)
  return originalDataVector.map((datum: number) => constantAverage)
}
