export const cssClassnameFromList = (wordArray: Array<string>) => {
  const classNameString = wordArray.reduce((concatenatedString: string, currentWord: string) => {
    if (concatenatedString.length === 0) {
      concatenatedString = `${currentWord}`
    } else {
      concatenatedString = `${concatenatedString} ${currentWord}`
    }
    return concatenatedString
  }, '')
  return classNameString
}

export const cssHypehnatedClassnameFromList = (wordArray: Array<string>) => {
  const classNameString = wordArray.reduce((concatenatedString: string, currentWord: string) => {
    if (concatenatedString.length === 0) {
      concatenatedString = `${currentWord}`
    } else {
      concatenatedString = `${concatenatedString}-${currentWord}`
    }
    return concatenatedString
  }, '')
  return classNameString
}
