import React, { useLayoutEffect, useState } from "react";
import CIcon from "@coreui/icons-react";

import {
  CFade,
  CCardHeader,
  CCol,
  CRow,
  CCard,
  CCardBody,
  CCollapse
} from "@coreui/react";
//@ts-ignore
import cloneDeep from "lodash/cloneDeep";

import ConditionalCollapseHeader from './ConditionalCollapseHeader'

const CFadeToggleCollapseCard = (props: any) => {
  const {
    headerProps,
    handleErrors,
    errorStates,
    showCard,
    handleToggle,
    outerTagClass,
    dropdownEnable,
    cardBodyProps
  } = props;
  //console.log('Input group row props are: ', props)
  const [expanded, setExpanded] = useState(false);
  useLayoutEffect(() => {
    setExpanded(cloneDeep(dropdownEnable))
  }, [dropdownEnable])
  return (
    <CRow className={outerTagClass}>
      <CCol md="12" sm="12">
        <CFade in={showCard}>
          <CCard style={{ width: "100%" }}>
            <ConditionalCollapseHeader
              {...headerProps}
              handleToggle={handleToggle}
              expanded={expanded}
              setExpanded={setExpanded}
              toggleEnable={dropdownEnable}
            />
            <CCollapse show={dropdownEnable && expanded}>
              <div style={cardBodyProps.divStyle}>
                <CCardBody className="mb-3">{props.children}</CCardBody>
              </div>
            </CCollapse>
          </CCard>
        </CFade>
      </CCol>
    </CRow>
  );
};
export default CFadeToggleCollapseCard;
