import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CDataTable, CRow } from '@coreui/react'

const CDataTableGroup = (props: any) => {
  const { tableData, dataFields, columnNames, divStyle, panelHeader, cRowClass, mdColSize, headerText, perPageSelect, paginationStyle, scopedSlots, noItemsViewSlot } = props
  const filtTestObject = {
    locationId: 5011,
    locationName: 'Daniella tiene nada',
    locationContact: 'testeroo',
    goalsInfo: {
      hasDepartments: true,
      hasDepartmentGoals: true,
      hasLocationGoals: true,
      isDeptSummary: false,
    },
  }
  return (
    <div style={divStyle}>
      {panelHeader && <span>{panelHeader}</span>}
      <CRow className={cRowClass}>
        <CCol md={mdColSize}>
          <CCard>
            <CCardHeader>{headerText}</CCardHeader>
            <CCardBody>
              <CDataTable
                items={tableData}
                fields={dataFields}
                columnHeaderSlot={columnNames}
                tableFilter
                itemsPerPageSelect={perPageSelect}
                itemsPerPage={perPageSelect.items}
                hover
                sorter
                cleaner
                pagination={paginationStyle}
                noItemsViewSlot={noItemsViewSlot}
                scopedSlots={scopedSlots}
                onColumnFilterChange={(e: any) => {
                  //console.log('On column filter change active, event is: ', e)
                }}
                onTableFilterChange={(e: any) => {
                  //console.log('On table filter change active, e is: ', e)
                }}
                onFilteredItemsChange={(filteredItems: any) => {
                  //console.log('Filtered items are now: ', filteredItems)
                  if (filteredItems && filteredItems.length > 0) {
                    // filteredItems?.unshift(filtTestObject)
                  } else {
                    //console.log('Filtered items has no length:', filteredItems?.length)
                  }
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  )
}

export default CDataTableGroup
