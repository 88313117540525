import CIcon from '@coreui/icons-react';
import {CInputGroupPrepend, CInputGroupText} from '@coreui/react';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const key = uuidv4();
let count = 0;
let count2 = 0;

const CInputGroupRowPrepend = (props: any) => {
  const {iconStart, displayStr, textClass} = props;
  //console.log('Input group row prepend props are: ', props)
  const prependItems: any[] = [];
  if (iconStart) {
    prependItems.push(
      <CInputGroupText key={`${key}-00${count++}`}>
        <CIcon name={iconStart} />
      </CInputGroupText>
    );
  }
  if (displayStr) {
    prependItems.push(
      <CInputGroupText key={`${key}-000${count2++}`} className={textClass}>
        {displayStr}
      </CInputGroupText>
    );
  }
  //console.log('Prepend items are: ', prependItems)
  if (prependItems.length > 0) {
    return <CInputGroupPrepend key={key}>{prependItems}</CInputGroupPrepend>;
  } else {
    return <></>;
  }
};
export default CInputGroupRowPrepend;
