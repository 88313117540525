import React, {useState} from 'react';
import {CCol, CRow, CButtonToolbar, CLabel} from '@coreui/react';
import CIconButton from '../buttons/CIconButton';
import {ButtonProps, LabelButtonRow} from '../buttons/types';

export const PLabelButtonRow: React.FC<LabelButtonRow> = ({label, labelClass, labelColClass, labelStyle, buttonRow}) => {
  const {rowClass, colClass, toolbarClass, buttonProps} = {...buttonRow};
  if (buttonProps && Array.isArray(buttonProps)) {
    return (
      <div className={rowClass || 'mb-3'}>
        {/* <div className={labelColClass || 'p-inline-half'}> */}
        <div className={labelClass}>{`${label}`}</div>
        {/* </div> */}
        <div className={colClass || 'p-inline-half'}>
          {buttonProps.map((buttonAttrs: ButtonProps) => {
            return (
              <div className={toolbarClass || 'justify-content-center'}>
                <CIconButton {...buttonAttrs} />
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <CRow className={rowClass || 'mb-3'}>
        <CLabel className={labelClass} style={{...labelStyle}}>{`${label}`}</CLabel>
        <CCol className={colClass || 'justify-content-center'}>
          <CButtonToolbar className={toolbarClass || 'justify-content-center'}>
            <CIconButton {...buttonProps} />
          </CButtonToolbar>
        </CCol>
      </CRow>
    );
  }
};
export default PLabelButtonRow;
