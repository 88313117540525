import React from 'react'

import './buttons.scss'
import PIcon from '../icons/PIcon/PIcon'
import PButton from './PButton'
import { v4 as uuidv4 } from 'uuid'

const componentKey = uuidv4()

export const PIconButton = (props: any) => {
  const {
    id,
    isChecked,
    onClickAction,
    onClick,
    iconClass,
    buttonType,
    buttonText,
    buttonIcon,
    buttonClass,
    buttonColor,
    buttonStyle,
    buttonShape,
    buttonSize,
    buttonDisable,
    buttonVariant,
    buttonJsx,
    divClassName,
    buttonTextClass,
    colorOverride,
    buttonWrapperClass,
    buttonContentClass,
    inline,
  } = props
  //console.log('Button props are: ', props)
  const onClickFunction: Function = onClickAction ? onClickAction : onClick ? onClick : () => null
  const cursor = buttonDisable ? 'not-allowed' : 'pointer'
  // console.log('Button disable status is: ', [buttonDisable, cursor]);
  // console.log('PIcon button isChecked: ', isChecked)
  const StyledPButton: React.FC = () => {
    return (
      <PButton
        key={`${componentKey}-button`}
        type={buttonType || 'button'}
        style={buttonStyle || { cursor: cursor }}
        // tag={id}
        id={id}
        disabled={buttonDisable || false}
        className={buttonClass || 'px-4 mx-4'}
        color={buttonColor || 'primary'}
        size={buttonSize || 'sm'}
        shape={buttonShape || 'pill'}
        variant={buttonVariant || ''}
        onClick={(val: any) => onClickFunction(val)}
        colorOverride={colorOverride}
        checked={isChecked}
        pressed={isChecked}
        buttonWrapperClass={buttonWrapperClass}
      >
        <div className={buttonContentClass || 'inline-flex-row'}>
          {buttonIcon && <PIcon key={`${componentKey}-icon`} name={buttonIcon} className={iconClass || 'mx-2'} />}
          <span className={buttonTextClass || 'ml-2'}>{buttonJsx ? buttonJsx : buttonText}</span>
        </div>
      </PButton>
    )
  }
  if (inline) {
    console.log('Picon button is inline: ', props)
  }
  return inline ? (
    <StyledPButton />
  ) : (
    <div className={divClassName || 'mt-3 mb-3'}>
      <StyledPButton />
    </div>
  )
}
export default PIconButton
