import { pathspotGrayLight, pathspotPrimary, pathspotWhite, toastifyError, toastifyTextGray } from '../../styles/ts/colors'
import * as css from './css-properties'

export const dropdown = (args: any) => {
  const { justifyContent, alignItems, width } = args
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    minWidth: width || '50%',
    width: width || '50%',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
  }
}
export const datepicker = (args: any) => {
  return {
    ...css.inlineFlex,
    ...css.flexRow,
    borderColor: toastifyTextGray,
    minHeight: '2rem',
    borderWidth: '1px',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
  }
}
