import React from "react";
import {
  CModalGroup,
  continueButtonWarn,
  cancelButtonLight,
  CancelContinueButtons
} from "../..";
//@ts-ignore

const WarningModal = (props: any) => {
  const { modalProps, modalState } = props;
  let newModalProps = { ...modalProps };
  newModalProps.headerColor = "warning";
  let continueButtonProps: any = { ...continueButtonWarn };
  let cancelButtonProps: any = { ...cancelButtonLight };
  continueButtonProps.onClickAction = modalProps.onConfirm;
  cancelButtonProps.onClickAction = modalProps.onCancel;
  newModalProps.content.footer = (
    <CancelContinueButtons
      continueButtonProps={continueButtonProps}
      cancelButtonProps={cancelButtonProps}
    />
  );
  return (
    <span>
      <CModalGroup modal={newModalProps} />;
    </span>
  );
};
export default WarningModal;
